import { useObserver } from "mobx-react-lite";
import { IEValuationGridRowModel } from "../../IEmodels/IEValuationGridRowModel";
import * as Format from "Utils/Format";
import { AppUrls } from "AppUrls";
import { useRouter } from "@shoothill/core";

type Props = {
    valuation: IEValuationGridRowModel;
};

export const ValuationRow = ({ valuation }: Props) => {
    const { history } = useRouter();
    const handleValuationClick = (val: string) => {
        history.push(AppUrls.Client.Valuation.View.replace(":valuationid", val));
    };

    return useObserver(() => (
        <li className="item valuation-row" style={{ cursor: "pointer" }} onClick={() => handleValuationClick(valuation.id)}>
            <div className="valuation-title">
                <div>{valuation.valuationNumber.toString().padStart(4, "0")}</div>
                <div>{valuation.poNumberFormatted}</div>
            </div>
            <div className="right">{valuation.supplier}</div>
            <div className="right">{valuation.valuationDate.toLocaleDateString("en-GB")}</div>
            <div className="right">{Format.formatCurrencyForGrid(Format.formatCurrencyNoSign(valuation.totalContractValue))}</div>
            <div className="right">{Format.formatCurrencyForGrid(Format.formatCurrencyNoSign(valuation.totalIncludingMCD))}</div>
            <div className="right">{Format.formatCurrencyForGrid(Format.formatCurrencyNoSign(valuation.totalValueAtLastClaim))}</div>
            <div className="right">{valuation.totalClaimPercentage.toFixed(2)}</div>
            <div className="right">{Format.formatCurrencyForGrid(Format.formatCurrencyNoSign(valuation.amountClaimed))}</div>
        </li>
    ));
};
