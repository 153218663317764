import { observer } from "mobx-react-lite";
import React from "react";

import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { SubdirectoryArrowIcon } from "Content/SubdirectoryArrowIcon";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AddPlanOfWorkModalStyle } from "Views/Project/Modals/Modal.Styles";
import {
    HistoryItemViewModel,
    HistoryPlanOfWorkDisplayName,
    HistoryTable,
    HistoryViewModel,
    IDrawing,
    IDrawings,
    LastestDrawingRevision,
    LatestDrawing,
    PreviousDrawing,
    PreviousDrawingRevision,
} from ".";
import { DownloadDrawingView } from "../../Shared";

interface IProps {
    viewModel: HistoryViewModel;
}

export const HistoryView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="History"
            PaperComponent={AddPlanOfWorkModalStyle}
            actions={
                <React.Fragment>
                    <CancelButton onClick={viewModel.cancel}>Close</CancelButton>
                </React.Fragment>
            }
        >
            <HistoryTable>
                {viewModel.historyItems.map((rowData: HistoryItemViewModel) => {
                    return (
                        <div>
                            <HistoryPlanOfWorkDisplayName>{rowData.planOfWorkStageDisplayName}</HistoryPlanOfWorkDisplayName>
                            {rowData.drawings.map((drawingData: IDrawings) => (
                                <>
                                    <LatestDrawing>
                                        <DownloadDrawingView
                                            fileName={drawingData.latestDrawing.drawingFileName}
                                            attachmentUrl={drawingData.latestDrawing.drawingAttachmentUrl}
                                            inlineUrl={drawingData.latestDrawing.drawingInlineUrl}
                                            autodeskVersionId={drawingData.latestDrawing.autodeskVersionId}
                                            drawingId={drawingData.latestDrawing.drawingId}
                                        />
                                        <LastestDrawingRevision>Rev: {drawingData.latestDrawing.drawingRevisionNumber}</LastestDrawingRevision>
                                    </LatestDrawing>
                                    {drawingData.previousDrawings.map((previousDrawing: IDrawing) => (
                                        <PreviousDrawing>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ marginRight: "0.25rem" }}>
                                                    <SubdirectoryArrowIcon />
                                                </div>
                                                <DownloadDrawingView
                                                    fileName={previousDrawing.drawingFileName}
                                                    attachmentUrl={previousDrawing.drawingAttachmentUrl}
                                                    inlineUrl={previousDrawing.drawingInlineUrl}
                                                    autodeskVersionId={previousDrawing.autodeskVersionId}
                                                    drawingId={previousDrawing.drawingId}
                                                />
                                            </div>
                                            <PreviousDrawingRevision>Rev: {previousDrawing.drawingRevisionNumber}</PreviousDrawingRevision>
                                        </PreviousDrawing>
                                    ))}
                                </>
                            ))}
                        </div>
                    );
                })}
            </HistoryTable>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
