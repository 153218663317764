import { ModelBase, observable } from "@shoothill/core";
import { action, computed } from "mobx";

export class IEValuationGridRowModel extends ModelBase<IEValuationGridRowModel, IEValuationGridRowModelDTO> {
    @observable
    public id: string = "";

    @observable
    public supplier: string = "";

    @observable
    public poNumberFormatted: string = "";

    @observable
    public valuationNumber: number = 0;

    @observable
    public valuationDate = new Date();

    @observable
    public totalContractValue: number = 0;

    @observable
    public totalIncludingMCD: number = 0;

    @observable
    public totalValueAtLastClaim: number = 0;

    @observable
    public amountClaimed: number = 0;

    @computed
    public get totalClaimPercentage(): number {
        return (this.amountClaimed / this.totalContractValue) * 100;
    }

    @action
    fromDto(model: IEValuationGridRowModelDTO): void {
        this.id = model.id;
        this.supplier = model.supplier;
        this.poNumberFormatted = model.poNumberFormatted;
        this.valuationNumber = model.valuationNumber;
        this.valuationDate = new Date(model.valuationDate);
        this.totalContractValue = model.totalContractValue;
        this.totalIncludingMCD = model.totalIncludingMCD;
        this.totalValueAtLastClaim = model.totalValueAtLastClaim;
        this.amountClaimed = model.amountClaimed;
    }

    toDto(model: IEValuationGridRowModel): void {}
}

export type IEValuationGridRowModelDTO = {
    id: string;
    supplier: string;
    poNumberFormatted: string;
    valuationNumber: number;
    valuationDate: string;
    totalContractValue: number;
    totalIncludingMCD: number;
    totalValueAtLastClaim: number;
    amountClaimed: number;
};
