import { Button } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { Files } from "Components/Files/Files/Files";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AddDrawingModalStyle } from "Views/Project/Modals/Modal.Styles";
import { UpdateDrawingModel, UpdateDrawingViewModel } from ".";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { DarwinInputWrapper } from "Components/Form/DarwinInputWrapper";
import FolderNavigator from "Components/FolderNavigator/FolderNavigator";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";

interface IProps {
    viewModel: UpdateDrawingViewModel;
    projectId: string;
}

export const UpdateDrawingView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    useEffect(() => {
        viewModel.apiLoadCloudTopLevelFolderAsync(props.projectId);
    }, []);

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="Update drawing"
            PaperComponent={AddDrawingModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiUpdateDrawingAsync} variant="contained">
                        Save
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <DarwinSelect
                displayName="Users:*"
                execute={viewModel.setApproverUserAsync}
                fullWidth={true}
                getOptionLabel={(option: any) => option.displayName}
                onBlur={() => viewModel.isFieldValid("approverUserId")}
                options={viewModel.approverUsers}
                placeholder="Please select"
                validationMessage={viewModel.getError("approverUserId")}
                value={viewModel.approverUser}
                canExecute={!viewModel.IsLoading}
            />

            <DarwinInputWrapper<UpdateDrawingModel>
                type="text"
                label="Description:*"
                placeHolder="Description"
                validateOnBlur={true}
                viewModel={viewModel}
                fieldName="description"
                shrink={true}
                editMode={!viewModel.IsLoading}
            />

            <DarwinSelect
                displayName="Document source:*"
                execute={(val: any) => viewModel.setDocumentSource(val.id)}
                fullWidth={true}
                getOptionLabel={(option: any) => option.displayName || ""}
                onBlur={() => viewModel.isFieldValid("documentSource")}
                options={viewModel.documentSourceOptions}
                placeholder="Please select"
                validationMessage={viewModel.getError("documentSource")}
                value={viewModel.documentSourceOption}
                canExecute={!viewModel.IsLoading}
            />

            {viewModel.isFromComputer && (
                <>
                    <DashedDivider gutterTop gutterBottom borderThickness={2} borderOpacity={0.5} />
                    <Files viewModel={viewModel.filesViewModel} disabled={viewModel.IsLoading} />
                </>
            )}

            {viewModel.isFromCloud && viewModel.autodeskDocuments.length === 0 && (
                <>
                    <DashedDivider gutterTop gutterBottom borderThickness={2} borderOpacity={0.5} />
                    <FolderNavigator
                        rootFolders={viewModel.rootFolders}
                        folders={viewModel.folders}
                        apiLoadCloudFolderContentsAsync={(projectId: string, folderId: string) => viewModel.apiLoadCloudFolderContentsAsync(projectId, folderId)}
                        apiLoadCloudItemAsync={(projectId: string, itemId: string) => viewModel.apiLoadCloudItemAsync(projectId, itemId)}
                        isLoading={viewModel.IsLoading}
                        projectId={props.projectId}
                    />
                </>
            )}

            {viewModel.autodeskDocuments.map((i) => {
                return <div style={{ margin: "15px 0 15px 0" }}>{i.fileName}</div>;
            })}

            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
