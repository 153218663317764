import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { runInAction, observable, computed, action } from "mobx";
import { AddEditResponseModel, RFIResponseDocumentDTO, RFIResponseRequestDTO, UpsertAddEditResponseRequestDTO, UpsertAddEditResponseResponseDTO } from "./AddEditResponseModel";
import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { isNewTabType, openFileInNewTab } from "Utils/Utils";

export class AddEditResponseViewModel extends ViewModelBase<AddEditResponseModel> {
    public constructor(projectId: string | null, rFIId: string | null, id: string | null) {
        super(new AddEditResponseModel(), false);
        this.setDecorators(AddEditResponseModel);
        this.model.projectId = projectId!;
        this.model.id = id!;
        this.model.rFIId = rFIId!;
        this.model.createdDate = new Date().toISOString();
        if (!isEmptyOrWhitespace(this.model.id)) {
            this.loadRFIResponse();
        }
    }

    @observable public errorMessage: string = "";

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public hasReceivedDateChanged: boolean = false;

    @observable
    public status: { id: string; displayName: string } | null = null;

    @computed
    public get isFormDisabled(): boolean {
        return this.model.hasId;
    }

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get rfiResponseDocuments(): RFIResponseDocumentDTO[] {
        return this.model.rfiResponseDocuments.filter((d) => !d.isDeleted);
    }

    @action
    public setReceivedDate = (value: string | null): void => {
        this.setValue("receivedDate", value);
        this.hasReceivedDateChanged = true;
    };

    @action
    public handleStatusId = (item: { id: string; displayName: string }) => {
        this.status = item;
        this.setValue("statusId", item.id);
    };

    /**
     * Delete a document from the local array.
     * @param id The id of the document to be deleted
     */
    @action
    public handleDeleteDocument = (id: string | null): void => {
        // Used to delete by index but there's different types of documents separated into different lists.
        if (id !== null) {
            for (let i = 0; i < this.model.rfiResponseDocuments.length; i++) {
                if (this.model.rfiResponseDocuments[i].id === id) {
                    //this.hasInvoiceAttachmentsChanged = true;
                    this.model.rfiResponseDocuments[i].isDeleted = true;
                }
            }
        }
    };

    @action
    public loadRFIResponse = async (): Promise<void> => {
        const request: RFIResponseRequestDTO = {
            id: this.model.id,
        };
        let apiResult = await this.Post<UpsertAddEditResponseResponseDTO>(AppUrls.Server.Projects.ProjectTrackers.RFI.GetRFIResponseById, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.model.fromDto(apiResult.payload);
                    const data = {
                        id: apiResult.payload.rfiResponse.statusId,
                        displayName: apiResult.payload.rfiResponse.statusName,
                    };
                    this.handleStatusId(data);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    @action
    public upsert = async (e?: any): Promise<ApiResult<UpsertAddEditResponseRequestDTO>> => {
        e?.preventDefault();

        if (await this.isMyModelValid()) {
            const model: any = this.model.toDto();
            let apiResult = await this.Post<UpsertAddEditResponseResponseDTO>(AppUrls.Server.Projects.ProjectTrackers.RFI.UpsertRFIResponse, model);
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.history.push(AppUrls.Client.Project.RequestForInformation.replace(":projectid", this.model.projectId));
                });
            }
            return apiResult;
        } else {
            this.errorMessage = "Form is not valid";
            return Promise.reject();
        }
    };

    /**
     * Handle a file being selected and process the data for upload.
     * @param event
     */
    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                let fileToDisplay: RFIResponseDocumentDTO = {
                    id: Math.random().toString(16).substr(2, 8), // 6de5ccda
                    url: apiResult.payload,
                    fileName: data.fileName,
                    isDeleted: false,
                };

                runInAction(() => {
                    this.model.rfiResponseDocuments.push(fileToDisplay);
                    //this.hasInvoiceAttachmentsChanged = true;
                });
            }
        }
    };

    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const fileExtension: string = fileName.split(".").pop()!.toLowerCase();

            // Open the file in a new tab if supported.
            if (isNewTabType(fileExtension)) {
                openFileInNewTab(apiResult, fileName);
            } else {
                const response = apiResult as any;
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            }
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isModelValid()) {
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public async isFieldValid(fieldName: keyof FieldType<AddEditResponseModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        errorMessage = "";
        isValid = true;

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    // #region Snackbar

    @observable
    public snackbarState = false;

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @observable
    public snackType = "";

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";
    // #endregion

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
