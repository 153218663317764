import { observer } from "mobx-react-lite";
import React from "react";

import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { SubdirectoryArrowIcon } from "Content/SubdirectoryArrowIcon";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AddPlanOfWorkModalStyle } from "Views/Project/Modals/Modal.Styles";
// import {
//     HistoryItemViewModel,
//     HistoryPlanOfWorkDisplayName,
//     HistoryTable,
//     HistoryViewModel,
//     IDrawing,
//     IDrawings,
//     LastestDrawingRevision,
//     LatestDrawing,
//     PreviousDrawing,
//     PreviousDrawingRevision,
// } from ".";
import { DownloadDrawingView } from "../../Shared";
import { DrawingsViewModel } from "../Table";
import { IssueDrawingItemViewModel, IssueItemViewModel } from "../Table/IssueItemViewModel";
import { DrawingRow, DrawingTable } from "../../Output/Drawings/DrawingsView.styles";

interface IProps {
    viewModel: DrawingsViewModel;
    issueViewModel: IssueItemViewModel;
}

export const IssueView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;
    const issueViewModel = props.issueViewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={() => viewModel.closeIssue(false)}
            title={issueViewModel.isIssue ? "Issue" : "Communication"}
            PaperComponent={AddPlanOfWorkModalStyle}
            actions={
                <React.Fragment>
                    <CancelButton onClick={() => viewModel.closeIssue(false)}>Close</CancelButton>
                </React.Fragment>
            }
        >
            <div>Additional notes: </div>
            <div style={{ whiteSpace: "pre-wrap" }}>{issueViewModel.notes}</div>

            <DrawingTable>
                {issueViewModel.drawings.map((rowData: IssueDrawingItemViewModel, index: number) => {
                    return (
                        <DrawingRow key={index}>
                            <DownloadDrawingView
                                fileName={rowData.name!}
                                attachmentUrl={rowData.attachmentUrl!}
                                inlineUrl={rowData.inlineUrl!}
                                autodeskVersionId={rowData.autodeskVersionId}
                                drawingId={rowData.id}
                            />
                        </DrawingRow>
                    );
                })}
            </DrawingTable>
        </BaseModal>
    );
});
