import { ModelBase, observable } from "@shoothill/core";
import { action } from "mobx";
import { ValuationItemDTO } from "./ValuationItemModel";

export class ValuationModel extends ModelBase<ValuationModel, ValuationDetailsDTO> {
    @observable
    public supplier = "";

    @observable
    public valuationNumber = 0;

    @observable
    public valuationDate = new Date();

    @observable
    public poNumberFormatted = "";

    @observable
    public mainContractorsDiscount: number | null = null;

    @observable
    public retention: number | null = null;

    @observable
    public previouslyPaid = 0;

    @observable
    public ieId = "";

    @observable
    public ieName = "";

    @observable
    public groups = observable<ValuationGroupDTO>([]);

    @observable
    public canEdit = false;

    @observable
    public canDelete = false;

    @observable
    public rowVersion: string | null = null;

    @action
    public fromDto(model: ValuationDetailsDTO): void {
        this.supplier = model.supplier;
        this.valuationNumber = model.valuationNumber;
        this.valuationDate = new Date(model.valuationDate);
        this.poNumberFormatted = model.poNumberFormatted;
        this.mainContractorsDiscount = model.mainContractorsDiscount;
        this.retention = model.retention;
        this.previouslyPaid = model.previouslyPaid;
        this.ieId = model.ieId;
        this.ieName = model.ieName;
        this.canEdit = model.canEdit;
        this.canDelete = model.canDelete;
        this.rowVersion = model.rowVersion;

        this.groups.replace(model.valuationGroups);
    }

    toDto(model: ValuationModel): void {}
}

export type ValuationDetailsDTO = {
    supplier: string;
    valuationNumber: number;
    poNumberFormatted: string;
    valuationDate: string;
    mainContractorsDiscount: number | null;
    retention: number | null;
    previouslyPaid: number;
    ieId: string;
    ieName: string;
    canEdit: boolean;
    canDelete: boolean;
    rowVersion: string | null;

    valuationItems: ValuationItemDTO[];
    valuationGroups: ValuationGroupDTO[];
};

export type ValuationGroupDTO = {
    id: string;
    name: string;
    isVariation: boolean;
};
