import { ModelBase, observable } from "@shoothill/core";
import { IEValuationGridRowModel, IEValuationGridRowModelDTO } from "./IEValuationGridRowModel";
import { action } from "mobx";

export class IEValuationGridPOModel extends ModelBase<IEValuationGridPOModel, IEValuationGridPOModelDTO> {
    @observable
    public id: string = "";

    @observable
    public supplier: string = "";

    @observable
    public poNumberFormatted: string = "";

    @observable
    public valuations = observable<IEValuationGridRowModel>([]);

    @action
    fromDto(model: IEValuationGridPOModelDTO): void {
        this.id = model.id;
        this.supplier = model.supplier;
        this.poNumberFormatted = model.poNumberFormatted;

        this.valuations.replace(
            model.valuations.map((valuation) => {
                const model = new IEValuationGridRowModel();
                model.fromDto(valuation);
                return model;
            }),
        );
    }

    toDto(model: IEValuationGridPOModel): void {}
}

export type IEValuationGridPOModelDTO = {
    id: string;
    supplier: string;
    poNumberFormatted: string;
    valuations: IEValuationGridRowModelDTO[];
};
