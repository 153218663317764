import { FieldType, ViewModelBase } from "@shoothill/core";
import { action, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { DownloadDrawingModel } from "./DownloadDrawingModel";

export class DownloadDrawingViewModel extends ViewModelBase<DownloadDrawingModel> {
    public server: ServerViewModel = new ServerViewModel();

    constructor() {
        super(new DownloadDrawingModel());

        this.setDecorators(DownloadDrawingModel);
    }

    // #region Actions

    // #endregion Actions

    @action
    public apiGetItemDownloadUrl = async (drawingId: string, versionId: string): Promise<string | null> => {
        let downloadUrl: string | null = null;

        await this.server.query<string>(
            () =>
                this.Get(
                    AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.AutodeskCloudAPI.GetItemDownloadUrl.replace("{drawingId}", drawingId).replace(
                        "{versionId}",
                        encodeURIComponent(versionId),
                    ),
                ),
            (result) => {
                runInAction(() => {
                    downloadUrl = result;
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }

        return downloadUrl;
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<DownloadDrawingModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
