import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";

export enum DrawingDocumentSource {
    FromComputer = 10,
    FromConstructionCloud = 20,
}

export class AddDrawingModel extends ModelBase<AddDrawingModel, any> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_DESCRIPTION = null;
    public static readonly DEFAULT_APPROVERUSERID = null;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = AddDrawingModel.DEFAULT_ID;

    @observable
    public description: string | null = AddDrawingModel.DEFAULT_DESCRIPTION;

    @observable
    public approverUserId: string | null = AddDrawingModel.DEFAULT_APPROVERUSERID;

    @observable
    public documentSource: DrawingDocumentSource | null = null;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = AddDrawingModel.DEFAULT_ID;
        this.description = AddDrawingModel.DEFAULT_DESCRIPTION;
        this.approverUserId = AddDrawingModel.DEFAULT_APPROVERUSERID;
    };

    @action
    public fromDto(dto: DrawingInsertResponseDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): DrawingInsertRequestDTO {
        return {
            description: this.description,
            approverUserId: this.approverUserId,
            files: [],
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateApproverUserId(): string {
        // RULES
        // Must be selected.
        return this.approverUserId === null || this.approverUserId === undefined ? "Please select a user to approve" : "";
    }

    @computed
    public get validateDescription(): string {
        // RULES
        // Must be selected.
        return this.description === null || this.description === undefined ? "Please add a description" : "";
    }
}

export interface DrawingInsertRequestDTO {
    description: string | null;
    approverUserId: string | null;
    files: any[];
}

export interface DrawingInsertResponseDTO extends DrawingInsertRequestDTO {
    id: string | null;
}
