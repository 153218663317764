export class IssueItemViewModel {
    // #region Properties

    public outputTypeId: string = "";
    public outputTypeDisplayName: string = "";
    public drawings: IDrawing[] = [];

    // #endregion Properties
}

export interface IDrawing {
    drawingId: string;
    drawingFileName: string;
    drawingAttachmentUrl: string;
    drawingInlineUrl: string;
    autodeskVersionId: string | null;
}
