import { Link } from "@material-ui/core";
import React, { useState } from "react";

import { DownloadFileIcon } from "Content/DownloadFileIcon";
import { DownloadDrawingContainer } from ".";
import { DownloadDrawingViewModel } from "./DownloadDrawingViewModel";
import { observer, useObserver } from "mobx-react-lite";

interface IProps {
    fileName: string;
    attachmentUrl: string;
    inlineUrl: string;
    autodeskVersionId: string | null;
    drawingId: string | null;
}

export const DownloadDrawingView: React.FC<IProps> = observer((props) => {
    const [viewModel] = useState(() => new DownloadDrawingViewModel());
    /**
     * This is required should you host this component on a table row
     * that is clickable.
     */
    const onLinkClick = (event: any) => {
        event.stopPropagation();
    };

    const onItemClick = async (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (props.drawingId && props.autodeskVersionId) {
            const downloadUrl = await viewModel.apiGetItemDownloadUrl(props.drawingId, props.autodeskVersionId);
            if (downloadUrl) {
                const tempLink = document.createElement("a");
                tempLink.href = downloadUrl;
                tempLink.download = props.fileName;
                tempLink.setAttribute("download", props.fileName);
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
            }
        }
    };

    return useObserver(() => (
        <>
            {props.autodeskVersionId !== null && (
                <DownloadDrawingContainer>
                    <Link className="downloaddrawing-attachment" onClick={(event) => onItemClick(event)} href={"#"} title={`Download ${props.fileName}`}>
                        <DownloadFileIcon />
                    </Link>
                    <Link className="downloaddrawing-inline" onClick={(event) => onItemClick(event)} href={"#"} title={`View ${props.fileName}`}>
                        {props.fileName}
                    </Link>
                </DownloadDrawingContainer>
            )}
            {props.autodeskVersionId === null && (
                <DownloadDrawingContainer>
                    <Link className="downloaddrawing-attachment" onClick={onLinkClick} href={props.attachmentUrl} title={`Download ${props.fileName}`}>
                        <DownloadFileIcon />
                    </Link>
                    <Link className="downloaddrawing-inline" onClick={onLinkClick} href={props.inlineUrl} target="_blank" title={`View ${props.fileName}`}>
                        {props.fileName}
                    </Link>
                </DownloadDrawingContainer>
            )}
        </>
    ));
});
