import { Button, Checkbox, FormHelperText, TextField } from "@material-ui/core";
import { isEmptyOrWhitespace } from "@shoothill/core";
import { observer } from "mobx-react-lite";
import React from "react";

import { DashedSectionSplitter } from "Components/Form/SectionSplitter";
import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AddPlanOfWorkModalStyle } from "Views/Project/Modals/Modal.Styles";
import { FormModalSection, FormRow } from "Views/Stock/Shared/Shared.styles";
import { IDrawing, IssueItemViewModel, IssueOutputDisplayName, IssueTable, IssueViewModel, OutputDrawing, OutputDrawingContainer } from ".";
import { DownloadDrawingView } from "../../Shared";

interface IProps {
    viewModel: IssueViewModel;
}

export const IssueView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title={viewModel.title}
            PaperComponent={AddPlanOfWorkModalStyle}
            actions={
                <React.Fragment>
                    <Button autoFocus color="secondary" disabled={viewModel.server.IsBusy} onClick={viewModel.apiIssueAsync} variant="contained">
                        Send
                    </Button>
                    <CancelButton onClick={viewModel.cancel}>Cancel</CancelButton>
                </React.Fragment>
            }
        >
            <FormModalSection>
                <IssueTable>
                    {viewModel.issueItems.map((rowData: IssueItemViewModel) => (
                        <>
                            <IssueOutputDisplayName>{rowData.outputTypeDisplayName}</IssueOutputDisplayName>
                            <OutputDrawingContainer>
                                {rowData.drawings.map((drawing: IDrawing) => (
                                    <OutputDrawing>
                                        <Checkbox checked={viewModel.isIssueSelected(drawing)} onClick={() => viewModel.setIssueSelection(drawing)} />
                                        <DownloadDrawingView
                                            fileName={drawing.drawingFileName}
                                            attachmentUrl={drawing.drawingAttachmentUrl}
                                            inlineUrl={drawing.drawingInlineUrl}
                                            autodeskVersionId={drawing.autodeskVersionId}
                                            drawingId={drawing.drawingId}
                                        />
                                    </OutputDrawing>
                                ))}
                            </OutputDrawingContainer>
                        </>
                    ))}
                    {viewModel.getError("issueItems") && <FormHelperText>{viewModel.getError("issueItems")}</FormHelperText>}
                </IssueTable>
                <DashedSectionSplitter />
                <FormRow grid dc={"1fr"} showIf={viewModel.model.isIssue}>
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="To:"
                        onBlur={() => viewModel.isFieldValid("toEmailAddress")}
                        onChange={(event) => viewModel.setValue("toEmailAddress", event.target.value)}
                        helperText={viewModel.getError("toEmailAddress")}
                        error={!isEmptyOrWhitespace(viewModel.getError("toEmailAddress"))}
                        value={viewModel.getValue("toEmailAddress")}
                    />
                </FormRow>
                <FormRow grid dc={"1fr"} showIf={viewModel.model.isIssue}>
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="Cc:"
                        onBlur={() => viewModel.isFieldValid("ccEmailAddress")}
                        onChange={(event) => viewModel.setValue("ccEmailAddress", event.target.value)}
                        helperText={viewModel.getError("ccEmailAddress")}
                        error={!isEmptyOrWhitespace(viewModel.getError("ccEmailAddress"))}
                        value={viewModel.getValue("ccEmailAddress")}
                    />
                </FormRow>
                <FormRow grid dc={"1fr"}>
                    <TextField
                        className="textarea-forminput"
                        InputLabelProps={{ shrink: true }}
                        label="Additional notes:"
                        multiline
                        minRows={5}
                        onBlur={() => viewModel.isFieldValid("notes")}
                        onChange={(event) => viewModel.setValue("notes", event.target.value)}
                        helperText={viewModel.getError("notes")}
                        error={!isEmptyOrWhitespace(viewModel.getError("notes"))}
                        value={viewModel.getValue("notes")}
                    />
                </FormRow>
            </FormModalSection>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
