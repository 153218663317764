import { ApiResult, FieldType, ViewModelBase, observable } from "@shoothill/core";
import {
    RFICategoryDTO,
    RFIStatusDTO,
    RFISupplierDTO,
    RFIRequestedByDTO,
    UpsertRFIAndRelatedRequestDTO,
    RFIDTO,
    RFIAndRelatedResponseDTO,
    RFIFormModel,
    RFIProjectDetailsDTO,
} from "./RFIFormModel";
import moment from "moment";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AppUrls } from "AppUrls";
import { runInAction, action, computed } from "mobx";
import { RFIFormFileModel, RFIFileDTO } from "../Common/RFIFormFileModel";
import { RFIFormDocumentModel, RFIDocumentDTO } from "../Common/RFIFormDocumentModel";
import { RFIFormDocumentViewModel } from "../Common/RFIFormDocumentViewModel";
import { RFIFormCategoryDocumentViewModel } from "../Common/RFIFormCategoryDocumentViewModel";
import { RFIFormFileViewModel } from "../Common/RFIFormFileViewModel";
import { RFIViewModel } from "../../RFIViewModel";

export class RFIFormViewModel extends ViewModelBase<RFIFormModel> {
    constructor(id: string | null, projectId: string | null, ref: string | null, isrevision: string | null) {
        super(new RFIFormModel());
        this.setDecorators(RFIFormModel);
        this.model.id = id;
        this.model.projectId = projectId!;
        this.loadWithRelated();
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public snackMessage = "";

    @observable
    public snackType = "";

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public title: any = null;

    @observable
    public description: any = null;

    @observable
    public statuses = observable<RFIStatusDTO>([]);

    @observable
    public supplier: { id: string; displayName: string } | null = null;

    @observable
    public category: { id: string; displayName: string } | null = null;

    @observable
    public status: { id: string; displayName: string } | null = null;

    @observable
    public requestedBy: { id: string; displayName: string } | null = null;

    @observable
    public clientReference: { id: string; displayName: string } | null = null;

    @observable
    public suppliers = observable<RFISupplierDTO>([]);

    @observable
    public categories = observable<RFICategoryDTO>([]);

    @observable
    public requestedByUsers = observable<RFIRequestedByDTO>([]);

    @observable
    public rfiProjectDetails: RFIProjectDetailsDTO | null = null;

    @observable
    public rfiViewModels: RFIViewModel[] = [];

    @observable
    public rfiFileViewModels: RFIFormFileViewModel[] = [];

    @observable
    public rfiCategoryDocumentViewModels: RFIFormCategoryDocumentViewModel[] = [];

    @observable
    public rfiDocumentViewModels: RFIFormDocumentViewModel[] = [];

    @observable
    public rfi: RFIDTO | null = null;

    @observable
    public hasLoaded: boolean = false;

    @computed
    public get getHasLoaded(): boolean {
        return this.hasLoaded;
    }

    @computed
    public get clientReferenceFormatted() {
        if (this.model.clientReference === null) {
            return "";
        } else {
            if (!this.model.revision) {
                return "CL" + this.model.clientReference;
            } else {
                return "CL" + this.model.clientReference + "." + this.model.revision ? this.model.revision?.toString() : 0;
            }
        }
    }

    @action
    public getFormTitle(isrevision: string): string {
        if (isrevision === "1") {
            return "CL" + this.model.getValue("clientReference") + "." + (this.model.revision ? this.model.revision.toString() : "") + " - Edit RFI revision";
        } else {
            return "CL" + this.model.getValue("clientReference") + " - Edit RFI";
        }
        return "";
    }

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @action
    public handleSupplierId = (item: { id: string; displayName: string }) => {
        this.supplier = item;
        this.setValue("supplierId", item.id);
    };

    @action
    public handleCategoryId = (item: { id: string; displayName: string }) => {
        this.category = item;
        this.setValue("rfiCategoryId", item.id);
    };

    @action
    public handleStatusId = (item: { id: string; displayName: string }) => {
        this.status = item;
        this.setValue("rfiStatusId", item.id);
    };

    @action
    public handleRequestedById = (item: { id: string; displayName: string }) => {
        this.requestedBy = item;
        this.setValue("requestedByUserId", item.id);
    };

    @observable
    public hasRequestedDateChanged: boolean = false;

    @observable
    public hasResponseRequiredByDateChanged: boolean = false;

    @action
    public setRequestedDate = (value: string | null): void => {
        this.setValue("requestedDate", value);
        this.hasRequestedDateChanged = true;
    };

    @action
    public setResponseRequiredByDate = (value: string | null): void => {
        this.setValue("responseRequiredByDate", value);
        this.hasResponseRequiredByDateChanged = true;
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.RequestForInformation.replace(":projectid", projectId ? projectId : this.model.projectId));
    }

    @action
    public fileChangeForCategory = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                runInAction(() => {
                    let model = new RFIFormDocumentModel();
                    model.rfiId = this.model.id ? this.model.id : "";
                    model.rfiCategoryId = this.model.rfiCategoryId ? this.model.rfiCategoryId : "";
                    model.fileName = data.fileName;
                    model.fileURL = apiResult.payload;
                    model.isDeleted = false;
                    model.createdByUserId = null;
                    this.model.rfiDocuments.push(model);
                    let viewModel = new RFIFormDocumentViewModel(model);
                    this.rfiDocumentViewModels.push(viewModel);
                });
            }
        }
    };

    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                runInAction(() => {
                    let model = new RFIFormFileModel();
                    model.rfiId = "";
                    model.fileName = data.fileName;
                    model.fileURL = apiResult.payload;
                    model.isDeleted = false;
                    model.createdByUserId = null;
                    this.model.rfiAttachments.push(model);
                    let viewModel = new RFIFormFileViewModel(model);
                    this.rfiFileViewModels.push(viewModel);
                });
            }
        }
    };
    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };
    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public deleteRFIAttachment = async (index: number): Promise<void> => {
        this.rfiFileViewModels[index].model.isDeleted = true;
    };

    @action
    public deleteRFIDocument = async (index: number): Promise<void> => {
        this.rfiDocumentViewModels[index].model.isDeleted = true;
    };

    @computed
    public get isFormDisabled(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get hasId(): boolean {
        return this.model.id !== null && this.model.id !== undefined && this.model.id !== "";
    }

    @computed
    public get getTodayDateFormatted(): string {
        return this.model.createdDate ? moment(this.model.createdDate).format("DD/MM/YYYY @ HH:mm").toString() : moment().format("DD/MM/YYYY @ HH:mm").toString();
    }

    @action
    private createViewModels() {
        for (const fileItem of this.model.rfiAttachments) {
            this.rfiFileViewModels.push(new RFIFormFileViewModel(fileItem));
        }
        for (const rfiDocument of this.model.rfiDocuments) {
            this.rfiDocumentViewModels.push(new RFIFormDocumentViewModel(rfiDocument));
        }
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.rfiProjectDetails) {
            return "";
        }
        return this.rfiProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.rfiProjectDetails) {
            return "";
        }
        return this.rfiProjectDetails.projectName;
    }

    @computed
    public get getSupplier(): string | undefined {
        return this.suppliers.find((s) => s.id === this.model.supplierId)?.displayName;
    }

    @observable
    public isFromDarwinOptions = observable<{ id: boolean; displayName: string }>([
        { id: true, displayName: "From Darwin" },
        { id: false, displayName: "From Supplier" },
    ]);

    @computed
    public get isFromDarwinOption() {
        return this.isFromDarwinOptions.find((o) => o.id === this.model.isFromDarwin);
    }

    @action
    public setIsFromDarwin = (isFromDarwin: boolean) => {
        this.model.isFromDarwin = isFromDarwin;

        if (isFromDarwin) {
            this.supplier = null;
            this.setValue("supplierId", RFIFormModel.DEFAULT_SUPPLIER_ID);
            this.setValue("supplierReference", RFIFormModel.DEFAULT_SUPPLIER_REFERENCE);
        }
    };

    public loadWithRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        return await this.server
            .query<RFIAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.ProjectTrackers.RFI.GetRFIAndRelated}\\${this.model.id}`),
                (result) => {
                    runInAction(() => {
                        this.model.fromDto(result);
                        this.categories.replace(result.categories);
                        this.suppliers.replace(result.suppliers);
                        this.statuses.replace(result.statuses);
                        this.rfiProjectDetails = result.rfiProjectDetails;
                        this.createViewModels();
                        const selectedSupplier: RFISupplierDTO | undefined = this.suppliers.find((t) => t.id === this.model.supplierId);
                        const selectedStatus: RFIStatusDTO | undefined = this.statuses.find((t) => t.id === this.model.rfiStatusId);
                        const selectedCategory: RFICategoryDTO | undefined = this.categories.find((t) => t.id === this.model.rfiCategoryId);
                        const selectedRequestedByUser: RFIRequestedByDTO | undefined = this.requestedByUsers.find((t) => t.id === this.model.requestedByUserId);

                        if (selectedSupplier) {
                            this.handleSupplierId(selectedSupplier);
                        }
                        if (selectedStatus) {
                            this.handleStatusId(selectedStatus);
                        }
                        if (selectedCategory) {
                            this.handleCategoryId(selectedCategory);
                        }
                        if (selectedRequestedByUser) {
                            this.handleRequestedById(selectedRequestedByUser);
                        }
                    });
                },
            )
            .finally(() => {
                this.setIsLoading(false);
                this.setHasLoaded(true);
            });
    };

    public upsert = async (isrevision: any): Promise<void> => {
        this.setIsLoading(true);
        const model: RFIDTO = this.model.toDto();
        console.log("model: " + model);
        model.supplierReference = this.model.supplierReference;

        let attachmentsToAdd: RFIFileDTO[] = [];
        let documentsToAdd: RFIDocumentDTO[] = [];

        this.rfiFileViewModels.forEach((item) => {
            const attachmentToAdd: RFIFileDTO = item.model.toDto();
            attachmentToAdd.rfiId = model.id;
            attachmentsToAdd.push(attachmentToAdd);
        });

        this.rfiDocumentViewModels.forEach((item) => {
            const documentToAdd: RFIDocumentDTO = item.model.toDto();
            documentToAdd.rfiId = model.id;
            documentToAdd.rfiCategoryId = model.rfiCategoryId;
            documentsToAdd.push(documentToAdd);
        });

        const request: UpsertRFIAndRelatedRequestDTO = {
            rfi: model,
            rfiAttachments: attachmentsToAdd,
            rfiDocuments: documentsToAdd,
        };
        return await this.server
            .command<RFIAndRelatedResponseDTO>(
                () => this.Post(AppUrls.Server.Projects.ProjectTrackers.RFI.Upsert, request),
                (result: RFIAndRelatedResponseDTO) => {
                    runInAction(() => {
                        if (result) {
                            this.handleCancel(result.rfi.projectId);
                        }
                    });
                },
                this.isMyModelValid,
                "There was an error trying to send the Request for information",
            )
            .finally(() => this.setIsLoading(false));
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    public async isFieldValid(fieldName: keyof FieldType<RFIFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
