import * as Utils from "@shoothill/core";
import { BaseStore, CoreStoreInstance, isNullOrEmpty } from "@shoothill/core";
import { action, observable, runInAction, computed } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Globals/Models";
import { AccountStatus } from "@shoothill/core";
import * as ArrayUtils from "Utils/Array";
import { AppUrls } from "AppUrls";
import { RoleLevelEnum, RoleTypeEnum } from "./Admin/RoleStore";
import { ApprovalDelegateUserDataViewModel } from "Views/Approval/ApprovalDelegate/ApprovalDelegateUserDataViewModel";
import { ApprovalDelegateAndRelatedDTO } from "Views/Approval/ApprovalDelegate/ApprovalDelegateUserDataModel";
export interface AccountStatusExtended extends AccountStatus {
    userRoleLevel: RoleLevelEnum;
    canViewCentralIEs: boolean;
    canViewStock: boolean;
    approvalDelegateAndRelated: ApprovalDelegateAndRelatedDTO;
}

export class AccountStore extends BaseStore {
    @observable public IsLoggedIn: boolean = false;
    @observable public UserName: string = "";
    @observable public DisplayName: string = "";
    @observable public FullName: string = "";
    @observable public UserRoles: string[] = [];
    @observable public UserRoleLevel: RoleLevelEnum | null = 0;
    @observable public CanViewCentralIEs: boolean = false;
    @observable public CanViewStock: boolean = false;
    @observable public InitialStateComplete: boolean = false;

    private jwt: string | null = null;
    private refreshToken: string | null = null;
    private refreshTokenExpiryDate: Date | null = null;
    private tokenViewModel = {};

    public constructor() {
        super();
        AccountStoresInstance = this;
    }

    public async init(stores: Stores, initialState: InitialState) {
        await this.getLoginState(initialState.accountStatus);
        //this.tokenViewModel = new TokenViewModel();
    }

    isJWTValid = async (): Promise<boolean> => {
        let jwt = await Utils.getJWT();
        if (isNullOrEmpty(jwt)) {
            return false;
        }
        let decodedJwt: any = Utils.parseJwt(jwt);
        if (isNullOrEmpty(decodedJwt)) {
            return false;
        } else {
            if (Date.now() >= decodedJwt.exp * 1000) {
                return false;
            }
        }
        return true;
    };

    @action
    public setIsLoggedIn(state: boolean) {
        this.IsLoggedIn = state;
        CoreStoreInstance.SetLoggedIn(false);
    }
    public isInRole = (role: RoleTypeEnum): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }
        return false;
    };

    @action
    public Logout = async (redirect: boolean = true): Promise<void> => {
        await Utils.deleteJWT();
        document.cookie = ".refreshtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        this.setIsLoggedIn(false);
        CoreStoreInstance.SetDisplayName("");
        runInAction(() => {
            this.UserName = "";
            this.DisplayName = "";
            this.UserRoles = [];
        });
        //(window as any).jwt = null;
        if (redirect) {
            window.location.href = "/";
        }
    };

    public getJwt = (): string => {
        return this.jwt as string;
    };

    @action
    private setInitialStateComplete = (state: boolean) => {
        this.InitialStateComplete = state;
    };

    @action
    public getLoginState = async (apiResult: AccountStatusExtended) => {
        let jwt = apiResult?.jwt ?? "";
        if (!jwt || jwt.length === 0) {
            this.jwt = await Utils.getJWT();
            //this.refreshToken = localStorage.getItem(".refreshToken");
            //this.refreshTokenExpiryDate = new Date(localStorage.getItem(".refreshTokenExpiryDate") as string);
        } else {
            this.jwt = jwt;
        }

        if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
            const data = Utils.parseJwt(this.jwt);
            await Utils.setJWT(this.jwt);
            if (data === "") {
                this.setInitialStateComplete(true);
                return;
            }
            //localStorage.setItem(".refreshToken", apiResult?.refreshToken ?? "");
            //localStorage.setItem(".refreshTokenExpiryDate", apiResult?.refreshTokenExpiryDate ?? new Date());
            this.setIsLoggedIn(true);
            this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
            this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
            this.FullName = data["FullName"] as string;
            let roles: string[] = [];
            if (Array.isArray(data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]) === true) {
                // just use the array.
                roles = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string[];
            } else {
                // create an array.
                const temp: string = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string;
                roles.push(temp);
            }
            runInAction(() => {
                this.UserRoles = roles;
                this.UserRoleLevel = apiResult.userRoleLevel;
                this.CanViewCentralIEs = apiResult.canViewCentralIEs;
                this.CanViewStock = apiResult.canViewStock;
                if (apiResult.approvalDelegateAndRelated) {
                    ApprovalDelegateUserDataViewModel.Instance.setServerData(apiResult.approvalDelegateAndRelated, true);
                }
            });

            CoreStoreInstance.SetDisplayName(this.DisplayName);
            this.setInitialStateComplete(true);
            // EN: For Debugging
            //(window as any).jwt = this.jwt;
        }
    };

    public containsRoles = (lookingFor: RoleTypeEnum[]): boolean => {
        let retVal: boolean = false;

        if (this.UserRoles && this.UserRoles.length > 0) {
            const result: string[] = this.UserRoles.filter((role: string) => ArrayUtils.contains(lookingFor, role));

            if (result.length > 0) {
                retVal = true;
            }
        }

        return retVal;
    };

    /**
     * Gets the default route based on the users role. Some roles may only be able to see projects for example.
     * @returns The default route as a string.
     */
    public getDefaultRoute = (): string => {
        const isSiteManager: boolean = this.isInRole(RoleTypeEnum.SiteManager);
        const isSiteTablet: boolean = this.isInRole(RoleTypeEnum.SiteTablet);
        const isAdminRole: boolean = this.isInRole(RoleTypeEnum.Admin);

        if (isSiteManager) {
            return AppUrls.Client.Project.List;
        } else if (isSiteTablet) {
            //return AppUrls.Client.Project.General.replace(":projectid", "3208ccdd-da01-48fc-972a-06e1cc80de6f");
            return AppUrls.Client.Project.SiteTablet.Menu;
        } else if (isAdminRole) {
            return AppUrls.Client.Admin.User.List;
        }

        return "/";
    };

    @computed
    public get isLevel5OrHigher(): boolean {
        return (this.UserRoleLevel && this.UserRoleLevel >= 5) || this.isAdminRole ? true : false;
    }

    @computed
    public get isAdminRole(): boolean {
        return this.isInRole(RoleTypeEnum.Admin);
    }

    @computed
    public get isGeneralRole(): boolean {
        return this.isInRole(RoleTypeEnum.General);
    }

    @computed
    public get isManagerRole(): boolean {
        return this.isInRole(RoleTypeEnum.Managers);
    }

    @computed
    public get isCellHeadRole(): boolean {
        return this.isInRole(RoleTypeEnum.CellHead);
    }

    @computed
    public get isSiteManager(): boolean {
        return this.isInRole(RoleTypeEnum.SiteManager);
    }

    @computed
    public get isSiteTablet(): boolean {
        return this.isInRole(RoleTypeEnum.SiteTablet);
    }

    @computed
    public get isLevel1CommercialRole(): boolean {
        return this.isInRole(RoleTypeEnum.Level1Commercial);
    }

    @computed
    public get isFinanceRole(): boolean {
        return this.isInRole(RoleTypeEnum.Finance);
    }

    @computed
    public get isCEORole(): boolean {
        return this.isInRole(RoleTypeEnum.CEOCOO);
    }

    @computed
    public get isStockTransferRole(): boolean {
        return this.isInRole(RoleTypeEnum.StockTransfer);
    }

    @computed
    public get getCanViewCentralIEs(): boolean {
        return !this.isSiteManager && this.CanViewCentralIEs;
    }

    @computed
    public get getCanViewStock(): boolean {
        return !this.isSiteManager && this.CanViewStock;
    }

    @computed
    public get getCanEditProject(): boolean {
        if (this.isSiteManager) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanAddProject(): boolean {
        if (this.isSiteManager || this.isGeneralRole || this.isLevel1CommercialRole || this.isManagerRole || this.isCellHeadRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanEditProjectStatus(): boolean {
        if (this.isSiteManager || this.isGeneralRole || this.isLevel1CommercialRole || this.isManagerRole || this.isCellHeadRole) {
            return false;
        }

        return true;
    }

    @computed
    public get canEditProjectConstruction(): boolean {
        return this.getCanAddProject || this.isManagerRole || this.isCellHeadRole;
    }

    @computed
    public get getCanAddIE(): boolean {
        if (this.isSiteManager || this.isGeneralRole || this.isLevel1CommercialRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanAddIEInDraft(): boolean {
        if (this.isSiteManager) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanEditIE(): boolean {
        if (this.isSiteManager || this.isGeneralRole || this.isLevel1CommercialRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanEditIEInDraft(): boolean {
        if (this.isSiteManager) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanAddIELine(): boolean {
        if (this.isSiteManager || this.isGeneralRole || this.isLevel1CommercialRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanAddIELineInDraft(): boolean {
        if (this.isSiteManager) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanEditIELine(): boolean {
        if (this.isSiteManager || this.isGeneralRole || this.isLevel1CommercialRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanEditIELineInDraft(): boolean {
        if (this.isSiteManager) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanAddInvoice(): boolean {
        if (this.isGeneralRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanEditInvoice(): boolean {
        if (this.isGeneralRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanAddSupplier(): boolean {
        if (this.isGeneralRole || this.isLevel1CommercialRole || this.isManagerRole || this.isCellHeadRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanEditSupplier(): boolean {
        if (this.isGeneralRole || this.isLevel1CommercialRole || this.isManagerRole || this.isCellHeadRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanEditSupplierReference(): boolean {
        return this.isFinanceRole || this.isCEORole;
    }

    @computed
    public get getCanViewInductions(): boolean {
        if (this.isGeneralRole || this.isLevel1CommercialRole) {
            return false;
        }

        return true;
    }

    @computed
    public get getCanViewSettings(): boolean {
        return this.isAdminRole;
    }

    @computed
    public get getCanTransferStock(): boolean {
        return this.isAdminRole || this.isCEORole || this.isStockTransferRole;
    }

    @computed
    public get getCanDeleteInvoiceAttachments(): boolean {
        return this.isFinanceRole;
    }
}
export let AccountStoresInstance = {} as AccountStore;
