import { Cell, Grid, styled, useObserver, useRouter } from "@shoothill/core";
import { useState, useEffect } from "react";
import { AppUrls } from "AppUrls";
import { Form, FormSection } from "Views/PurchaseOrder/Form/Views/Forms";
import { BackButtonHolder } from "Views/Project/Commercial/IETables/IEGrid.Styles";
import { CustomArrow } from "Views/Project/CustomComponents";
import { pxToRem, theme } from "Globals/Styles/AppTheme";
import { ValuationViewModel } from "./ValuationViewModel";
import { Box } from "@material-ui/core";
import { FormHeader } from "Views/PurchaseOrder/Form/Views/FormHeaders";
import { DashedDivider } from "Views/PurchaseOrder/Form/Views/Dividers";
import React from "react";
import { ValuationGrid, ValuationGridGrandTotals, ValuationGridTotals } from "./ValuationGrid/ValuationGrid";
import { ValuationSumsGrid } from "./ValuationGrid/ValuationSumsGrid";
import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DeleteModal } from "Components/Modal/DeleteModal";

type Props = {
    className?: string;
};

const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

const ValuationViewBase: React.FC<Props> = (props) => {
    // #region Code Behind

    const { match } = useRouter();
    const { poid, valuationid } = match.params as any;
    const [viewModel] = useState(() => new ValuationViewModel({ new: !valuationid, poId: poid, valuationId: valuationid }));
    const { history } = useRouter();
    const [deleteOpen, setDeleteOpen] = useState(false);

    useEffect(() => {
        viewModel.GetValuationDetailsAsync();
    }, []);

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    return useObserver(() => {
        return (
            <Form className={props.className} onSubmit={onSubmit}>
                <BackButtonHolder
                    onClick={() => history.push(AppUrls.Client.Project.IE.replace(":ieid", viewModel.model.ieId) + "#val")}
                    style={{ minHeight: "30px", paddingTop: "3px" }}
                >
                    <div style={{ position: "relative", height: "30px", display: "flex", marginLeft: "10px" }}>
                        <div style={{ position: "absolute", top: "5px" }}>
                            <CustomArrow size={"8px"} color={theme.palette.blue.main} type={"left"} />
                        </div>
                        <div style={{ marginLeft: "20px", fontSize: "18px", fontWeight: "bold", color: theme.palette.blue.main }}>
                            Back to {viewModel.model.ieName} (Valuations)
                        </div>
                    </div>
                </BackButtonHolder>
                <HeaderContainer>
                    <FormHeader displayName={viewModel.model.supplier} />
                </HeaderContainer>
                <DashedDivider gutterBottom={true} borderThickness={2} borderOpacity={0.5} />
                <ValuationDetails>
                    <div>
                        <span className="label">Valuation no.</span>
                        <span>{viewModel.model.valuationNumber.toString().padStart(3, "0")}</span>
                    </div>
                    <div>
                        <span className="label">Valuation date:</span>
                        <span>{viewModel.model.valuationDate.toLocaleDateString("en-GB")}</span>
                    </div>
                    <div>
                        <span className="label">Purchase order:</span>
                        <span>{viewModel.model.poNumberFormatted}</span>
                    </div>
                </ValuationDetails>
                {viewModel.sections.map((section) => (
                    <React.Fragment key={`valuation-section-${section.title}`}>
                        <SectionHeader>{section.title}</SectionHeader>
                        {section.groups.map((group) => (
                            <React.Fragment key={`valuation-group-${group.id}`}>
                                <GroupHeader>{group.name}</GroupHeader>
                                <ValuationGrid items={group.items} canEdit={viewModel.model.canEdit} />
                            </React.Fragment>
                        ))}

                        <div style={{ margin: "50px 0 0" }} />
                        <ValuationGridTotals title={section.totals.title} contractValue={section.totals.contractValue} includingMcd={section.totals.includingMcd} />
                    </React.Fragment>
                ))}

                <div style={{ margin: "50px 0 0" }} />
                <ValuationGridGrandTotals title="Grand total" contractValue={viewModel.grandTotal} amountClaimed={viewModel.grandTotalAmountClaimed} />

                <div style={{ margin: "50px 0 0" }} />
                <ValuationSumsGrid viewModel={viewModel} />
                <div style={{ margin: "200px 0 0" }} />

                <FormButtonsContainer>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                        <Cell ts={2} ds={2}>
                            <PrimaryButton displayName="Save" execute={viewModel.save} fullWidth={true} canExecute={!viewModel.IsLoading && viewModel.model.canEdit} />
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <PrimaryButton
                                displayName="Send for internal approval"
                                execute={viewModel.sendForInternalApproval}
                                fullWidth={true}
                                //canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                                canExecute={false}
                            />
                        </Cell>

                        <Cell ts={2} ds={2}>
                            <DefaultButton
                                displayName="Delete"
                                execute={() => setDeleteOpen(true)}
                                fullWidth={true}
                                canExecute={!viewModel.IsLoading && viewModel.model.canDelete}
                            />
                        </Cell>
                        <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                    </Grid>
                </FormButtonsContainer>

                <DeleteModal
                    open={deleteOpen}
                    onClose={() => setDeleteOpen(false)}
                    onDelete={() => viewModel.deleteValuation()}
                    title="Delete valuation?"
                    text="Are you sure you want to delete this valuation?"
                    isLoading={viewModel.IsLoading || !viewModel.model.canDelete}
                />
            </Form>
        );
    });
};

export const ValuationView = styled(ValuationViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        position: relative;
    }
`;

export const FormButtonsContainer = styled(FormSection)`
    position: sticky;
    bottom: 0;
    padding: 10px 0px;
    background-color: #ffffff;
    border-top: 1px solid lightgrey;
    margin: 0px;
    box-shadow: 0px 1px 6px 0px #888888;

    button {
        > span {
            font-size: ${pxToRem(14)} !important;
        }
    }
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;

    // Header
    > div:nth-child(1) {
        margin: 4px 30px 13.5px 30px;
    }
`;

const ValuationDetails = styled(Box)`
    display: flex;
    flex-direction: row;
    margin: 0 30px;
    font-size: 12px;

    > div {
        margin-right: 20px;
    }

    span.label {
        font-weight: bold;
        margin-right: 10px;
    }
`;

const SectionHeader = styled(Box)`
    font-size: 18px;
    font-weight: bold;
    margin: 30px 30px 10px 30px;
    border-bottom: 1px solid ${theme.palette.grey[300]};
`;

const GroupHeader = styled(Box)`
    font-size: 16px;
    font-weight: bold;
    margin: 30px 30px 10px 30px;
`;
