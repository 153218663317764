import { ModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";

export class DownloadDrawingModel extends ModelBase<DownloadDrawingModel> {
    // #region Defaults

    // #endregion Defaults

    // #region Properties

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: any): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): any {}

    // #endregion Methods

    // #region Custom Validation
}
