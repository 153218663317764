import { ModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import { RFIFormDocumentModel, RFIDocumentDTO } from "../Common/RFIFormDocumentModel";
import { RFIFormFileModel, RFIFileDTO } from "../Common/RFIFormFileModel";
import { RFICreateFormModel, RFIDTO } from "./RFICreateFormModel";

export class RFICreateFormItemsModel extends ModelBase<RFICreateFormItemsModel> {
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_RFIS = [];
    public static readonly DEFAULT_RFI_DOCUMENTS = [];
    public static readonly DEFAULT_RFI_ATTACHMENTS = [];

    @observable
    public projectId: string = RFICreateFormItemsModel.DEFAULT_PROJECT_ID;
    @observable
    public rfiDocuments: RFIFormDocumentModel[] = RFICreateFormItemsModel.DEFAULT_RFI_DOCUMENTS;
    @observable
    public rfiItems: RFICreateFormModel[] = RFICreateFormItemsModel.DEFAULT_RFIS;
    @observable
    public rfiAttachments: RFIFormFileModel[] = RFICreateFormItemsModel.DEFAULT_RFI_ATTACHMENTS;

    @action
    public reset = () => {
        this.projectId = RFICreateFormItemsModel.DEFAULT_PROJECT_ID;
        this.rfiItems.length = 0;
        this.rfiDocuments.length = 0;
        this.rfiAttachments.length = 0;
    };

    @action
    public fromDto(dto: RFIStatusDTO): void {}

    public toDto(dto: RFICreateFormItemsModel): void {}

    @action
    public fromRelatedDto(dto: RFIStatusDTO): void {}
}

export interface RFIStatusDTO {
    id: string;
    displayName: string;
}

export interface RFISupplierDTO {
    id: string;
    displayName: string;
}

export interface RFICategoryDTO {
    id: string;
    displayName: string;
}

export interface RFIRequestedByDTO {
    id: string;
    displayName: string;
}

export interface RFIDarwinSupplierReferenceDTO {
    id: string;
    displayName: string;
}

export interface UpsertCategoryRFIsAndRelatedRequestDTO {
    projectId: string;
    rfiItems: RFIDTO[];
    rfiDocuments: RFIDocumentDTO[];
    rfiAttachments: RFIFileDTO[];
}

export interface RFIRelatedResultResponseDTO {
    suppliers: RFISupplierDTO[];
    statuses: RFIStatusDTO[];
    rfiProjectDetails: RFIProjectDetailsDTO;
    categories: RFICategoryDTO[];
}

export interface RFIProjectDetailsDTO {
    projectReference: string;
    projectName: string;
}
