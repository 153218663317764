import { useObserver } from "mobx-react-lite";
import { IEValuationGridViewModel } from "../IEmodels/IEValuationGridViewModel";
import { ValuationPORow } from "./Rows/ValuationPORow";
import { useState } from "react";
import { ValuationGridViewContainer } from "./ValuationGrid.Styles";
import styled from "styled-components";
import { IEValuationGridPOModel } from "../IEmodels/IEValuationGridPOModel";
import { ValuationRow } from "./Rows/ValuationRow";

type Props = {
    viewModel: IEValuationGridViewModel;
};

export const ValuationGridView = ({ viewModel }: Props) => {
    const initialActiveGridItem = {
        activePO: null as number | null,
    };

    const [activeGridItem, setActiveGridItem] = useState(initialActiveGridItem);

    const setActivePOItem = (newActivePOItem: number) => {
        setActiveGridItem((prevActiveGridItem) => ({
            ...prevActiveGridItem,
            activePO: newActivePOItem,
        }));
    };

    return useObserver(() => (
        <ValuationGridViewContainer>
            {viewModel.model.purchaseOrders.map((po, index) => (
                <div key={`valuation-po-${po.id}`} className={activeGridItem.activePO === index ? "active" : ""}>
                    <ValuationPORow po={po} currentActivePOIndex={activeGridItem.activePO} rowEnable={(i) => setActivePOItem(i)} rowIndex={index} />
                    {activeGridItem.activePO === index && <ActivePOValuationsView po={po} />}
                </div>
            ))}
        </ValuationGridViewContainer>
    ));
};

type ActivePOValuationsViewProps = {
    po: IEValuationGridPOModel;
};

const ActivePOValuationsView = ({ po }: ActivePOValuationsViewProps) => {
    return useObserver(() => (
        <ValuationsSubItem>
            <li>
                <div style={{ display: "flex", alignItems: "center", backgroundColor: "#EDEDED" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ display: "inline-block" }}>Valuations</span>
                    </div>
                </div>
            </li>
            {po.valuations.map((valuation) => (
                <ValuationRow key={`valuation-${valuation.id}`} valuation={valuation} />
            ))}
        </ValuationsSubItem>
    ));
};

const ValuationsSubItem = styled.div`
    > li {
        > div:nth-child(1) {
            margin-left: 50px;
        }
    }
`;
