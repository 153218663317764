import { IconButton, Typography } from "@material-ui/core";
import { Grid, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import { SHBox } from "Components/Box";
import { AddButton, TimerButton } from "Components/Buttons/Buttons";
import { DashedSectionSplitter } from "Components/Form/SectionSplitter";
import { NavigationBarView } from "Components/Navigation/NavigationBar";
import { UploadFileIcon } from "Content/UploadFileIcon";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { PatchedPagination } from "Components/Table/PatchedPagination";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinPage, DarwinPageTitle, DarwinTableButtonContainer, DarwinTablePageContent, DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { pxToRem } from "Globals/Styles/AppTheme";
import { StatusCell } from "Globals/Styles/Control/StatusCell.style";
import { formatDate, formatTime } from "Utils/Format";
import { DrawingsItemViewModel, DrawingsViewModel } from ".";
import { AddDrawingView } from "../AddDrawing";
import { HistoryView } from "../History";
import { UpdateDrawingView } from "../UpdateDrawing";
import { DownloadDrawingView } from "../../Shared";
import { IssueItemViewModel } from "./IssueItemViewModel";
import { IssueView } from "../Issue/IssueView";

export const DrawingsView: React.FunctionComponent = observer(() => {
    const { match } = useRouter();
    const { outputid, projectid } = match.params as any;
    const [viewModel] = useState(() => new DrawingsViewModel(outputid));
    const tableOptions = Defaults.GetDarwinTableOptions() as any;

    return (
        <React.Fragment>
            {/* Display Add Drawing Modal */}
            {viewModel.canDisplayAddDrawing && <AddDrawingView viewModel={viewModel.addDrawingViewModel!} projectId={projectid} />}

            {/* Display Update Drawing Modal */}
            {viewModel.canDisplayUpdateDrawing && <UpdateDrawingView viewModel={viewModel.updateDrawingViewModel!} projectId={projectid} />}

            {/* Display History Modal */}
            {viewModel.canDisplayHistory && <HistoryView viewModel={viewModel.historyViewModel!} />}

            {/* Display Issue Modal */}
            {viewModel.canDisplayIssue && <IssueView viewModel={viewModel} issueViewModel={viewModel.issueViewModel!} />}

            <DarwinPage>
                <NavigationBarView action={viewModel.navigateBack} displayName="Back to outputs" />
                <DashedSectionSplitter />
                <DarwinPageTitle>
                    <Typography variant="h1" color="textPrimary">
                        {viewModel.displayName}
                    </Typography>
                </DarwinPageTitle>
                <DarwinTableToolbar>
                    <Grid columnGap={30} dc={"1fr"} rowGap={15} tc={"1fr"}>
                        <DarwinTableButtonContainer>
                            <TimerButton color="primary" displayName="View history" execute={viewModel.displayHistory} />
                            <AddButton color="primary" displayName="Add new drawings" execute={viewModel.displayAddDrawing} />
                        </DarwinTableButtonContainer>
                    </Grid>
                </DarwinTableToolbar>
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "Drawing",
                                field: "name",
                                width: "auto",
                                render: (rowData: DrawingsItemViewModel) => (
                                    <DownloadDrawingView
                                        fileName={rowData.name!}
                                        attachmentUrl={rowData.attachmentUrl!}
                                        inlineUrl={rowData.inlineUrl!}
                                        autodeskVersionId={rowData.autodeskVersionId}
                                        drawingId={rowData.id}
                                    />
                                ),
                            },
                            {
                                title: "Updated on/by",
                                field: "updateDate",
                                width: "auto",
                                render: (rowData: DrawingsItemViewModel) => (
                                    <SHBox>
                                        <SHBox>{`${formatDate(rowData.updateDate)} @ ${formatTime(rowData.updateDate)} by`}</SHBox>
                                        <SHBox>{rowData.updateByName}</SHBox>
                                    </SHBox>
                                ),
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    fontSize: pxToRem(10),
                                    letterSpacing: pxToRem(-0.2),
                                    width: "250px",
                                },
                            },
                            {
                                title: "Revision",
                                field: "revisionNumber",
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    width: "75px",
                                },
                            },
                            {
                                title: "Drawing status",
                                field: "statusName",
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    width: "250px",
                                },
                                render: (rowData: DrawingsItemViewModel) => (
                                    <StatusCell
                                        className="Unknown"
                                        style={{
                                            backgroundColor: rowData.statusColor,
                                            color: rowData.statusTextColor,
                                            margin: "3px 0",
                                            padding: "2px 0",
                                        }}
                                    >
                                        {rowData.statusName.toLocaleUpperCase()}
                                    </StatusCell>
                                ),
                            },
                            {
                                title: "",
                                field: "",
                                cellStyle: { ...tableOptions.cellStyle, width: "70px" },
                                headerStyle: { ...tableOptions.headerStyle, width: "70px" },
                                render: (rowData: any) => (
                                    <div>
                                        {rowData.canReviseDrawing && (
                                            <IconButton style={{ padding: "5px" }} size="small" onClick={() => viewModel.displayUpdateDrawing(rowData)} title="Update drawing">
                                                <UploadFileIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                ),
                                sorting: false,
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        data={viewModel.filteredDrawings}
                        options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                        title=""
                    />

                    <br />

                    <Typography variant="h2" color="textPrimary">
                        {"Communications"}
                    </Typography>

                    <br />

                    <MaterialTable
                        columns={[
                            {
                                title: "Notes",
                                field: "notes",
                                width: "auto",
                            },
                            {
                                title: "Drawings",
                                field: "drawingsCount",
                                width: "auto",
                                render: (rowData: IssueItemViewModel) => <SHBox>{rowData.drawingsCount} Drawing(s)</SHBox>,
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    fontSize: pxToRem(10),
                                    letterSpacing: pxToRem(-0.2),
                                    width: "250px",
                                },
                            },
                            {
                                title: "To addresses",
                                field: "toAddresses",
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    width: "100px",
                                },
                            },
                            {
                                title: "Cc addresses",
                                field: "ccAddresses",
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    width: "100px",
                                },
                            },
                            {
                                title: "Updated on/by",
                                field: "createdDate",
                                width: "auto",
                                render: (rowData: IssueItemViewModel) => (
                                    <SHBox>
                                        <SHBox>{`${formatDate(rowData.createdDate)} @ ${formatTime(rowData.createdDate)} by`}</SHBox>
                                        <SHBox>{rowData.createdBy}</SHBox>
                                    </SHBox>
                                ),
                                cellStyle: {
                                    ...tableOptions.cellStyle,
                                    fontSize: pxToRem(10),
                                    letterSpacing: pxToRem(-0.2),
                                    width: "250px",
                                },
                            },
                        ]}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        data={viewModel.filteredIssues}
                        options={{ ...tableOptions, filtering: false, search: false, toolbar: false }}
                        title=""
                        onRowClick={(e, rowData) => viewModel.displayIssue(rowData!)}
                    />
                </DarwinTablePageContent>
                <SnackBar
                    messageText={viewModel.snackMessage}
                    messageType={viewModel.snackType}
                    active={viewModel.snackbarState}
                    closeOption={() => viewModel.setSnackbarState(false)}
                    autoHideDuration={5000}
                />
            </DarwinPage>
        </React.Fragment>
    );
});
