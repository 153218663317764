import { useObserver } from "mobx-react-lite";
import { GridHeader } from "../IETables/IEGrid.Styles";
import { IEValuationGridViewModel } from "../IEmodels/IEValuationGridViewModel";
import React from "react";
import { ValuationGridView } from "../ValuationTables/ValuationGridView";
import { ValuationGridViewContainer } from "../ValuationTables/ValuationGrid.Styles";

type Props = {
    viewModel: IEValuationGridViewModel;
    ieId: string;
};

export const ValuationsTab = ({ viewModel, ieId }: Props) => {
    React.useEffect(() => {
        viewModel.GetValuationGrid(ieId);
    }, [viewModel, ieId]);

    return useObserver(() => (
        <div style={{ width: "100%", paddingTop: 22 }}>
            <GridHeader>
                <ValuationGridViewContainer>
                    <li className="heading">
                        <div>Purchase Order</div>
                        <div>Supplier</div>
                        <div>Valuation date</div>
                        <div>Total contract value</div>
                        <div>Total including MCD</div>
                        <div>Total value at last claim</div>
                        <div>Total claim %</div>
                        <div>Amount claimed</div>
                    </li>
                </ValuationGridViewContainer>
            </GridHeader>

            <ValuationGridView viewModel={viewModel} />
        </div>
    ));
};
