import { useObserver } from "mobx-react-lite";
import styled from "styled-components";
import { GridViewBase } from "./ValuationGrid.Styles";
import * as Format from "../../../../Utils/Format";
import { ValuationViewModel } from "../ValuationViewModel";
import { TextField } from "@material-ui/core";

type Props = {
    viewModel: ValuationViewModel;
};

export const ValuationSumsGrid = (props: Props) => {
    return useObserver(() => (
        <GridView>
            <li className="item">
                <div className="left grand-total">
                    <span>Main contractor discount</span>
                    <TextField
                        value={props.viewModel.model.mainContractorsDiscount?.toString() ?? ""}
                        onChange={(e) => props.viewModel.setValue("mainContractorsDiscount", e.target.value ? parseFloat(e.target.value) : null)}
                        type="number"
                        placeholder="0"
                        inputProps={{
                            type: "number",
                            max: 100,
                            min: 0,
                        }}
                        disabled={!props.viewModel.isFirstValuation || !props.viewModel.model.canEdit}
                    />
                </div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.viewModel.mainContractorsDiscountTotal)}</div>
                <div className="grand-total"></div>
            </li>
            <li className="item">
                <div className="left grand-total">
                    <span>Deduct retention at 3.0%; or 1.5% on practical completion</span>
                    <TextField
                        fullWidth={false}
                        value={props.viewModel.model.retention?.toString() ?? ""}
                        onChange={(e) => props.viewModel.setValue("retention", e.target.value ? parseFloat(e.target.value) : null)}
                        type="number"
                        placeholder="0"
                        inputProps={{
                            type: "number",
                            max: 100,
                            min: 0,
                        }}
                        disabled={!props.viewModel.isFirstValuation || !props.viewModel.model.canEdit}
                    />
                </div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.viewModel.retentionTotal)}</div>
                <div className="grand-total"></div>
            </li>
            <li className="item">
                <div className="left grand-total">Net Claim After Main Contractors Discount and Retention</div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.viewModel.netClaim)}</div>
                <div className="grand-total"></div>
            </li>
            <li className="item">
                <div className="left grand-total">Previously paid</div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.viewModel.model.previouslyPaid)}</div>
                <div className="grand-total"></div>
            </li>
            <li className="item">
                <div className="left grand-total">Amount Payable On This Application (Ex VAT)</div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.viewModel.amountPayable)}</div>
                <div className="grand-total"></div>
            </li>
            <li className="item">
                <div className="left grand-total">VAT @ 20%</div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.viewModel.vatTotal)}</div>
                <div className="grand-total"></div>
            </li>
            <li className="item">
                <div className="left grand-total">Total Amount Payable on this Application (Inc VAT)</div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="grand-total"></div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.viewModel.totalAmountPayable)}</div>
                <div className="grand-total"></div>
            </li>
        </GridView>
    ));
};

const GridView = styled(GridViewBase)`
    .MuiTextField-root {
        width: auto;
        margin-left: auto;
    }
`;
