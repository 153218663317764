import { ApiResult, FieldType, ViewModelBase, isEmptyOrWhitespace, observable } from "@shoothill/core";
import { RFICategoryDTO, RFIStatusDTO, RFISupplierDTO, RFIProjectDetailsDTO } from "./RFICreateFormItemsModel";
import moment from "moment";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AppUrls } from "AppUrls";
import { runInAction, action, computed } from "mobx";
import { RFIFormFileModel } from "../Common/RFIFormFileModel";
import { RFIFormDocumentViewModel } from "../Common/RFIFormDocumentViewModel";
import { RFIFormCategoryDocumentViewModel } from "../Common/RFIFormCategoryDocumentViewModel";
import { RFIFormFileViewModel } from "../Common/RFIFormFileViewModel";
import { RFIViewModel } from "../../RFIViewModel";
import { RFICreateFormModel } from "./RFICreateFormModel";

export class RFICreateFormItemViewModel extends ViewModelBase<RFICreateFormModel> {
    constructor(item: RFICreateFormModel | null) {
        super(item ? item : new RFICreateFormModel());
        this.setDecorators(RFICreateFormModel);
    }

    public server: ServerViewModel = new ServerViewModel();

    @observable
    public snackMessage = "";

    @observable
    public snackType = "";

    @observable
    public SNACKSUCCESS = "success";

    @observable
    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public title: any = null;

    @observable
    public description: any = null;

    @observable
    public statuses = observable<RFIStatusDTO>([]);

    @observable
    public supplier: { id: string; displayName: string } | null = null;

    @observable
    public category: { id: string; displayName: string } | null = null;

    @observable
    public status: { id: string; displayName: string } | null = null;

    @observable
    public requestedBy: { id: string; displayName: string } | null = null;

    @observable
    public clientReference: { id: string; displayName: string } | null = null;

    @observable
    public suppliers = observable<RFISupplierDTO>([]);

    @observable
    public categories = observable<RFICategoryDTO>([]);

    @observable
    public rfiProjectDetails: RFIProjectDetailsDTO | null = null;

    @observable
    public rfiViewModels: RFIViewModel[] = [];

    @observable
    public rfiFileViewModels: RFIFormFileViewModel[] = [];

    @observable
    public rfiCategoryDocumentViewModels: RFIFormCategoryDocumentViewModel[] = [];

    @observable
    public rfiDocumentViewModels: RFIFormDocumentViewModel[] = [];

    @observable
    public hasLoaded: boolean = false;

    @computed
    public get getHasLoaded(): boolean {
        return this.hasLoaded;
    }

    @action
    public getFormTitle(isrevision: string): string {
        return "New RFI";
    }

    @action
    public setHasLoaded = (val: boolean) => {
        this.hasLoaded = val;
    };

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @action
    public handleSupplierId = (item: { id: string; displayName: string }) => {
        this.supplier = item;
        this.setValue("supplierId", item.id);
    };

    @action
    public handleCategoryId = (item: { id: string; displayName: string }) => {
        this.category = item;
        this.setValue("rfiCategoryId", item.id);
    };

    @action
    public handleStatusId = (item: { id: string; displayName: string }) => {
        this.status = item;
        this.setValue("rfiStatusId", item.id);
    };

    @observable
    public hasRequestedDateChanged: boolean = false;

    @observable
    public hasResponseRequiredByDateChanged: boolean = false;

    @action
    public setRequestedDate = (value: string | null): string => {
        this.setValue("requestedDate", value);
        this.hasRequestedDateChanged = true;
        if (value) {
            return value;
        }
        return "";
    };

    @action
    public setResponseRequiredByDate = (value: string | null): string => {
        this.setValue("responseRequiredByDate", value);
        this.hasResponseRequiredByDateChanged = true;
        if (value) {
            return value;
        }
        return "";
    };

    @action
    public reset = () => {
        this.model.reset();
        this.server.reset();
    };

    @action
    public handleCancel(projectId: string | null): void {
        this.reset();
        this.history.push(AppUrls.Client.Project.RequestForInformation.replace(":projectid", projectId ? projectId : this.model.projectId));
    }

    @action
    public fileChange = async (event: React.ChangeEvent<HTMLInputElement>, uniqueId: string): Promise<void> => {
        if (event.target.files !== null && event.target.value !== null && event.target.files.length > 0) {
            let data: any = {
                fileName: event.target.files[0].name,
                formFile: event.target.files[0],
            };
            event.target.value = "";
            const apiResult = await this.fileUpload(data);
            if (apiResult && apiResult.wasSuccessful) {
                runInAction(() => {
                    let model = new RFIFormFileModel();
                    model.rfiId = "";
                    model.fileName = data.fileName;
                    model.fileURL = apiResult.payload;
                    model.isDeleted = false;
                    model.createdByUserId = null;
                    model.uniqueId = uniqueId;
                    this.model.rfiAttachments.push(model);
                    let viewModel = new RFIFormFileViewModel(model);
                    this.rfiFileViewModels.push(viewModel);
                });
            }
        }
    };
    /**
     * Upload a file to azure.
     * @param data The data of the file to be uploaded.
     * @returns apiResult.
     */
    public fileUpload = async (data: any): Promise<ApiResult<any>> => {
        const formData = new FormData();
        formData.append("formFile", data.formFile);
        formData.append("fileName", data.fileName);
        const apiResult = await this.Post<any>(AppUrls.Server.File.UploadFile, formData);
        if (apiResult) {
            if (!apiResult.wasSuccessful) {
                console.log(apiResult.errors);
                runInAction(() => {
                    this.setSnackMessage("Error uploading file please try again.");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
        }
        return apiResult;
    };
    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    @action
    public deleteRFIAttachment = async (index: number): Promise<void> => {
        this.rfiFileViewModels[index].model.isDeleted = true;
    };

    @action
    public deleteRFIDocument = async (index: number): Promise<void> => {
        this.rfiDocumentViewModels[index].model.isDeleted = true;
    };

    @computed
    public get getTodayDateFormatted(): string {
        return moment().format("DD/MM/YYYY @ HH:mm").toString();
    }

    @computed
    public get getProjectReferenceFormatted(): string {
        if (!this.rfiProjectDetails) {
            return "";
        }
        return this.rfiProjectDetails.projectReference;
    }

    @computed
    public get getProjectName(): string {
        if (!this.rfiProjectDetails) {
            return "";
        }
        return this.rfiProjectDetails.projectName;
    }

    @computed
    public get getSupplier(): string | undefined {
        return this.suppliers.find((s) => s.id === this.model.supplierId)?.displayName;
    }

    @observable
    public isFromDarwinOptions = observable<{ id: boolean; displayName: string }>([
        { id: true, displayName: "From Darwin" },
        { id: false, displayName: "From Supplier" },
    ]);

    @computed
    public get isFromDarwinOption() {
        return this.isFromDarwinOptions.find((o) => o.id === this.model.isFromDarwin);
    }

    @action
    public setIsFromDarwin = (isFromDarwin: boolean) => {
        this.model.isFromDarwin = isFromDarwin;

        if (isFromDarwin) {
            this.setValue("supplierId", RFICreateFormModel.DEFAULT_SUPPLIER_ID);
            this.setValue("supplierReference", RFICreateFormModel.DEFAULT_SUPPLIER_REFERENCE);
        }
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };
    public async isFieldValid(fieldName: keyof FieldType<RFICreateFormModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "title": {
                const result = this.validateTitle;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            // case "supplierReference": {
            //     const result = this.validateSupplierReference;
            //     errorMessage = result.errorMessage;
            //     isValid = result.isValid;
            //     break;
            // }
            case "requestedDate": {
                const result = this.validateRequestedDate;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "responseRequiredByDate": {
                const result = this.validateResponseRequiredByDate;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "supplierId": {
                const result = this.validateSupplierId;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "rfiStatusId": {
                const result = this.validateStatusId;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "description": {
                const result = this.validateDescription;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            case "rfiCategoryId": {
                const result = this.validateRFICategoryId;
                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
            default: {
                errorMessage = "";
                isValid = true;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @computed
    private get validateTitle() {
        const errorMessage = this.model.validateTitle;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSupplierReference() {
        const errorMessage = this.model.validateSupplierReference;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateRequestedDate() {
        const errorMessage = this.model.validateRequestedDate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateResponseRequiredByDate() {
        const errorMessage = this.model.validateResponseRequiredByDate;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateSupplierId() {
        const errorMessage = this.model.validateSupplierId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateStatusId() {
        const errorMessage = this.model.validateStatusId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateDescription() {
        const errorMessage = this.model.validateDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateRFICategoryId() {
        const errorMessage = this.model.validateRFICategoryId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
