import { ViewModelBase } from "@shoothill/core";
import { IEValuationModalModel } from "./IEValuationModalModel";
import { AppUrls } from "AppUrls";
import { PurchaseOrderRelatedDTO, SupplierRelatedDTO, ValuationRelatedDTO } from "../IE.Model";
import { action, computed, observable, runInAction } from "mobx";

export class IEValuationModalViewModel extends ViewModelBase<IEValuationModalModel> {
    private readonly ieId: string;

    @observable
    public suppliers = observable<SupplierRelatedDTO>([]);

    @observable
    public purchaseOrders = observable<PurchaseOrderRelatedDTO>([]);

    public constructor(ieId: string) {
        super(new IEValuationModalModel(), false);
        this.setDecorators(IEValuationModalModel);

        this.ieId = ieId;

        this.GetValuationRelated();
    }

    @computed
    public get selectedSupplier(): SupplierRelatedDTO | null {
        return this.suppliers.find((supplier) => supplier.id === this.getValue("supplierId")) ?? null;
    }

    @computed
    public get purchaseOrdersOptions(): PurchaseOrderRelatedDTO[] {
        return this.purchaseOrders.filter((purchaseOrder) => purchaseOrder.supplierId === this.getValue("supplierId"));
    }

    @computed
    public get selectedPurchaseOrder(): PurchaseOrderRelatedDTO | null {
        return this.purchaseOrdersOptions.find((purchaseOrder) => purchaseOrder.id === this.getValue("purchaseOrderId")) ?? null;
    }

    @action
    private GetValuationRelated = async (): Promise<void> => {
        this.setIsLoading(true);
        let apiResult = await this.Get<ValuationRelatedDTO>(AppUrls.Server.Valuation.GetValuationRelated.replace("{ieId}", this.ieId));
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.suppliers.replace(apiResult.payload.suppliers);
                    this.purchaseOrders.replace(apiResult.payload.purchaseOrders);
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    };

    public async isFieldValid(fieldName: never, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    beforeUpdate: undefined;
    afterUpdate: undefined;
}
