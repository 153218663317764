import React, { useEffect, useState } from "react";
import { Grid, useObserver, useRouter } from "@shoothill/core";
import MaterialTable from "material-table";
import * as Defaults from "Globals/Defaults/TableOptions";
import { DarwinTablePageContent } from "Globals/Styles/AppStyling";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { RFIItemViewModel } from "./RFIItemViewModel";
import { RFIItemResponseViewModel } from "./RFIItemResponseViewModel";
import { AddEditResponseView } from "./Modals/Response/AddEditResponseView";
import { RFIFileView } from "./Modals/Files/RFIFileView";
import { RFIStatusView } from "./Modals/Status/RFIStatusView";
import { styled } from "@shoothill/core";
import { CustomHr } from "../../CustomComponents";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { pxToRem } from "Globals/Styles/AppTheme";
import { RFIViewModel } from "./RFIViewModel";
import { PatchedPagination } from "../../../../Components/Table/PatchedPagination";
import { TableListSearch } from "./../../../../Components/Table/TableListSearch";

// Styling
import EditLogo from "Content/AddEdit.svg";
import DeleteLogo from "Content/Bin.svg";
import RevisionLogo from "Content/Revision.svg";
import { SolidDivider } from "Views/PurchaseOrder/Form/Views/Dividers";

interface IRFITableProps {
    data: RFIItemViewModel[];
    isLoading: boolean;
    handleSearchChange: (val: string) => void;
    projectId: string;
    viewModel: RFIViewModel;
}

export const RFITable: React.FunctionComponent<IRFITableProps> = (props: IRFITableProps) => {
    const { history } = useRouter();
    const viewModel = props.viewModel;
    const classes = useStyles();
    const [sortedState, setSortedState] = useState<RFIItemViewModel[]>([]);
    //useEffect below only gets run on initial render
    useEffect(() => {
        handleSortChange();
        return () => {};
    }, []);

    useEffect(() => {
        // Added because the above useState for sortedState doesn't get updated and it's keeping the data in the material table from showing as updated.
        setSortedState(props.data);
        return () => {};
    });

    const setSearchString = (value: string) => {
        props.handleSearchChange(value);
    };

    const handleSortChange = (orderBy: number = 10, orderDirection: "asc" | "desc" = "asc") => {
        if (orderBy === 10) {
            if (orderDirection === "asc") {
                const sortedData = props.data.slice().sort((a: RFIItemViewModel, b: RFIItemViewModel) => {
                    const dateA = a.model.requestedDate ? new Date(a.model.requestedDate).getTime() : 0;
                    const dateB = b.model.requestedDate ? new Date(b.model.requestedDate).getTime() : 0;

                    return dateA - dateB;
                });
                setSortedState(sortedData);
            } else {
                const sortedData = props.data.slice().sort((a: RFIItemViewModel, b: RFIItemViewModel) => {
                    const dateA = a.model.requestedDate ? new Date(a.model.requestedDate).getTime() : 0;
                    const dateB = b.model.requestedDate ? new Date(b.model.requestedDate).getTime() : 0;

                    return dateB - dateA;
                });
                setSortedState(sortedData);
            }
        }
    };

    const renderPage = () => {
        return (
            <>
                <SearchBarContainer>
                    <TableListSearch placeholder={"Keyword search"} searchString={viewModel.getSearchString()} setSearchString={setSearchString} />
                    <PrimaryButton displayName="Create new" execute={() => viewModel.handleCreateNew(props.projectId)} fullWidth={false} canExecute={!props.isLoading} />
                    <PrimaryButton displayName="Import" fullWidth={false} canExecute={!props.isLoading} />
                    <PrimaryButton displayName="Export all" fullWidth={false} canExecute={!props.isLoading} execute={() => viewModel.exportAsCSV(props.projectId)} />
                </SearchBarContainer>

                <Grid padding={"15px 0px 0px 0px"}>
                    <Box flex>
                        <span className="small-title-rfi">Total Drafts: {viewModel.getDraftCount}</span>
                        <span className="small-title-rfi">Total Awaiting Issue: {viewModel.getAwaitingIssueCount}</span>
                        <span className="small-title-rfi">Total Closed: {viewModel.getClosedCount}</span>
                        <span className="small-title-rfi" hidden={true}>
                            Total Responces: {viewModel.getRFIItemResponsesLength}
                        </span>
                    </Box>
                </Grid>
                <CustomHr margin={"6px 0"} padding={"0px 0px"} />
                <DarwinTablePageContent>
                    <MaterialTable
                        columns={[
                            {
                                title: "No. of responses",
                                field: "numberOfResponses",
                                render: (rowData: RFIItemViewModel) => (
                                    <PrimaryButton
                                        displayName={`${viewModel.handleReturnResponseCount(rowData)}`}
                                        fullWidth={false}
                                        canExecute={!props.isLoading}
                                        stopPropagation={true}
                                    />
                                ),
                            },
                            { title: "Category", field: "categoryName" },
                            {
                                title: "Client ref.",
                                field: "clientReference",
                                render: (rowData: RFIItemViewModel) => (
                                    <>
                                        <span>{rowData.clientReferenceFormatted}</span>
                                    </>
                                ),
                            },
                            { title: "Supplier", field: "supplierName" },
                            { title: "Item", field: "title" },
                            { title: "Item description", field: "description" },
                            {
                                title: "Request date",
                                field: "requestedDate",
                                render: (rowData: RFIItemViewModel) => rowData.requestedDateFormatted,
                            },
                            {
                                title: "Response required by",
                                field: "responseRequiredByDate",
                                render: (rowData: RFIItemViewModel) => rowData.responseRequiredByDateFormatted,
                            },
                            {
                                title: "Attchs. issued",
                                field: "numberOfFiles",
                                align: "center",
                                render: (rowData: RFIItemViewModel) => (
                                    <>
                                        {rowData.numberOfFiles > 0 ? (
                                            <PrimaryButton
                                                execute={() => viewModel.handleFilesShow(null, rowData.id, props.projectId)}
                                                displayName={rowData.numberOfFiles.toString()}
                                                fullWidth={false}
                                                canExecute={!props.isLoading}
                                                stopPropagation={true}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ),
                            },
                            {
                                title: "Latest response",
                                field: "latestResponseDate",
                                render: (rowData: RFIItemViewModel) => rowData.latestResponseDateFormatted,
                            },
                            {
                                title: "Status",
                                field: "statusName",
                                defaultSort: "asc",
                                render: (rowData: RFIItemViewModel) => (
                                    <>
                                        <StatusBox $viewModel={viewModel} $id={rowData.model.rfiStatusId}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <DarwinSelect
                                                    displayName=""
                                                    stopPropagation={true}
                                                    executeWithEvent={(e, data: { id: string; displayName: string }) => viewModel.handleStatusId(e, rowData, data, props.projectId)}
                                                    fullWidth={true}
                                                    getOptionLabel={(option: any) => option.displayName}
                                                    options={viewModel.statuses}
                                                    onBlur={() => rowData.isFieldValid("rfiStatusId")}
                                                    placeholder="Please select"
                                                    value={viewModel.handleReturnStatus(rowData)}
                                                    error={rowData.getError("rfiStatusId") !== ""}
                                                    validationMessage={rowData.getError("rfiStatusId")}
                                                    canExecute={true}
                                                />
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                                <span
                                                    onClick={(e) => viewModel.handleRowClick(e, rowData!, props.projectId)}
                                                    style={{ paddingTop: "20px", paddingRight: "20px" }}
                                                    className={classes.inputIcon}
                                                >
                                                    <img src={EditLogo} />
                                                </span>
                                                <span onClick={(e) => viewModel.handleRevisionClick(e, rowData!, props.projectId)} className={classes.inputIcon} id="revision">
                                                    <img src={RevisionLogo} />
                                                </span>
                                            </div>
                                        </StatusBox>
                                    </>
                                ),
                            },
                        ]}
                        detailPanel={(rowData) => {
                            return (
                                <>
                                    <ResponsePanel>
                                        <div>
                                            <span>Response ({viewModel.handleReturnResponseCount(rowData)})</span>
                                        </div>
                                        {viewModel.rFIItemResponseViewModels
                                            .filter((d) => d.rfiId === rowData.id)
                                            .map((item: RFIItemResponseViewModel, index: number) => {
                                                return (
                                                    !item.model.isDeleted && (
                                                        <Box>
                                                            <Box>
                                                                <ResponseItemTextContainer>
                                                                    <Typography variant="body1">{item.model.comment}</Typography>
                                                                </ResponseItemTextContainer>
                                                            </Box>
                                                            <Box>
                                                                <ResponseItemTextContainer>
                                                                    <Typography variant="body1">From {item.model.fromText}</Typography>
                                                                </ResponseItemTextContainer>
                                                            </Box>
                                                            <Box style={{ alignItems: "center" }}>
                                                                <ResponseItemTextContainer>
                                                                    <Typography variant="body1">
                                                                        {item.model.createdByName} on {item.model.receivedDateFormatted}
                                                                    </Typography>
                                                                    <ResponseItemTextContainer>
                                                                        <Typography variant="body1">{`Status Changed to ${item.model.statusName}`}</Typography>
                                                                    </ResponseItemTextContainer>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                                                        <span
                                                                            onClick={() => viewModel.handleAddResponseShow(item.id, rowData.id, props.projectId)}
                                                                            style={{ paddingRight: "20px" }}
                                                                            className={classes.inputIcon}
                                                                        >
                                                                            <img src={EditLogo} />
                                                                        </span>
                                                                        <span className={classes.inputIcon}>
                                                                            <img src={DeleteLogo} />
                                                                        </span>
                                                                    </div>
                                                                </ResponseItemTextContainer>
                                                            </Box>
                                                            <Box>
                                                                <SolidDivider gutterBottom={true} borderThickness={1} borderOpacity={1} />
                                                            </Box>
                                                        </Box>
                                                    )
                                                );
                                            })}
                                        <Box>
                                            <PrimaryButton
                                                execute={() => viewModel.handleAddResponseShow(null, rowData.id, props.projectId)}
                                                displayName="Add response"
                                                fullWidth={false}
                                                canExecute={!props.isLoading}
                                            />
                                        </Box>
                                    </ResponsePanel>
                                </>
                            );
                        }}
                        onOrderChange={(orderBy, orderDirection) => handleSortChange(orderBy, orderDirection)}
                        components={{
                            Pagination: PatchedPagination,
                        }}
                        options={Defaults.GetDarwinTableOptionsNoSearchV2()}
                        data={sortedState}
                        isLoading={props.isLoading}
                        onRowClick={(e, rowData) => {
                            viewModel.handleRowClick(e, rowData!, props.projectId);
                        }}
                        title=""
                    />
                </DarwinTablePageContent>
                {viewModel.addResponse && (
                    <AddEditResponseView
                        rFIId={viewModel.rfiId}
                        id={viewModel.rfiResponseId}
                        open={viewModel.addResponse}
                        onClose={() => viewModel.handleAddResponseClose(props.projectId)}
                    />
                )}
                {/* {viewModel.showItems && <DesignDataItemView designDataId={viewModel.designDataId} open={viewModel.showItems} onClose={() => viewModel.handleShowItemsClose()} />} */}
                {viewModel.showFiles && <RFIFileView rFIId={viewModel.rfiId} open={viewModel.showFiles} onClose={() => viewModel.handleShowFilesClose()} />}
                {viewModel.showStatusChange && (
                    <RFIStatusView
                        rFIId={viewModel.rfiId}
                        open={viewModel.showStatusChange}
                        onClose={() => viewModel.handleStatusChangeClose()}
                        onYes={() => viewModel.handleStatusChangeClose()}
                    />
                )}
            </>
        );
    };

    return useObserver(() => renderPage());
};

const StatusBox = styled(Box)<{ $viewModel: RFIViewModel; $id: string }>`
    .MuiFormControl-root {
        .MuiInputBase-root {
            background-color: ${(props) => props.$viewModel.setBackGroundStatus(props.$id)} !important;
            color: ${(props) => props.$viewModel.setColorStatus(props.$id)} !important;
        }
    }
`;

export const ResponsePanel: any = styled.li`
    position: relative;
    list-style-type: none;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    margin-bottom: 10px;

    > div {
        font-size: 12px;
    }

    > div > span {
        font-weight: bold;
    }
`;

export const ResponseItemTextContainer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 0px;

    p {
        font-size: 12px !important;
    }
`;

export const ClientReference = styled.span`
    text-decoration: underline !important;
    color: blue !important;
`;

export const SearchBarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0px 10px;

    > div:nth-child(1) {
        max-width: 50%;
        width: 100%;
    }

    > button {
        width: auto;
        white-space: nowrap;
        min-width: 165px;
        margin-left: 30px;

        span {
            font-size: ${pxToRem(12)} !important;
        }
    }
`;

export const SubTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 30px 10px;

    > p {
        font-size: 18px;
        letter-spacing: -0.36px;
        font-weight: 600;
    }

    > div {
        button {
            margin-bottom: 8px;
        }

        p {
            font-size: 8px;
            font-weight: 600;
            line-height: 11px;
            letter-spacing: -0.16px;
        }
    }
`;

const useStyles = makeStyles({
    buttonGroup: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    autoCompleteInput: {
        display: "flex",
        justifyContent: "start",
        paddingRight: "30px !important",
        paddingLeft: "40px !important",
        "& div.MuiAutocomplete-inputRoot": {
            width: "90%",
            height: "90%",
            display: "flex",
            alignItems: "center",
        },
    },
    input: {
        "& .MuiInputBase-root": {
            display: "flex !important",
            alignItems: "center !important",
            justifyContent: "center !important",
            margin: "0 5px",
            height: "90%",
            backgroundColor: "gold",
            width: "90%",

            "& > input": {
                textAlign: "right",
                fontSize: "12px",
            },
        },
    },
    inputIcon: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "20px",
    },
});
