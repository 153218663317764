import { FieldType, getHistory, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ApiResult, ValidationResponse } from "@shoothill/core";
import { action, observable, computed, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { FilesViewModel } from "Components/Files/Files/FilesViewModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ApproveDrawingModel, DrawingAndRelatedResponseDTO } from "./ApproveDrawingModel";
import { NotificationBarViewModel } from "Components/NotificationBar/NotificationBarViewModel";
import { DrawingApprovalPanelViewModel } from "./DrawingApprovalPanelViewModel";
import { ApprovalStatusBaseDTO } from "Globals/Models/ApprovalPanelModelBase";
import { AutodeskDocument, Folder, ItemVersion, ProjectTopFolder } from "../AddDrawing/AddDrawingViewModel";
import { DrawingDocumentSource } from "../AddDrawing/AddDrawingModel";

export class ApproveDrawingViewModel extends ViewModelBase<ApproveDrawingModel> {
    public server: ServerViewModel = new ServerViewModel();
    public filesViewModel = new FilesViewModel("image/*, application/pdf");

    constructor(id: string | null) {
        super(new ApproveDrawingModel());
        this.setDecorators(ApproveDrawingModel);
        this.model.id = id;

        this.loadWithRelated().then(() => {
            if (id) {
                this.setIsViewOnly(true);
            }
            if (this.model.projectId && this.model.projectId !== "") {
                this.apiLoadCloudTopLevelFolderAsync(this.model.projectId);
            }
        });
    }

    @observable
    public approvalPanelViewModel: DrawingApprovalPanelViewModel = new DrawingApprovalPanelViewModel();

    //@observable
    //public drawingStatuses = observable<DrawingStatusDTO>([]);

    @observable
    public showSendForApprovalModal: boolean = false;

    @observable
    public isViewOnly: boolean = false;

    @observable
    public isInReviseMode: boolean = false;

    @computed
    public get getIsViewOnly(): boolean {
        return this.isViewOnly;
    }

    @action
    public setIsViewOnly = (val: boolean) => {
        this.isViewOnly = val;
    };

    @computed
    public get isFormDisabled(): boolean {
        if (this.isViewOnly) {
            return this.isViewOnly;
        }

        if (this.canAmendDrawing) {
            return false;
        }

        return true;
    }

    @computed
    public get canAmendDrawing(): boolean {
        return this.approvalPanelViewModel.getCanShowAmenderPanel;
    }

    @action
    public handleShowSendForApprovalModalChange(show: boolean) {
        this.showSendForApprovalModal = show;

        if (!show) {
            this.setRequesterNote(ApproveDrawingModel.DEFAULT_REQUESTERNOTE);
        }
    }

    @computed
    public get getShowSendForApprovalModal() {
        return this.showSendForApprovalModal;
    }

    @action
    public setRequesterNote = (val: string) => {
        this.model.requesterNotes = val;
    };

    @computed
    public get getRequesterNoteValid() {
        return this.model.requesterNotes !== "" && this.model.requesterNotes !== null && this.model.requesterNotes !== undefined;
    }

    @action
    public goBack = () => {
        getHistory().goBack();
    };

    @observable
    public documentSourceOptions = observable<{ id: DrawingDocumentSource; displayName: string }>([
        { id: DrawingDocumentSource.FromComputer, displayName: "From computer" },
        { id: DrawingDocumentSource.FromConstructionCloud, displayName: "From construction cloud" },
    ]);

    @computed
    public get documentSourceOption() {
        return this.documentSourceOptions.find((o) => o.id === this.model.documentSource);
    }

    @computed
    public get isFromComputer() {
        if (this.model.attachmentUrl !== ApproveDrawingModel.DEFAULT_URL && this.model.autodeskVersionId === ApproveDrawingModel.DEFAULT_AUTODESKVERSIONID) {
            return true;
        }
        return this.documentSourceOption && this.documentSourceOption.id === DrawingDocumentSource.FromComputer;
    }

    @computed
    public get isFromCloud() {
        if (this.model.attachmentUrl === ApproveDrawingModel.DEFAULT_URL && this.model.autodeskVersionId !== ApproveDrawingModel.DEFAULT_AUTODESKVERSIONID) {
            return true;
        }
        return this.documentSourceOption && this.documentSourceOption.id === DrawingDocumentSource.FromConstructionCloud;
    }

    @action
    public setDocumentSource = (documentSource: DrawingDocumentSource, overrideExisting: boolean = false) => {
        this.model.documentSource = documentSource;
        this.filesViewModel.reset();
        this.autodeskDocuments = [];

        if (overrideExisting) {
            this.model.inlineUrl = null;
            this.model.attachmentUrl = null;
            this.model.autodeskVersionId = null;
        }
    };

    // #region Actions

    // #endregion Actions
    // #region Api Actions

    @action
    public loadWithRelated = (): Promise<void> => {
        this.setIsLoading(true);
        return this.server
            .query<DrawingAndRelatedResponseDTO>(
                () => this.Get(`${AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.WithRelated.replace("{drawingid}", this.model.id!)}`),
                (result) => {
                    runInAction(() => {
                        this.model.fromDto(result.drawing);

                        if (result.drawing.attachmentUrl === null) {
                            this.setDocumentSource(DrawingDocumentSource.FromConstructionCloud, false);
                        } else {
                            this.setDocumentSource(DrawingDocumentSource.FromComputer, false);
                        }

                        // Set the approval panel data.
                        this.approvalPanelViewModel.model.fromDto(result.approvalPanel);
                        this.approvalPanelViewModel.populateRequisitionPOStatuses(result.requisitionPOStatuses);
                        this.approvalPanelViewModel.approvalHistoryViewModel.fromDto(result.approvalHistory);

                        // Determine whether to show the notification bar.
                        if (this.approvalPanelViewModel.model.id !== null && this.approvalPanelViewModel.model.approvalStatusId !== null) {
                            NotificationBarViewModel.Instance.setItem("drawing");
                            NotificationBarViewModel.Instance.setPath(AppUrls.Client.Project.OutputTracker.Drawings.Edit);
                            NotificationBarViewModel.Instance.setIsActive(!this.approvalPanelViewModel.getIsRejectedOrApproved);
                        }
                    });
                },
            )
            .finally(() => this.setIsLoading(false));
    };

    public upsertApprovalStatus = async (isApproved: boolean, approverNotes: string, requisitionStatusId: string): Promise<void> => {
        let existingApproverNotes = this.approvalPanelViewModel.model.approverNotes !== null ? this.approvalPanelViewModel.model.approverNotes : "";
        let existingRequesterNotes = this.approvalPanelViewModel.model.requesterNotes !== null ? this.approvalPanelViewModel.model.requesterNotes : "";

        const request: ApprovalStatusBaseDTO = {
            requisitionPOId: null,
            variationId: null,
            invoiceId: null,
            drawingId: this.model.id !== null ? this.model.id : "",
            requisitionStatusId: requisitionStatusId,
            requesterNotes: existingRequesterNotes,
            approverNotes: approverNotes !== "" ? approverNotes : existingApproverNotes,
            isApproved: isApproved,
            rowVersion: this.approvalPanelViewModel.model.rowVersion,
        };

        const apiResult = await this.Post<any>(AppUrls.Server.Approval.UpsertDrawingApprovalStatus, request);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.history.push(AppUrls.Client.Approval.List);
            } else {
                console.log(apiResult.errors);
                runInAction(() => {
                    console.log(apiResult.errors);
                    this.setSnackMessage("Failed to process approval");
                    this.setSnackType(this.SNACKERROR);
                    this.setSnackbarState(true);
                });
            }
            //this.setHasLoaded(true);
        }
    };

    public amendDrawingPost = async (): Promise<ApiResult<DrawingAndRelatedResponseDTO>> => {
        this.setIsLoading(true);
        let dto = this.model.toDto();

        const formData = new FormData();

        if (this.isFromComputer) {
            // A collection of drawings.
            for (const file of this.filesViewModel.model?.files) {
                if (file.file) {
                    formData.append("Files", file.file);
                }
            }
        } else {
            // A collection of drawings.
            // Serialize AutodeskDocuments to JSON and append
            const autodeskDocs = this.autodeskDocuments.map((doc) => ({
                itemId: doc.itemId,
                versionId: doc.versionId,
                fileName: doc.fileName,
            }));
            formData.append("AutodeskDocuments", JSON.stringify(autodeskDocs));
        }

        const description = dto.description ?? "";
        const approverUserId = dto.approverUserId ?? "";
        const rowVersion = dto.rowVersion ?? "";

        formData.append("Description", description);
        formData.append("ApproverUserId", approverUserId);
        formData.append("RowVersion", rowVersion);

        const result = await this.Post<DrawingAndRelatedResponseDTO>(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.Amend.replace("{drawingid}", dto.id!), formData)
            .then((apiResult) => {
                if (!apiResult.wasSuccessful) {
                    console.log(apiResult.errors);
                    runInAction(() => {
                        console.log(apiResult.errors);
                        this.setSnackMessage("There was an error trying to amend the drawing");
                        this.setSnackType(this.SNACKERROR);
                        this.setSnackbarState(true);
                        this.handleShowSendForApprovalModalChange(false);
                    });
                }

                return apiResult;
            })
            .finally(() => this.setIsLoading(false));

        return result;
    };

    public amendDrawing = async (): Promise<void> => {
        return this.server.command<DrawingAndRelatedResponseDTO>(
            () => this.amendDrawingPost(),
            (result) => {
                runInAction(() => {
                    this.model.reset();
                    getHistory().goBack();
                });
            },
            this.isMyModelValid,
            "There was an error trying to amend the drawing",
        );
    };

    @action
    public apiLoadWithRelatedAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.GetDrawing.replace("{drawingid}", this.model.id!)),
            (result) => this.model.fromDto(result),
            "Error whilst loading the output data",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @observable
    public rootFolders: ProjectTopFolder[] = [];

    @observable
    public folders: Folder[] = [];

    @observable
    public itemVersions: ItemVersion[] = [];

    @action
    public apiLoadCloudTopLevelFolderAsync = async (projectId: string): Promise<void> => {
        if (this.rootFolders.length > 0) {
            return;
        }

        await this.server.query<ProjectTopFolder[]>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.AutodeskCloudAPI.GetProjectTopFolders.replace("{projectId}", projectId)),
            (result) => {
                runInAction(() => {
                    this.rootFolders = result;
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadCloudFolderContentsAsync = async (projectId: string, folderId: string): Promise<void> => {
        if (this.folders.some((folder) => folder.parentId === folderId)) {
            return;
        }

        await this.server.query<Folder[]>(
            () =>
                this.Get(
                    AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.AutodeskCloudAPI.GetFolderContents.replace("{projectId}", projectId).replace(
                        "{folderId}",
                        folderId,
                    ),
                ),
            (result) => {
                runInAction(() => {
                    if (!this.folders.some((folder) => folder.parentId === folderId)) {
                        this.folders.push(...result);
                    }
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @observable
    public autodeskDocuments: AutodeskDocument[] = [];

    @action
    public apiLoadCloudItemAsync = async (projectId: string, itemId: string): Promise<void> => {
        if (this.itemVersions.some((item) => item.id === itemId)) {
            return;
        }

        await this.server.query<ItemVersion>(
            () =>
                this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.AutodeskCloudAPI.GetItemTip.replace("{projectId}", projectId).replace("{itemId}", itemId)),
            (result) => {
                runInAction(() => {
                    if (!this.itemVersions.some((item) => item.id === result.id)) {
                        this.itemVersions.push(result);
                        let docToAdd: AutodeskDocument = {
                            itemId: result.itemId,
                            versionId: result.id,
                            fileName: result.displayName,
                        };
                        this.autodeskDocuments.push(docToAdd);
                    }
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    /**
     * Download a file that exists in azure.
     * @param fileUrl The URL of the file to be downloaded.
     * @param fileName The name of the file to be downloaded.
     */
    public DownloadFile = async (fileUrl: string, fileName: string): Promise<void> => {
        //let fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
        try {
            const apiResult = await this.Post<Blob>(AppUrls.Server.File.DownloadFile, fileUrl, undefined, { responseType: "blob" });
            const response = apiResult as any;
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (exception) {
            console.error(exception);
            this.setIsErrored(true);
        }
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // Validate the permit model.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    @computed
    private get validateDescription(): ValidationResponse {
        const errorMessage = this.model.validateDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // @computed
    // private get validateApproverUserId(): ValidationResponse {
    //     const errorMessage = this.model.validateApproverUserId;

    //     return {
    //         errorMessage: errorMessage,
    //         isValid: isEmptyOrWhitespace(errorMessage),
    //     };
    // }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<ApproveDrawingModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "description": {
                const result = this.validateDescription;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            // case "approverUserId": {
            //     const result = this.validateApproverUserId;

            //     errorMessage = result.errorMessage;
            //     isValid = result.isValid;
            //     break;
            // }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}
