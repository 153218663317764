import { useObserver } from "mobx-react-lite";
import styled from "styled-components";
import { ValuationItemViewModel } from "../ValuationItemViewModel";
import * as Format from "../../../../Utils/Format";
import { TextField } from "@material-ui/core";
import { GridViewBase } from "./ValuationGrid.Styles";

type Props = {
    items: ValuationItemViewModel[];
    canEdit?: boolean;
};

export const ValuationGrid = (props: Props) => {
    return useObserver(() => (
        <GridView>
            <li className="heading">
                <div className="left">Description of works</div>
                <div>Contract value</div>
                <div>Including MCD</div>
                <div>Value at last claim</div>
                <div>Claim %</div>
                <div>Amount claimed</div>
                <div>Amount claimed including MCD</div>
            </li>
            {props.items.map((item) => (
                <li key={`valuation-item-${item.model.id}`} className="item">
                    <div className="left">{item.model.itemDescription}</div>
                    <div className="right">{Format.formatCurrencyNoSign(item.model.contractValue)}</div>
                    <div className="right">{Format.formatCurrencyNoSign(item.includingMcd)}</div>
                    <div className="right">{Format.formatCurrencyNoSign(item.model.valueAtLastClaim)}</div>
                    <div className="right">
                        <TextField
                            value={item.model.claimPercentage?.toString() ?? ""}
                            onChange={(e) => item.setClaimPercentage(e.target.value ? parseFloat(e.target.value) : null)}
                            type="number"
                            placeholder="0"
                            inputProps={{
                                type: "number",
                                max: 100,
                                min: Math.round((item.model.previousAmountClaimed / item.model.contractValue) * 10000) / 100,
                            }}
                            disabled={!props.canEdit}
                        />
                    </div>
                    <div className="right">{Format.formatCurrencyNoSign(item.model.amountClaimed)}</div>
                    <div className="right">{Format.formatCurrencyNoSign(item.amountClaimedIncludingMcd)}</div>
                </li>
            ))}
        </GridView>
    ));
};

type TotalsProps = {
    title: string;
    contractValue: number;
    includingMcd: number;
};

export const ValuationGridTotals = (props: TotalsProps) => {
    return useObserver(() => (
        <GridViewTotals>
            <li className="item">
                <div className="right">{props.title}</div>
                <div className="right">{Format.formatCurrencyNoSign(props.contractValue)}</div>
                <div className="right">{Format.formatCurrencyNoSign(props.includingMcd)}</div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
                <div className="right"></div>
            </li>
        </GridViewTotals>
    ));
};

type GrandTotalsProps = {
    title: string;
    contractValue: number;
    amountClaimed: number;
};

export const ValuationGridGrandTotals = (props: GrandTotalsProps) => {
    return useObserver(() => (
        <GridViewGrandTotal>
            <li className="item">
                <div className="left">{props.title}</div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.contractValue)}</div>
                <div className="right grand-total"></div>
                <div className="right grand-total"></div>
                <div className="right grand-total"></div>
                <div className="right grand-total filled">{Format.formatCurrencyNoSign(props.amountClaimed)}</div>
                <div className="right grand-total"></div>
            </li>
        </GridViewGrandTotal>
    ));
};

const GridView = styled(GridViewBase)``;

const GridViewTotals = styled(GridViewBase)`
    li {
        border-top: solid 1px #dddddd;
    }
`;

const GridViewGrandTotal = styled(GridViewTotals)`
    li {
        border-color: #425b66;
        border-width: 4px;

        > div {
            font-size: 16px;
        }
    }
`;
