import { BaseModal } from "Components/Modal/BaseModal";
import { useObserver } from "mobx-react-lite";
import { IEValuationModalViewModel } from "../IEmodels/IEValuationModalViewModel";
import { useState } from "react";
import { DarwinSelect } from "Components/AutoComplete/DarwinSelect";
import { IEButton } from "../IETables/IEGrid.Styles";
import { useRouter } from "@shoothill/core";
import { AppUrls } from "AppUrls";
import { Button } from "@material-ui/core";
import { DefaultButton } from "Components/Buttons/Buttons";
import { IEValuationModalPOContainer, IEValuationModalStyle } from "Views/Project/Modals/Modal.Styles";

type Props = {
    ieId: string;
    open: boolean;
    onClose: () => void;
};

export const IEValuationModal: React.FC<Props> = ({ ieId, open, onClose }) => {
    const [viewModel] = useState(() => new IEValuationModalViewModel(ieId));
    const { history } = useRouter();

    return useObserver(() => (
        <BaseModal
            dialogClasses="project-dialog"
            open={open}
            onClose={onClose}
            title="Create new valuation"
            PaperComponent={IEValuationModalStyle}
            actions={
                <>
                    <Button
                        onClick={() => history.push(AppUrls.Client.Valuation.Add.replace(":poid", viewModel.getValue("purchaseOrderId")))}
                        color="primary"
                        variant="contained"
                        disableElevation
                        style={{ maxWidth: "116px" }}
                        disabled={viewModel.IsLoading}
                    >
                        Create
                    </Button>
                    <DefaultButton execute={onClose} style={{ maxWidth: "116px" }} displayName="Cancel" />
                </>
            }
        >
            <DarwinSelect
                options={viewModel.suppliers}
                getOptionLabel={(option: any) => option.name}
                value={viewModel.selectedSupplier}
                fullWidth
                execute={(option: any) => viewModel.setValue("supplierId", option.id)}
                displayName="Please select supplier"
            />
            <IEValuationModalPOContainer $poDisabled={viewModel.getValue("supplierId") === null}>
                <hr className="supplier-po-divider" />
                <DarwinSelect
                    options={viewModel.purchaseOrdersOptions}
                    getOptionLabel={(option: any) => option.poNumberFormatted}
                    value={viewModel.selectedPurchaseOrder}
                    fullWidth
                    execute={(option: any) => viewModel.setValue("purchaseOrderId", option.id)}
                    displayName="Purchase order"
                    canExecute={viewModel.getValue("supplierId") !== null}
                />
            </IEValuationModalPOContainer>
        </BaseModal>
    ));
};
