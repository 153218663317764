import { ModelBase } from "@shoothill/core";
import { action, computed, observable } from "mobx";
import { DrawingDocumentSource } from "../AddDrawing/AddDrawingModel";

export class UpdateDrawingModel extends ModelBase<UpdateDrawingModel> {
    // #region Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_DESCRIPTION = null;
    public static readonly DEFAULT_APPROVERUSERID = null;
    public static readonly DEFAULT_ROWVERSION = null;

    // #endregion Defaults

    // #region Properties

    @observable
    public id: string | null = UpdateDrawingModel.DEFAULT_ID;

    @observable
    public description: string | null = UpdateDrawingModel.DEFAULT_DESCRIPTION;

    @observable
    public approverUserId: string | null = UpdateDrawingModel.DEFAULT_APPROVERUSERID;

    @observable
    public rowVersion: string | null = UpdateDrawingModel.DEFAULT_ROWVERSION;

    @observable
    public documentSource: DrawingDocumentSource | null = null;

    // #endregion Properties

    // #region Methods

    @action
    public fromDto(dto: DrawingUpdateResponseDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): any {
        return {
            description: this.description,
            approverUserId: this.approverUserId,
            rowVersion: this.rowVersion,
            files: [],
        };
    }

    // #endregion Methods

    // #region Custom Validation

    @computed
    public get validateApproverUserId(): string {
        // RULES
        // Must be selected.
        return this.approverUserId === null || this.approverUserId === undefined ? "Please select a user to approve" : "";
    }

    @computed
    public get validateDescription(): string {
        // RULES
        // Must be selected.
        return this.description === null || this.description === undefined ? "Please add a description" : "";
    }
}

export interface DrawingUpdateRequestDTO {
    description: string | null;
    approverUserId: string | null;
    rowVersion: string | null;
    files: any[];
}

export interface DrawingUpdateResponseDTO extends DrawingUpdateRequestDTO {
    id: string | null;
}
