import * as Models from "Globals/Models";
import * as Utils from "Utils/Compare";

const createVersion = (version: string, date: string, items: string[]): { version: string; date: string; items: { description: string }[] } => {
    return {
        date,
        items: items.map((item) => {
            return { description: item };
        }),
        version,
    };
};

const versions = [
    createVersion("1.1.18", "2024-01-21", ["Hotfix: Removed re-approving after rejection for purchase orders."]),
    createVersion("1.1.17", "2024-01-09", [
        "Added fix for role assignment bug in the IE form.",
        "Updated search on IE PO list to include the PO number.",
        "Added button to PO modal to navigate to the PO form via the IE grid.",
        "Added concurrency fix for purchase order approvals.",
        "Fixed contractual percentage bugs.",
    ]),
    createVersion("1.1.16", "2024-01-08", ["Added fix for supplier contact deletion bug."]),
    createVersion("1.1.15", "2024-01-06", ["Updated invoice lists to show the name of the budget holder for central invoices."]),
    createVersion("1.1.14", "2024-01-02", ["Updated DB."]),
    createVersion("1.1.13", "2024-12-13", [
        "Moved RFI category to item.",
        "Fixed bug with RFI latest response date.",
        'Added "From Darwin" option to RFIs.',
        "Updated stock history to show purchase orders. Per item and for the all list.",
        "Added valuations.",
        "Updated invoice document downloads to persist the file name.",
        "Added fix for supplier contact tile bug.",
        "Added fix for supplier contact type not loading when changing primary contact.",
        "Added fix for case sensitive email address issue.",
    ]),
    createVersion("1.1.12", "2024-12-05", [
        "Added construction API integration for drawings.",
        "Updated new RFI responses to change the RFI status.",
        "Added document uploader to RFI responses.",
        "Updated latest response column to show the date instead of the name.",
    ]),
    createVersion("1.1.11", "2024-12-04", [
        "Added grand total to IE tab.",
        "Updated date picker to handle more formats.",
        "Updated variation approval permissions.",
        "Updated invoices so they can be approved from the invoice list.",
        "Updated POs in the IE to open a modal when clicked instead of navigating away.",
        'Added "Add" button to the groups on the requisition form.',
        "Updated invoice number validation.",
        "Updated permissions for projects and IEs.",
    ]),
    createVersion("1.1.10", "2024-12-02", ["Added fix for PO approved emails not sending to suppliers."]),
    createVersion("1.1.9", "2024-11-26", ["UAT fix for raised by user email."]),
    createVersion("1.1.8", "2024-11-21", ['Updated "Cells" to "Department" on PO lists.']),
    createVersion("1.1.7", "2024-11-20", ["Removed future spend column from the Central I&E grid and CSV export."]),
    createVersion("1.1.6", "2024-11-19", ["Performance improvements for invoice lists."]),
    createVersion("1.1.5", "2024-11-18", [
        "Updated requisition form with performance improvements.",
        "Updated PO, invoice and variation lists with performance improvements.",
        "Set approver to update when re-posting an invoice.",
        "Added email address of user who matched invoice to the dispute email.",
        "Updated approved PO email to send to the user who revised the PO if it shouldn't be sent to the supplier and the supplier is non-backs.",
        "Added CSV exports to top level PO and invoice lists.",
    ]),
    createVersion("1.1.4", "2024-11-06", ["Fixed bug on the approval panel for invoices where the name of the user who last updated was incorrect."]),
    createVersion("1.1.4", "2024-11-06", ["Updated admin role."]),
    createVersion("1.1.3", "2024-11-05", ["Added CSV export to variations and client provisional sums lists."]),
    createVersion("1.1.2", "2024-11-04", ["Added list for rejected variations and client provisional sums."]),
    createVersion("1.1.1", "2024-11-01", [
        "Level 5's can now approve invoices.",
        "Fixed bug preventing purchase orders from being rejected.",
        "Updated stock history to show the user who updated the item.",
        "Added variation and CPS description to the IE grids and the PO category dropdown.",
        "Fixed invoice already allocated modal not showing when adding a PO.",
        "Updated invoices to show the approver role name in lists.",
        "Updated approval lists to filter by supplier name.",
        "Updated requisition note so that is can be edited without having to revise the PO.",
    ]),
    createVersion("1.1.0", "2024-10-24", ["Updated variation and CPS rejections."]),
    createVersion("1.0.9", "2024-10-21", ["Tweaks for Approved Invoice report."]),
    createVersion("1.0.8", "2024-10-21", ["Updated UAT threshhold email address."]),
    createVersion("1.0.7", "2024-10-16", ["Added fix for variation and CPS values on reports, related to date filtering."]),
    createVersion("1.0.6", "2024-10-15", ["Updated UAT database."]),
    createVersion("1.0.5", "2024-10-14", ["Added All Projects Approved Invoice report."]),
    createVersion("1.0.4", "2024-10-11", [
        "Added tickbox next to delivery address on Purchase Orders.",
        "Fixed red bar warning on purchase orders.",
        "Added fix for invoice re-posting from project to central.",
        "Added PO threshold notification.",
        "Fixed bug with duplicate PO notification.",
        "Added fix for level 3 requisition approvals.",
    ]),
    createVersion("1.0.3", "2024-10-01", ["Added fix for CPS and variation filtering on the IE reports.", "Fixed additional variation bugs."]),
    createVersion("1.0.2", "2024-09-30", ["Added fix to invoice validation when revising requisitions."]),
    createVersion("1.0.1", "2024-09-20", ["Fixed additional bugs on the invoice match form.", "Added validation to prevent project type mismatch on the requisition form."]),
    createVersion("0.0.299", "2024-09-19", ["Updated invoice match form to only include approved requisition data.", "Fixed approval concurrency issues for delegations."]),
    createVersion("0.0.298", "2024-09-16", ["Added banner to warn when the duplicate supplier request will require level 5 approval."]),
    createVersion("0.0.297", "2024-09-09", ["Updated variance calculation on the variation form."]),
    createVersion("0.0.296", "2024-09-09", ["Fixed delivery address not being auto selected for pre purchase requisitions."]),
    createVersion("0.0.295", "2024-09-03", ["Fixed date filter bugs with the variations and CPS on the IE reports."]),
    createVersion("0.0.294", "2024-09-02", ["Updated central prepayments to only send a single PDF when approving."]),
    createVersion("0.0.293", "2024-08-27", ["Fixed bug with forgot password email link."]),
    createVersion("0.0.292", "2024-08-23", [
        "Fixed bug causing disputed invoices to be sent to the wrong users for approval.",
        "Added output communications.",
        "Fixed email bug with duplicate email addresses.",
        "Added additional CC emails field to variations.",
        "Added client rejections for variations.",
    ]),
    createVersion("0.0.291", "2024-08-20", [
        "Updated level 3 approval overrides for requisitions.",
        "Added communication section for Drawings.",
        "Updated output filters to include drawing names.",
    ]),
    createVersion("0.0.290", "2024-08-15", [
        "Updated prepayment requisitions to use the same delivery address as the parent.",
        "Added validation for prepayment requisitions.",
        "Added drawing approvals.",
    ]),
    createVersion("0.0.289", "2024-08-12", ["Updated duplication check for multiple suppliers and updated suppliers.", "Fixed future spend issues for the reports."]),
    createVersion("0.0.288", "2024-08-06", ["Fixed calculation bugs with future spend amendments."]),
    createVersion("0.0.287", "2024-08-05", ["Prepayment text changes and info modal.", "Updated prepayment POs to inherit from the original PO."]),
    createVersion("0.0.286", "2024-07-31", ["Updated requisition emails to send to the user who revised instead of the user who created the requisition."]),
    createVersion("0.0.285", "2024-07-26", [
        "Added fix for items being approved by multiple users.",
        "Added error message for an invoice approval failing.",
        "Added approval/disputed panel for invoices.",
    ]),
    createVersion("0.0.284", "2024-07-23", ["Added multiple supplier warning for requisitions."]),
    createVersion("0.0.283", "2024-07-22", ["Added central prepayments to requisitions."]),
    createVersion("0.0.282", "2024-07-22", ["Updated UAT DB."]),
    createVersion("0.0.281", "2024-07-17", ["Fixed validation bug with future spend notes."]),
    createVersion("0.0.280", "2024-07-15", ["Fixed bug with terms not showing when viewing a requisition."]),
    createVersion("0.0.279", "2024-07-12", ["Fixed variation future spend issue on the budget forecast.", "Updated UAT DB."]),
    createVersion("0.0.278", "2024-07-10", [
        "Added validation to the future spend amendment forms.",
        "Fixed CPS number showing as VAR.",
        "Fixed commercial grid issues with variation data.",
    ]),
    createVersion("0.0.277", "2024-07-09", ["Tweaks to requisition form inputs.", "Added user name to the unapproved requisitions modal dialog."]),
    createVersion("0.0.276", "2024-07-09", ["Updated invoice attachment permissions.", "Updated terms and conditions on the requisition form."]),
    createVersion("0.0.275", "2024-07-05", [
        "Added filters to the materials list.",
        "Fixed bug with the delegate approvals buttons.",
        "Updated Sub contractor agreement for purchase orders.",
        "Added supplier contacts to the requisition form.",
    ]),
    createVersion("0.0.274", "2024-07-02", [
        "Added upfront payments field to invoices.",
        "Updated invoice approvals so that if it is upfront payment then it goes to level 4 for approvals.",
        "Added view only page for IE form.",
        "Updated IE and variation future spend amendment form impact calculations.",
        "Added filter to IE and variation future spend amendments page.",
        "Added before and after price to material price change approval modal.",
        "Updated invoices so that only finance users can delete existing attachments.",
        "Updated the central project so that all users can view it.",
        "Fixed bugs on IE invoice list.",
        "Added supplier filter to the purchase order and rejected lists.",
        "Added filters to the stock list.",
        "Fixed errors on the projects reports.",
        "Fixed issue with revising client provisional sums.",
    ]),
    createVersion("0.0.273", "2024-06-20", [
        "Updated invoice approvals so they can be overridden by higher level users.",
        "Updated approvals so that the can be viewed by level 3 users before level 2 users have approved.",
        'Fixed loading bug on the "I need authority" list.',
        "Fixed bug on invoice list for project name.",
        "Fixed bug preventing stock approvals from showing in the approvals lists.",
        "Performance improvements for invoice list.",
    ]),
    createVersion("0.0.272", "2024-06-19", ["Updated invoice approvals to send to finance users if more than one project is assigned."]),
    createVersion("0.0.271", "2024-06-18", [
        "Fixed duplicate variations showing in the variation grid.",
        "Updated invoice approvals to not send to design users.",
        "Fixed future spend bug on the variation grids.",
        "Performance improvements for approvals Resolved list.",
        "Additional performance improvements for Invoice and PO lists.",
    ]),
    createVersion("0.0.270", "2024-06-14", [
        "Updated validation against invoice items when revising a requisition.",
        "Updated sorting for inductions and added Inducted Date column.",
        "Fixed supplier name not showing on project invoice list.",
        "Added Posted Date column for invoices.",
    ]),
    createVersion("0.0.269", "2024-06-13", [
        "Added additional filters to the projects list.",
        "Fixed bug preventing Already Allocated values from loading when adding a PO to an invoice.",
        "Performance improvements for the Required and All approvals lists.",
        "Performance improvements for the Resolved approvals list and the Required counter.",
        "Fixed bug preventing central and stock POs from being sent for approval.",
    ]),
    createVersion("0.0.268", "2024-06-11", ["Updated missing allocation validation for invoice match form."]),
    createVersion("0.0.267", "2024-06-10", [
        "Added the name of the approved to the approved invoice email.",
        "Updated IE report to I & E report.",
        "Updated VRC report to CVR report.",
        "Updated Spend report to Cost report.",
        "Added validation to invoice match form to prevent the form from saving if there are any missing allocations.",
    ]),
    createVersion("0.0.266", "2024-06-07", [
        "Performance improvements for top level PO and rejected PO lists.",
        "Fixed bug causing central and stock POs to be sent to the original user when a revised PO is requested to be amended.",
        "Performance improvements for top level invoice list and IE invoice list.",
        "Fixed bugs with the suggested new future spend.",
    ]),
    createVersion("0.0.265", "2024-06-04", ["Updated invoice approvals for level 1 and 2 under 50k."]),
    createVersion("0.0.264", "2024-06-04", ["Fixed loading bug on the invoice match page."]),
    createVersion("0.0.263", "2024-06-03", ["Fixed bugs where future spend amendments couldn't be viewed or created for variations that are currently being revised."]),
    createVersion("0.0.262", "2024-06-02", ["Updated invoice approvals so that they can always be viewed by finance users."]),
    createVersion("0.0.261", "2024-05-31", ["Updated stock transfer permissions."]),
    createVersion("0.0.260", "2024-05-31", ["Added fix for refresh issue."]),
    createVersion("0.0.259", "2024-05-30", ["Updated approval process for invoices."]),
    createVersion("0.0.258", "2024-05-24", ["Added fix for date filtering on grids and reports."]),
    createVersion("0.0.257", "2024-05-23", ["Enabled reports for all users."]),
    createVersion("0.0.256", "2024-05-22", [
        "Updated invoice status change dropdown to only be editable by finance role users.",
        "Fixed bug preventing users from sending the invoice match form for approval.",
    ]),
    createVersion("0.0.255", "2024-05-21", [
        "Fixed stock balance and value data on the stock history view.",
        "Updated stock history changed by user name to be the name of the user who raised it.",
    ]),
    createVersion("0.0.254", "2024-05-20", ["Added fix for PO list filter bug.", "Updated disputed invoices to be sent to the user who disputed them."]),
    createVersion("0.0.253", "2024-05-17", ["Fixed missing data on the approval lists for stock transfers."]),
    createVersion("0.0.252", "2024-05-17", ["Added Stock History page.", "Added Stock History CSV export."]),
    createVersion("0.0.251", "2024-05-15", [
        "Fixed data duplication bug for invoices.",
        "Additional invoice match form fixes.",
        "Fixed blank role name on stock PO approval history modal.",
        "Updated central requisition approval panel history to show the individual users approval actions.",
    ]),
    createVersion("0.0.250", "2024-05-13", ["Added Client Provisional Sums."]),
    createVersion("0.0.249", "2024-05-08", ["Hotfix for revised variations not loading from the approved list."]),
    createVersion("0.0.248", "2024-05-07", ["Removed PO unapproved variation validation."]),
    createVersion("0.0.247", "2024-05-03", ["Added All Projects Invoice report and CSV export.", "Updated variation data in grids to persist while being revised."]),
    createVersion("0.0.246", "2024-04-29", [
        "Fixed PDF preview watermark.",
        "Updated Amendments on the future spend and variation amendment lists to show the project impact for amendments.",
        "Fixed bug preventing purchase orders from showing on the future spend amendment list.",
    ]),
    createVersion("0.0.245", "2024-04-26", ["Hotfix for PO validation."]),
    createVersion("0.0.244", "2024-04-26", [
        "Fixed bug with PO document validation not excluding deleted documents.",
        "Updated requisition form budget forecast to include material data for the Committed Cost.",
    ]),
    createVersion("0.0.243", "2024-04-25", ["Fixed bug where invoice values weren't loading on the supplier invoice tab."]),
    createVersion("0.0.242", "2024-04-24", ['Updated "Delivery costs" material to have no supplier.', "Updated requisition attachment validation messages."]),
    createVersion("0.0.241", "2024-04-23", ["Added validation to stock transfers to prevent new transfers being requested for lines that have outstanding transfer approvals."]),
    createVersion("0.0.240", "2024-04-22", [
        "Added validation for the approved PO email when creating, amending and revising a requisition.",
        "Added fix for incorrect future spend value on the commercial view.",
    ]),
    createVersion("0.0.239", "2024-04-18", ["Updated stock transfers to handle central projects."]),
    createVersion("0.0.238", "2024-04-17", ["Added validation for the approved PO email."]),
    createVersion("0.0.237", "2024-04-12", ["Updated approved stock PO email to send to the supplier."]),
    createVersion("0.0.236", "2024-04-11", [
        "PDF improvements.",
        "Added Delivery costs field to stock requisitions.",
        "Fixed bug when revising stock requisitions.",
        "Fixed invoice approval emails not sending.",
    ]),
    createVersion("0.0.235", "2024-04-10", ["Updated invoices to handle stock project and requisitions.", "Updated approval history modal dialog for central requisitions."]),
    createVersion("0.0.234", "2024-04-09", ["Updated approval logic for central requisitions.", "Fixed PO revision validation with groups with negative totals."]),
    createVersion("0.0.233", "2024-04-08", [
        "Added additional information to the stock history view.",
        "Hid central PO future spend from form.",
        "Fixed PO revision validation against invoices with negative allocations.",
    ]),
    createVersion("0.0.232", "2024-04-05", [
        "Fixed line total calculation bug on the variations list.",
        "Fixed line total and variance precision bug on the variations list.",
        "Fixed variation calculation issues on the IE grids.",
        "Added suggested future spend to stock transfers.",
    ]),
    createVersion("0.0.231", "2024-04-02", ['Added CSV export for All Projects "All" report.', 'Added CSV export for Project "All" report.']),
    createVersion("0.0.230", "2024-03-28", ["Added CSV export to the IE tab.", 'Added CSV export for IE "All" report.']),
    createVersion("0.0.229", "2024-03-27", [
        "Updated approved variation email to zip attachments.",
        "Updated approved variation email to include associated design information as attachments.",
    ]),
    createVersion("0.0.228", "2024-03-27", [
        'Added All Projects "CVR" report.',
        'Added All Projects "Spend" report.',
        'Added CSV exports for All Projects "CVR" and "Spend" reports.',
    ]),
    createVersion("0.0.227", "2024-03-22", [
        "Fixed error on the supplier details page.",
        "Added CSV export to Project CVR and Spend reports.",
        "Fixed click through bug on the approved variations list.",
    ]),
    createVersion("0.0.226", "2024-03-21", ["Updated users so they can be assigned to multiple projects."]),
    createVersion("0.0.225", "2024-03-21", [
        "Fixed bug on RFI form where the list wouldn't updated until the user refreshed.",
        "Fixed bug on the variations grid where the committed cost was sometimes being calculated incorrectly.",
        "Fixed RFI response status not loading in the dropdown.",
        "Fixed bug on the variations grid where income was sometimes calculated incorrectly.",
        "Fixed styling issues on the variation form.",
        "Fixed calculation bugs on the approved variations list.",
    ]),
    createVersion("0.0.224", "2024-03-19", [
        "Fixed total late count on the RFI section.",
        "Fixed the order of responses in the RFI section.",
        'Updated the RFI latest response so that it shows the "From" text from the response that was created most recently.',
        "Fixed bug causing the RDD item count to include deleted items.",
    ]),
    createVersion("0.0.223", "2024-03-19", ["Fixed bugs with variations on the IE reports."]),
    createVersion("0.0.222", "2024-03-19", ["Added variation data to the IE reports and CSV exports."]),
    createVersion("0.0.221", "2024-03-18", ["Disabled approval notifications."]),
    createVersion("0.0.220", "2024-03-15", ["Added CSV export for IE CVR and Spend reports.", "Fixed IE report CSV file bugs."]),
    createVersion("0.0.219", "2024-03-14", ["Added Project CVR report.", "Added Project Spend report."]),
    createVersion("0.0.218", "2024-03-12", ["Added IE Spend report.", "Fixed bug with IE reports where zero items would cause errors."]),
    createVersion("0.0.217", "2024-03-11", ["Fixed divide by zero error on the IE CVR report."]),
    createVersion("0.0.216", "2024-03-08", [
        "Fixed fullscreen feature not working for embedded Vimeo videos.",
        "Added reports section and moved existing reports.",
        "Added IE CVR report.",
    ]),
    createVersion("0.0.215", "2024-03-06", ["Minor tweaks.", "Fixed bug preventing the questions from loading in the induction process.", "Updated induction videos."]),
    createVersion("0.0.214", "2024-03-05", ["Added pagination to the variation lists to resolve the list truncation issue."]),
    createVersion("0.0.213", "2024-03-04", ["Fixed bug where users could not save requisitions with variation items.", "Added projects report."]),
    createVersion("0.0.212", "2024-02-29", ["Fixed refresh bug for stock."]),
    createVersion("0.0.211", "2024-02-29", ["Hotfix for stock permissions."]),
    createVersion("0.0.210", "2024-02-29", [
        "Stock hidden for users assigned to a project.",
        "Filtered supplier POs when assigned to a project.",
        "Filtered supplier invoices when assigned to a project.",
    ]),
    createVersion("0.0.209", "2024-02-28", [
        "Updated stock adjustments to allow the user to surpass the stock value.",
        "Fixed bug preventing variations from showing in the approvals list.",
        "Fixed bug causing variations items to be excluded from the IE grids.",
    ]),
    createVersion("0.0.208", "2024-02-23", ["Added request for information."]),
    createVersion("0.0.207", "2024-02-22", [
        "Added override for central invoices.",
        "Fixed bugs for stock requisitions being able to be viewed by all users.",
        "Added functionality to assign a user to a project.",
    ]),
    createVersion("0.0.206", "2024-02-19", ["Fixed issue where the date was always incorrect on the dilapidation form."]),
    createVersion("0.0.205", "2024-02-19", ['Added "RAMS form" column to the site access lists.']),
    createVersion("0.0.204", "2024-02-15", [
        "Updated stock and added materials.",
        "Added stock and material approvals.",
        "Added stock and material approval validation.",
        "Updated approval process for stock requisitions.",
        "Updated to handle 4 decimal places.",
    ]),
    createVersion("0.0.203", "2024-02-08", [
        "Added IE grid report.",
        "Updated PO terms and condition document email attachments to depend on the form selection.",
        "Added scaffold to the site tablet menu.",
        "Added Time column to the sign in/sign out CSV.",
        'Added "Go to menu" button to the induction signed in page.',
        "Updated Darwin logo on the induction pages.",
        "Added site access log to inductions page.",
        "Fixed bug on approved variation list where deleted data was affecting values.",
    ]),
    createVersion("0.0.202", "2024-02-02", ["General fixes."]),
    createVersion("0.0.201", "2024-01-31", [
        "Added IE commercial report.",
        "Updated IE commercial report filters.",
        "Fixed bug preventing requisition from saving when there is a group with a negative value.",
    ]),
    createVersion("0.0.200", "2024-01-30", ["Fixed bug causing empty categories and subcategories to show on the variation grid."]),
    createVersion("0.0.199", "2024-01-30", [
        "Fixed tablet user errors.",
        "Updated inspections to show the project data.",
        "Fixed bug causing outdated variation data to load in the variations grid.",
    ]),
    createVersion("0.0.198", "2024-01-29", [
        "Fixed requisition spend limit calculation bug with central approvals.",
        "Fixed central approval bug for level 5 users that aren't the budget holder.",
        "Fixed central approval bug preventing budget holder user from approving for a requisition not within the spend limit.",
    ]),
    createVersion("0.0.197", "2024-01-25", [
        "Updated central requisition approval process to use the net gain value when revising instead of the full value.",
        "Fixed bugs with central requisition approvals.",
    ]),
    createVersion("0.0.196", "2024-01-23", [
        "Added watermark to PO preview PDF.",
        "Fixed date filtering on the IE commercial grid.",
        'Fixed bug with "Clear" button on the datepicker calendar.',
        "Fixed order line bugs on the PO form when creating from a central line.",
    ]),
    createVersion("0.0.195", "2024-01-22", [
        "Fixed initial bugs for disputing invoices.",
        "Fixed bug preventing uploading excel files to the invoice form.",
        "Fixed PO approval bug causing approvals to require the wrong users to approve.",
        "Updated disputing an invoice to send to different users for amends depending on status code.",
        "Added dialog to update the invoice status code while an invoice is disputed.",
        "Fixed UTC date bug on the PO PDF.",
        "Fixed delivery addresses bug where addresses from different projects would show when switching projects.",
        "Updated approval process for central requisitions.",
        "Limited central requisitions to only one group.",
        "Fixed bug causing incorrect categories showing in the category dropdown when switching between project and central type.",
    ]),
    createVersion("0.0.194", "2024-01-11", [
        "Added required approval notifications for requisitions.",
        "Filtered out any invoice allocations from the already allocated modal where their allocated value is 0.",
        "Updated Overall I and E summary to exclude variations.",
        "Fixed the search bar on the IE requisitions tab.",
        "Added new 60 day payment terms option. Updated invoice date field to match default value.",
        "Updated invoices so only finance users can post.",
        "Updated invoice internal disputed status resets.",
        "Updated level 3 approvals so that a level 4 commercial user can override a level 3 user.",
        "Updated central requisitions to hide the pre and post summary boxes.",
        "Fixed bug with approval panel group links between view and edit mode.",
    ]),
    createVersion("0.0.193", "2024-01-08", ["Updated PO approval for level 3 user overriding only level 2 commercial user."]),
    createVersion("0.0.192", "2024-01-05", [
        "Updated styling for the PO PDF to improve text wrapping for section two.",
        "Updated construction edit for manager and cell head roles.",
        "Fixed styling bug with date picker calendar icon.",
        "Budget holders can no longer auto approve central requisition requests.",
    ]),
    createVersion("0.0.191", "2023-12-13", [
        "Added approval documents for purchase orders.",
        "Added already allocated modal to the invoice match form.",
        'Updated PO PDF "To date" to show "From date" or "Date required" if it is missing.',
        "Added summary pre/post box to PO form.",
        'Fixed bug preventing "Revise" button not showing on PO form when navigating from the IE grid.',
        'Updated revising a PO to allow editing of hire order "Date from" and "Date to".',
    ]),
    createVersion("0.0.190", "2023-12-09", ["Fixed bug when saving central requisitions as a finance role user."]),
    createVersion("0.0.189", "2023-12-08", ["Fixed bugs with approval process for central requisition requests."]),
    createVersion("0.0.188", "2023-12-07", [
        "Fixed datepicker bug causing incorrect format to be used when typing the date.",
        "Excluded 0.00 from validation on the invoice match form.",
    ]),
    createVersion("0.0.187", "2023-12-06", ["Fixed invoice allocation issues for negative values.", "Additional invoice calculation fixes."]),
    createVersion("0.0.186", "2023-12-05", ["Fixed date issues in the induction process.", "Updated induction photo evidence uploader controls to only accept images."]),
    createVersion("0.0.185", "2023-12-04", [
        "Updated N/A options for step 2 and step 3 of inductions.",
        "Finance and level 5 users can edit supplier reference field.",
        "Updated VariationNumber to count per IE instead of per project.",
        "Updated invoice due date to set to the end of the following month if a supplier with end of month is selected.",
    ]),
    createVersion("0.0.184", "2023-12-01", [
        "Fixed datepicker bugs.",
        "Fixed bug causing deleted future spend data to show in the budget forecast on the requisition form.",
        'Added "Available balance" to the purchase order lists.',
    ]),
    createVersion("0.0.183", "2023-11-30", [
        "Fixed datepicker bugs.",
        'Updated induction questions so that only CSCS No. can select "N/A".',
        "Invoice due date now automatically changes based on the supplier payment terms if end of month is selected.",
    ]),
    createVersion("0.0.182", "2023-11-29", [
        "Updated date picker form input.",
        "Fixed bug preventing editing the project and IE dropdowns when creating a new requisition.",
        "Updated stock list to use new datepicker.",
        "Fixed induction picture stage.",
        "Split project types and order types.",
        'Added "N/A" option to induction qualifications.',
    ]),
    createVersion("0.0.181", "2023-11-24", [
        "Updated variation email CC so that it sends to head of project users.",
        "Updated PO PDF attachments to be compressed into a zip file.",
        "Updated induction sign in process so that only approved and verified induction users have access.",
        "Site manager user can now see project site access and inductions list.",
    ]),
    createVersion("0.0.180", "2023-11-23", [
        "Updated project and IE to be editable when amending new requisitions.",
        "Updated item descriptions to be editable when amending requisitions.",
        "Added PDF list for PO revisions.",
        "Added invoice number to the approvals lists.",
        "Invoice due date now automatically changes based on the supplier payment term days.",
        "Added delivery instructions to PO PDF.",
    ]),
    createVersion("0.0.179", "2023-11-21", [
        "Updated approval process for level 3 users.",
        "Added item description to the search bar for approvals.",
        "Updated IE grid to keep items expanded when navigating within an IE.",
        "Updated Variation grid to keep items expanded when navigating within an IE.",
    ]),
    createVersion("0.0.178", "2023-11-17", [
        "Text change on inductions.",
        "Added potential fix for missing image on site access PDF.",
        "Updated induction sign in process to use vimeo videos.",
    ]),
    createVersion("0.0.177", "2023-11-17", ["Added videos for visitor and full induction."]),
    createVersion("0.0.176", "2023-11-16", [
        "Updated requisition matrix calculation to use the current total expected spend instead of the estimate cost.",
        "Updated requisition matrix calculation to use difference between the current changes and the last approved value per group.",
        "Updated requisition matrix calculation to use the current committed cost from the line.",
        "Fixed matrix bug where it wouldn't get the latest approved audit data.",
        "Fixed suggested new future spend calculation bug causing rounding errors.",
    ]),
    createVersion("0.0.175", "2023-11-15", ["Added Total to the main IE grid."]),
    createVersion("0.0.174", "2023-11-14", [
        "Added approved status column to the rams list.",
        'Added "Cancelled by" column to the permits list.',
        "Updated authorization user list on the end of day audit form to be a list of users instead of induction users.",
        "Updated non-conformance authorisations text based on card type.",
        "Updated inspection user dropdown to contain users and induction users.",
        "Fixed bug on the variation and approved variation lists where variations with no items wouldn't show.",
        "Updated variation list Line total column to include the overhead and design percentages.",
        "Updated To and CC addresses for approved stock purchase order emails.",
    ]),
    createVersion("0.0.173", "2023-11-07", [
        "Added unreject functionality to purchase orders.",
        "Fixed bug preventing some invoices from loading.",
        "Fixed bug causing invoices with a missing due date showing an invalid date.",
        "Fixed validation error with the Employers Agent Recipient field on the variation form.",
        "Updated sub-contractor supervisor field on the rams form to be free text.",
        "Updated RAMS form to be editable.",
    ]),
    createVersion("0.0.172", "2023-11-02", [
        `Updated the "Changes required" button to send for amends.`,
        "Updated formatting for future spend notes, risk opp notes and requisition notes.",
        "Added button to the PO form to send an email to the supplier.",
    ]),
    createVersion("0.0.171", "2023-11-02", ["Updated IEs to handle multiple approvers per role."]),
    createVersion("0.0.170", "2023-11-01", ["Fixed bug causing incorrect future spend amendment being used for budget forecast when adding a new item/group."]),
    createVersion("0.0.169", "2023-10-31", [
        "Added due date field to invoice form.",
        "Added clear button to supplier list for filters.",
        "Fixed future spend value on the budget forecast when revising a PO.",
        "Updated invoice filters to persist when navigating away.",
    ]),
    createVersion("0.0.168", "2023-10-26", [
        "Moved requisition internal notes section to the approval panel.",
        "Updated top level purchase order and requisition list filters to persist during session.",
    ]),
    createVersion("0.0.167", "2023-10-25", ["Moved the Revise button to the left so it is visible when the approval panel is showing."]),
    createVersion("0.0.166", "2023-10-24", ["Updated approval panel for purchase orders to show even when navigating from a non-approval list."]),
    createVersion("0.0.165", "2023-10-23", [
        "Updated induction step 5 to hide options for visitor induction.",
        "Fixed approval counter showing incorrect value.",
        "Fixed sorting for insurance expiry date column on the supplier list.",
        "Fixed sorting for requested date on the approvals lists.",
    ]),
    createVersion("0.0.164", "2023-10-20", ["Updated label for CSCS No. on inductions step 2 part 2.", "Copy change for RAMS on step 5 of induction process."]),
    createVersion("0.0.163", "2023-10-20", [
        "Changed Visitor type to Inductee type on induction landing and relevant lists.",
        "Updated induction age 18 confirmation questions to not show if you are 18 or over.",
        "Removed Yes/No from induction age adjustments question.",
        "Copy change on induction landing page to specify minutes.",
        "Added custom text box for priority employer name on the induction landing form.",
        "Updated approval delegate user dropdown to match against role name for level 1 users.",
    ]),
    createVersion("0.0.162", "2023-10-19", ["Updated approval history to show the name of the delegate user where necessary."]),
    createVersion("0.0.161", "2023-10-18", [
        "Added invoices total value to the project IE grid.",
        "Fixed bug preventing delegated users from amending variations.",
        "Updated variations to handle delegate approvers.",
        "Fixed bug causing incorrect status to be applied to a variation after submitting an amendment.",
    ]),
    createVersion("0.0.160", "2023-10-16", ["Fixed bug occasionally preventing purchase order approvals from approving."]),
    createVersion("0.0.159", "2023-10-13", ["Fixed loading spinner on the approvals lists.", "Fixed over allocation issue for invoices.", "Fixed approval delegation modal typo."]),
    createVersion("0.0.158", "2023-10-13", ["Updated roles that can edit risk opp amendments."]),
    createVersion("0.0.157", "2023-10-12", ["Added approval delegations for purchase orders and invoices."]),
    createVersion("0.0.156", "2023-10-09", ["Fixed bug causing error when using PO search bar."]),
    createVersion("0.0.155", "2023-10-06", [
        "Added revision letter to the PO approved email.",
        "Added project and supplier filters to the invoice lists.",
        "Improved search for invoice lists.",
        "Fixed bug preventing internal and stock purchase orders from showing in the IE purchase order and requisition tabs.",
    ]),
    createVersion("0.0.154", "2023-10-04", ["Fixed overlapping names for the IE grids."]),
    createVersion("0.0.153", "2023-10-02", ["Fixed bug causing the new future spend value to change when viewing an existing requisition."]),
    createVersion("0.0.152", "2023-09-28", ["Added Cell 4 and 5 for IEs and projects."]),
    createVersion("0.0.151", "2023-09-27", ["Fixed future spend budget forecast bug."]),
    createVersion("0.0.150", "2023-09-26", ["Updated project construction delivery addresses to be editable."]),
    createVersion("0.0.149", "2023-09-25", ["Added RA/MS review question and attachment download to induction signin page."]),
    createVersion("0.0.148", "2023-09-21", [
        "Updated PO rejections so that POs are reverted back to their previously approved state.",
        "Added audits for PO documents.",
        "Fixed bugs with the PO rejection process.",
        "Updated IE and variation risk opp forms to include the created name and created date.",
        "Fixed bug on risk opp forms preventing the user from consecutively adding items.",
        "Added status filter to the supplier list.",
        "Updated the IE grid header to stick to the top of the page when scrolling.",
        'Updated "Approval" button to be "Approval history".',
    ]),
    createVersion("0.0.147", "2023-09-19", [
        "Removed default variation items for new variations.",
        "Fixed bug preventing negative values from being added to various forms.",
        'Added "Changes required" button to the variation form to send the variation back through the approval process before client approval.',
        "Updated disputed invoices so that finance users can make changes.",
        "Added PO approval history.",
        "Updated IE commercial view to hide approved and pending approval invoices.",
    ]),
    createVersion("0.0.146", "2023-09-13", [
        "Updated PO unapproved validation message to include list of unapproved purchase orders.",
        "Updated the variation PDF to hide items with a value of zero, that haven't been reset to zero in the most resent revision.",
    ]),
    createVersion("0.0.145", "2023-09-12", [
        "Updated variation PDF to be landscape.",
        "Updated future spend amendment and riskopp amendment permissions so that any role can view, but only the restricted roles can add new amendments.",
        "Updated project invoice list to show all invoices for a project.",
        "Updated additional email field on the requisition form to be editable when amending and revising.",
    ]),
    createVersion("0.0.144", "2023-09-08", [
        "Added committed cost column to the variation form.",
        "Updated styling for revision highlights on the variation PDF.",
        "Updated delete option for variation items to better handle revisions and approvals.",
        'Fixed "attch" typo to "attach" on document uploader on the requisition form.',
        "Fixed styling for long descriptions on the variation grid.",
        "Added PO number with revision to the purchase order form.",
        "Added projects filter to the top level purchase orders list.",
        "Fixed Raised by dropdown on the top level requisitions list.",
        "Updated the datepicker calender to show from Monday to Monday instead of Sunday to Sunday.",
        "Fixed UTC issue with client response by date on the variation list.",
    ]),
    createVersion("0.0.143", "2023-09-05", ["Updated variation revision amendments to be sent to the user who revised it, rather then the user who created it."]),
    createVersion("0.0.142", "2023-09-05", ["Fixed Target Cost not loading for variations on the requisition budget forecast."]),
    createVersion("0.0.141", "2023-09-04", [
        "Updated variation PDF to handle revision changes.",
        "Fixed bug with variation audit history.",
        "Updated requisition and variation Number generation to exclude deleted items.",
        "Added fix to prevent duplicate email addresses between email lists.",
    ]),
    createVersion("0.0.140", "2023-08-31", [
        "Added revision process for variations.",
        "Fixed save buttons not showing for new variations.",
        "Fixed bug preventing new variation form from saving.",
    ]),
    createVersion("0.0.139", "2023-08-30", ["Fixed budget forecast bugs for viewing PO revisions in the approval process."]),
    createVersion("0.0.138", "2023-08-29", ["Fixed budget forecast issues for variation items."]),
    createVersion("0.0.137", "2023-08-17", [
        "Added troubleshooting steps for outbound emails.",
        "Fixed budget forecast calculation bug for revisions.",
        "Fixed budget forecast variance negative zero bug.",
    ]),
    createVersion("0.0.136", "2023-08-16", ["Fixed bug allowing deleted users to log in.", "Fixed bug preventing approved variation emails from sending."]),
    createVersion("0.0.135", "2023-08-15", [
        "Fixed bug on the requisition budget forecast when viewing a revised requisition in the approval process.",
        "Removed excessive validation from the variation match form.",
        "Fixed label issues on the future spend amendment and risk opp amendment forms for variations and IEs.",
        "Fixed UTC date bugs on the future spend amendment and risk opp amendment forms for variations and IEs.",
    ]),
    createVersion("0.0.134", "2023-08-14", [
        "Added additional filtering to the invoice match form to filter out deleted items.",
        "Added additional invoice match form validation to prevent over allocating.",
    ]),
    createVersion("0.0.133", "2023-08-10", ["Fixed error preventing the approved variations list from loading.", "Updated IE grids to better handle PO audit data."]),
    createVersion("0.0.132", "2023-08-08", [
        "Fixed -£0.00 bug on the invoice match form, an on other forms.",
        "Added filter for raise by username to the purchase order list.",
        "Fixed supplier date formatting.",
        "Fixed UTC date issues on the variation form.",
        "Updated variation form inputs to only be enabled when the form is editable.",
        "Added delete functionality to variation items.",
        "Fixed bug on top level requisitions list preventing requisitions without suppliers from loading.",
    ]),
    createVersion("0.0.131", "2023-08-03", ["Added variation future spend amendments.", "Added validation to money inputs preventing numbers with more that two decimal places."]),
    createVersion("0.0.130", "2023-08-03", ["Added programme updates tab and sub forms."]),
    createVersion("0.0.129", "2023-08-02", ["Updated the PO PDF to filter out deleted documents."]),
    createVersion("0.0.128", "2023-08-02", [
        "Updated IE grids to handle variation risk opp amendments.",
        "Fixed Filtering on the main IE grid for some items.",
        "Fixed main IE grid not using sub IE risk opp values.",
        "Added support for .zip file type.",
        "Updated file uploader to prevent users from uploading file types that aren't supported.",
        "Added support for .msg file type.",
        "Added BCC email address to approved PO emails.",
    ]),
    createVersion("0.0.127", "2023-07-27", [
        "Updated the main IE view calculations to include the variation sub totals.",
        "Updated the variance calculation throughout the system. Also reversed the colours in the grids and added yellow for 0.",
        "Updated requisition lines to be ordered per group on the requisition form, PDF and approved email.",
    ]),
    createVersion("0.0.126", "2023-07-27", [
        "Fixed bug causing non assigned administration users to be able to view level 6 approvals.",
        "Fixed bug preventing level 5 or 6 users from being able to view level 6 approvals.",
    ]),
    createVersion("0.0.125", "2023-07-26", [
        "Additional future spend and committed cost fixes on the variation grid and approved variation list.",
        "Fixed calculation bugs on the variation list.",
    ]),
    createVersion("0.0.124", "2023-07-25", [
        "Updated variation variance to be the difference between target cost and total expected spend for the variation grid, list and approved list.",
        "Fixed bugs with the variation committed cost and future spend values on the variation grid and approved variation list.",
        'Fixed bug preventing the download of files with an uppercase fle extension. E.g. "PDF" vs "pdf".',
        "Fixed bug where invoice documents could not be downloaded from the preview tab.",
    ]),
    createVersion("0.0.123", "2023-07-21", [
        "Fixed budget forecast calculation bugs when amending a requisition.",
        "Fixed future spend and committed cost calculation bugs on the IE grids where approved data from revised requisitions would be excluded.",
    ]),
    createVersion("0.0.122", "2023-07-20", [
        "Updated approved PO email to show only additions and omissions instead of all items.",
        "Fixed bug that would sometimes prevent the future spend amendment form from loading.",
        "Fixed calculation bug on the IE grid that would exclude approved data when revising and amending the only requisition against a line.",
    ]),
    createVersion("0.0.121", "2023-07-19", ["Added attachments section to the supplier form."]),
    createVersion("0.0.120", "2023-07-18", [
        "Fixed variation using central categories when clicking back button on the central page.",
        "Fixed bug causing a requisition to fail to save if the requisition was created from a variation line.",
        "Updated requisition quantity to handle decimal values.",
        "Fixed supplier status not saving for imported suppliers due to form validation error.",
    ]),
    createVersion("0.0.119", "2023-07-17", ["Fixed Invoice Date UTC bug.", "Fixed bug causing the port committed cost to be calculated incorrectly when revising."]),
    createVersion("0.0.118", "2023-07-14", [
        "Added percentage fields to the variation form.",
        "Updated invoice date so that it can be amended at any time by the finance user role.",
    ]),
    createVersion("0.0.117", "2023-07-13", [
        "Updated approvals lists to filter by the item reference number. Invoice number, PO number and variation number.",
        "Fixed multiple bugs with the project IE grid causing various calculation issues and inconsistencies between the grids.",
    ]),
    createVersion("0.0.116", "2023-07-11", [
        "Fixed bug preventing requisitions without approvals from loading.",
        "Added additional contacts field to the requisition form which will be included in CC for PO approved email.",
    ]),
    createVersion("0.0.115", "2023-07-11", ["Fixed bug preventing adding lines to a new requisition."]),
    createVersion("0.0.114", "2023-07-10", ["Added fix to prevent requisition lines from validating against themselves."]),
    createVersion("0.0.113", "2023-07-07", [
        "Fixed bug preventing all valid suppliers loading in the supplier dropdown when amending a requisition.",
        "Copy change on invoice match form.",
        "Copy change on PO approval panel.",
        "Updated disputed invoice email to send to the supplier contact with the type of invoice.",
        "Added fix to requisition form that would sometimes prevent adding variation lines.",
        "Fixed the IE invoice list showing invoices with deleted links to projects.",
        "Fixed other instances of deleted invoice projects from showing in the system.",
    ]),
    createVersion("0.0.112", "2023-07-06", [
        "Updated disabled status of requisition form inputs.",
        "Fixed bug preventing invoices from having a value of 0.",
        "Fixed bug preventing supplier data from loading on the requisition and invoice form.",
    ]),
    createVersion("0.0.111", "2023-07-05", [
        "Updated invoice email checkbox permission to include the ceo role.",
        "Updated currency formatting on the PO PDF.",
        "Updated budget forecast to handle amending a requisition.",
    ]),
    createVersion("0.0.110", "2023-07-05", [
        "Added line validation on the requisition form to check against existing unapproved requisitions. So you can't add a line to a requisition if it is awaiting approval in another requisition.",
    ]),
    createVersion("0.0.109", "2023-07-03", [
        "Fixed level 1 commercial role permissions bug on the invoice form.",
        "Updated requisition form to disable the form inputs when view only.",
        "Fixed loading bug when viewing a requisition that links to more than one future spend amendment.",
    ]),
    createVersion("0.0.108", "2023-07-03", ["Fixed bug causing groups with only deleted items to affect calculations on the grids and the future spend amendment form."]),
    createVersion("0.0.107", "2023-06-30", ["Added Level 1 commercial role.", "Fixed the Income and Variance calculations for variations on various lists."]),
    createVersion("0.0.106", "2023-06-28", [
        "Removed character limit validation for the PO description and the PO line description.",
        "Fixed bug preventing the budget forecast from loading.",
        "Added supplier name to the approvals lists.",
    ]),
    createVersion("0.0.105", "2023-06-28", [
        "Updated requisition form to handle multiple variations, and handle IE items and variation items together.",
        "Fixed order of the IE grids to be alphabetical.",
        "Fixed issue where the Central navigation link would occasionally not show.",
        "Fixed 'New project' bug for finance role.",
        "Fixed project audit error.",
        "Fixed project general and construction form not saving for finance role.",
        "Updated requisition form budget forecast to include the future spend amendments.",
        "Updated Risk/Opp colours.",
        "Fixed bug preventing level 2 approvals from showing in the required tab.",
        "Updated level 2 and 3 roles to prevent them from adding or editing suppliers.",
        "Fixed committed cost calculation bug on the requisition and purchase order lists.",
        "Filtered the category dropdown on the requisition form to only show approved variations.",
        "Fixed supplier notes not showing in the PO PDF.",
    ]),
    createVersion("0.0.104", "2023-06-21", [
        "Fixed future spend calculation issue on the budget forecast on the requisition form.",
        "Added invoice approval override user.",
        "Updated manager and cellhead role permissions for projects and I&Es.",
    ]),
    createVersion("0.0.103", "2023-06-19", ["Updated general role permissions for settings, inductions, suppliers, invoices, projects and I&Es."]),
    createVersion("0.0.102", "2023-06-16", ["Added legacy flag to requisitions for imported data.", "Fixed PO line validation against invoice allocation."]),
    createVersion("0.0.101", "2023-06-15", [
        "Line description no longer editable when revising a purchase order.",
        "Updated purchase order line validation to check against approved as well as paid invoices.",
        "Removed auto approvals for regular purchase orders.",
        "Fixed revision logic for central requisitions.",
        "Added validation to the requisition to prevent saving without adding at least one line.",
        "Updated email copy for PO approved email and invoice disputed email.",
        "Fixed 'Send to supplier' checkbox not updating when amending a PO.",
        "Updated email from display name.",
    ]),
    createVersion("0.0.100", "2023-06-14", [
        "Fixed purchase order item quantity validation bug.",
        "Updated central purchase order approvals to allow level 5 users to approve.",
        "Fixed invoice project dropdown filtering bug.",
        "Fixed requisition audit generation bug related to multiple users being involved in creating and revising.",
        "Added PDF preview to the requisition approval panel.",
        "Fixed approval bug where if you created and auto-approved a requisition, then revise it such that it would require manual approval, there was an error.",
    ]),
    createVersion("0.0.99", "2023-06-13", [
        "Fixed dates on the PO PDF.",
        "Fixed future spend calculation including new deleted items on the requisition form budget forecast.",
        "Removed delete option from existing requisition items.",
        "Added PO validation to prevent changing lines that have already been allocated and paid.",
        "Updated variation PDF and remove the '£' sign from the list items.",
        "Fixed PO revision email not sending.",
        "Added save as draft button on the invoice match form.",
    ]),
    createVersion("0.0.98", "2023-06-09", [
        "Fixed date sorting on the future spend amendment page.",
        "Fixed incorrect future spend value showing on the IE gird page. Caused by incorrect sorting.",
        "Added revision letter to the revisions on the future spend amendment page.",
        "Fixed incorrect future spend value showing on the requisition budget forecast.",
        "Fixed labour requisition being unable to save due to missing supplier.",
        "Fixed the budget forecast future spend value when revising a requisition.",
        "Removed references to project name from the PO approved email.",
    ]),
    createVersion("0.0.97", "2023-06-06", ["PO PDF audit bug fix.", "PO PDF styling updates.", "Added revision letter to the PO PDF title.", "Added supplier name to the PO PDF."]),
    createVersion("0.0.96", "2023-06-02", ["Updated PO PDF with additions and omissions."]),
    createVersion("0.0.95", "2023-06-01", [
        "Added inspection type name to the inspection form.",
        "Removed the project name from the PO PDF.",
        "PO PDF attachment list will only show if there are attachments.",
        "Removed the Is override column on the approval history modal.",
        "Text change on requisitions form.",
        "Updated the variation PDF to also hide the lists if there are no list items.",
        "Updated the requisition form delivery addresses dropdown to filter out deleted addresses.",
    ]),
    createVersion("0.0.94", "2023-05-31", [
        "Fixed supplier address on requisition form being incorrectly formatted when viewing an existing requisition request.",
        "Fixed the column sorting for the approvals lists and the invoice lists.",
        "Renamed near miss Location field to Exact location.",
        "Updated permit contractor list to no longer filter per project.",
        'Updated permit dynamic radio buttons with "N/A" option.',
        "Fixed navigation bug when submitting a permit form.",
        "Added checkbox to the permit forms. Updated hot works fire watch question to use the checkbox.",
        "Fixed permit form signatures not saving correctly.",
        "Fixed permit list UTC date issues.",
        "Added RAMS form.",
    ]),
    createVersion("0.0.93", "2023-05-26", [
        "Updated the PO description on the I&E grid to use an ellipsis.",
        "Fixed the table sorting on the project variations list.",
        "Set some supplier related fields to disabled on the requisition form.",
        'Added "Expired" supplier status.',
        "Added insurance date column to the supplier list.",
        "Invoice supplier email checkbox can now only be changed by finance and exec roles.",
    ]),
    createVersion("0.0.92", "2023-05-25", [
        "Added dilapidation form.",
        "Added project specific balance to allocate values to the invoice match form.",
        "Fixed date and value sorting on the invoice lists.",
        "Set payment terms dropdown to disabled on the requisition form.",
        "Updated DC0001, DC0010 & DC0011 invoice disputed codes with internal text.",
        "Updated supplier email checkbox position and logic on the requisition form.",
        "Updated invoice purchase order list to filter by the assigned projects.",
    ]),
    createVersion("0.0.91", "2023-05-23", [
        "Updated central requisition approval history and approval list approver name.",
        "Updated invoice list approver name.",
        "Fixed site tablet user navigation bug.",
    ]),
    createVersion("0.0.90", "2023-05-19", [
        "Restructured the project construction tabs.",
        "Updated the requisition PDF to hide the attachment and list subtitles if non are present.",
        "Updated the emails in UAT to CC in the darwin approvals email.",
        "Fixed bugs with the supplier form date pickers.",
        "Updated purchase order approval history to use the users name instead of the role level for levels 2, 3 and 4.",
    ]),
    createVersion("0.0.89", "2023-05-18", [
        "Fixed bugs on the supplier form.",
        "Added approval history to the invoice form.",
        "Added invoice project allocation check.",
        "Fixed duplicate projects bug on the invoice details form.",
        "Added terms & conditions PDF to the purchase order approved email.",
    ]),
    createVersion("0.0.88", "2023-05-15", [
        "Added future central projects.",
        "Updated variation dropdown on the requisition form to include the description.",
        "Added the project invoice value to the item list on the invoice match form.",
        "Fixed login and permission issues with the Finance role.",
    ]),
    createVersion("0.0.87", "2023-05-10", ["Copy changes on the requisition form.", "Added non conformance section.", "Added daily diary.", "Updated invoice approvals."]),
    createVersion("0.0.86", "2023-05-02", [
        "Updated variation approval email.",
        "Updated invoice approval email.",
        "Updated invoice disputed email.",
        "Updated PO approved email.",
        "Added incident accident form.",
    ]),
    createVersion("0.0.85", "2023-04-13", [
        "Fixed bug where the PO send email to supplier checkbox would always show as ticked, even if it wasn't.",
        "Updated disputed email address for some disputed status codes.",
        "Added supplier name to the approvals lists for invoices.",
        "Added permissions for access to central project and lines.",
        "Added invoice approved email.",
    ]),
    createVersion("0.0.84", "2023-04-06", ["Removed email sending when an invoice is marked as paid.", 'Changed name of order type "Labour" to "DGL Labour".']),
    createVersion("0.0.83", "2023-04-06", [
        "Added fix for -0 issue for invoice purchase orders available balance.",
        "Added project name and reference to the approval lists for invoices.",
        "Fixed bug causing requisition requests submission to fail if they would auto approve.",
    ]),
    createVersion("0.0.82", "2023-04-05", [
        "Added fix for -0 issue for invoice balance.",
        "Changed order of costs on the variation PDF.",
        "Set sent email to supplier checkbox to ticked by default.",
        "Added fix for induction site access visit date BST bug.",
    ]),
    createVersion("0.0.81", "2023-04-04", [
        "Added central purchase order approvals.",
        "Added central invoice approvals.",
        "Added send email to supplier checkbox on the requisition form.",
        "Added send email to supplier checkbox on the invoice form.",
        "Fixed bug preventing requisition from being approved",
        "Fixed sorting by supplier reference on the supplier list.",
        "Added invoice reference to the invoice disputed email.",
        "Updated the invoice attachments to open in a new tab rather than download.",
        "Fixed IE commercial view invoice list filtering. How only shows invoices with a match status and no purchase orders assigned.",
        "Updated approval lists to filter out any deleted POs, invoices and variations. Previously items could not be approved if they were deleted, but they could still be approved in the approvals lists.",
    ]),
    createVersion("0.0.80", "2023-03-28", ["Added emails for variations, invoices and purchase orders.", "Added invoice paid status change dropdown."]),
    createVersion("0.0.79", "2023-03-24", ["Added Tool box talk record."]),
    createVersion("0.0.78", "2023-03-24", ["Added central Project and I&E."]),
    createVersion("0.0.77", "2023-03-17", [
        "Fixed bug causing the invoice search term to persist between invoice lists.",
        "Added validation to prevent allocating more than is available on the invoice match page.",
        "Added delete button to the purchase orders on the invoice match form.",
    ]),
    createVersion("0.0.76", "2023-03-16", ["Added incident witness statement form."]),
    createVersion("0.0.75", "2023-03-14", [
        "Fixed bug preventing all related invoices from showing on the project commercial screen.",
        "Added incident section and near miss form.",
    ]),
    createVersion("0.0.74", "2023-03-10", [
        "Updated invoice pop-up condition to use the net value.",
        "Updated the supplier reference to no longer be editable.",
        'Added "Post and create new invoice" button to the invoice details form.',
        "Fixed bug causing incorrect project name showing per invoice in the invoice lists.",
        "Updated PO PDF name to include PO number and Revision.",
    ]),
    createVersion("0.0.73", "2023-03-03", [
        "Fixed inspection question ordering.",
        "Added fail reason textbox to inspections.",
        "Added supplier name to edit form.",
        "Updated invoice VAT to pre-populate 20% of the sub-total.",
    ]),
    createVersion("0.0.72", "2023-03-02", [
        "Added approval history for purchase orders.",
        "Moved permit sub header.",
        "Fixed bug preventing project audit form from navigating back to the project audit list after saving or canceling.",
        "Fixed bug preventing approvals from fully approving.",
        "Fixed bug preventing the end of day audit from saving and displaying correctly.",
    ]),
    createVersion("0.0.71", "2023-02-28", [
        "Added additional values to the variation PDF.",
        "Updated variation quantity to handle decimals.",
        "Added variation description to variation lists.",
        "Added invoice list to the commercial page.",
        "Updated invoice PO link to open the PO form in a modal instead of navigating away.",
        "Added required approvals tab.",
    ]),
    createVersion("0.0.70", "2023-02-23", [
        "Fixed IE grid future spend bug where it was ignoring the latest approved audit value if a requisition future spend had been revised.",
        "Fixed project invoice addresses.",
        "Fixed project delivery addresses.",
        "Fixed project description not showing formatted when viewing project.",
        "Fixed future spend bug on the requisition form caused by deleted items not being handled properly. This also fixed the sugested new future spend committed cost, and the total expected spend.",
        "Fixed requisition grid calculations when approving a revised requisition.",
        "Added project inspections.",
        "Added project permits.",
        "Added Site Manager user role.",
        "Fixed bug preventing the invoice value from loading on the IE invoice form.",
        'Fixed bug preventing the invoice number from correctly validating if certain characters such as "/" were included.',
        "Set invoice data to default to today.",
        "Extended invoice table with project name and approved date.",
        "Added invoice post/draft modal dialog",
        "Fixed invoice date validation bug.",
        "Updated variation number to increment per project.",
        "Fixed styling bugs.",
    ]),
    createVersion("0.0.69", "2023-02-07", ["Added fix to the calculations on the requisition summary grid when revising a requisition."]),
    createVersion("0.0.68", "2023-02-06", [
        "Added site video link input to the project site access page.",
        "Fixed bug where induction phone digits wouldn't include leading zeros.",
        "Fixed bug on the health and confirmation page that would prevent the user from continuing.",
        "Fixed bug preventing the sign in button from navigating away.",
    ]),
    createVersion("0.0.67", "2023-02-03", ["Added project site access CSV download."]),
    createVersion("0.0.66", "2023-02-03", [
        "Added loader spinner when navigating between the induction stages and signin/out process.",
        "Wired up search bar and show on site option to the project site access filters.",
        "Added fix to prevent one user form being inducted more than once.",
    ]),
    createVersion("0.0.65", "2023-02-01", [
        "Fixed bug preventing the requisition summary/forecast committed cost from using all items from other approved purchase orders.",
        "Added select all option for force signout functionality on the project site access list.",
    ]),
    createVersion("0.0.64", "2023-01-31", [
        "Added force sign out functionality to the project site access list.",
        "Fixed committed cost forecast/summary bug for revised purchase orders.",
    ]),
    createVersion("0.0.63", "2023-01-31", ["Fixed PO revision bug preventing the project grid from loading."]),
    createVersion("0.0.62", "2023-01-30", ["Updated project site access list with last 3 digits column."]),
    createVersion("0.0.61", "2023-01-30", ["Added inductions.", "Updated project site access filters to default to today."]),
    createVersion("0.0.60", "2023-01-24", [
        "Fixed a bug preventing the approval process from resetting when a requisition is amended.",
        "Fixed a bug causing a level 3 user to be overridden when a level 4 user approves a level 5 or 6 approval.",
    ]),
    createVersion("0.0.59", "2023-01-23", [
        "Fixed a bug causing a level 3 user to be overridden early when a level 4 user approves a level 4 approval.",
        "Fixed bug preventing the user from entering anything into the submit for approval modal dialog text area on the requisition form.",
        "Fixed bug preventing the week commencing date from loading when viewing an existing purchase order.",
    ]),
    createVersion("0.0.58", "2023-01-20", [
        "Fixed bug preventing level 6 approvals from being viewed by the correct users.",
        "Fixed bug preventing two level 5 users from approving a level 6 approval.",
    ]),
    createVersion("0.0.57", "2023-01-18", ["Updated requisition approval functionality."]),
    createVersion("0.0.56", "2022-12-14", [
        "Added fix for the variation summary total calculation.",
        "Fixed bug causing deleted requisition groups and items to be included in the committed cost calculation throughout the system.",
    ]),
    createVersion("0.0.55", "2022-12-13", [
        "Fixed bug preventing the correct future spend value from showing on the I&E grid.",
        "Fixed future spend calculation bug on the commercial IE grid.",
        "Fixed subtotals not working when viewing sub I&Es.",
        "Fixed the design percentage calculation not calculating on the variation summary calculations.",
        "Updated the SubIE grid values to show the value including children and grandchildren, rather than just the SubIE.",
        "Removed the supplier filter from the project dropdown on the invoice details form.",
        "Updated the project dropdown on the invoice details screen to filter by purchase order number.",
        "Fixed bug preventing the design variance value from being added to the total on the variation form summary.",
        "The project dropdown on the invoice details form will no longer be set to disabled when searching for or selecting projects.",
        "Fixed bug with the project dropdown search.",
        "Fixed bug causing the pending approval notification bar to always show for all invoices after viewing an invoice that is pending approval.",
        "Fixed bug for the selected projects delete functionality not working under certain conditions.",
        "Updated invoice match submission so that you can't submit for approval unless the balance allocated matched the invoice value.",
    ]),
    createVersion("0.0.54", "2022-12-06", [
        "Moved purchase order dropdown to the match tab on the invoice form.",
        "Added projects dropdown in the details tab on the invoice form.",
        "Updated invoice dispute logic to show the dispute button on the match form at all times.",
        "Updated the variation form summary calculations.",
        "Fixed Sub I&E calculations.",
        "Updated disputed status to show the disputed status code. Also added the disputed reason to the invoice form header.",
        "Fixed bug causing the IELine items to save without decimal points.",
        "Added subtotal to the I&E, sub I&E and Variation grids.",
    ]),
    createVersion("0.0.53", "2022-11-30", [
        "Added fix to prevent category and subcategory from clearing after navigating from a line",
        "Fixed bug causing pre-selected project and I&E showing when creating a new requisition from the top level list.",
        "Added fix preventing the I&E dropdown from loading any data.",
    ]),
    createVersion("0.0.52", "2022-11-28", [
        "Added variation grid to the I&E tab.",
        "Updated future spend amendment project impact calculation.",
        "Added requisition button to the variation grid line description.",
    ]),
    createVersion("0.0.51", "2022-11-23", [
        "Added headers to the variation summary section on the variation form.",
        "Updated the I&E line modal so that the risk/opportunity value is no longer editable.",
    ]),
    createVersion("0.0.50", "2022-11-21", [
        "Labour requisitions no longer require the supplier information.",
        "Updated PONumber prefixes for Stock and Labour requisitions.",
        'Added "Week commencing" date field for labour requisitions.',
        "Fixed bug causing the validation for the order type date fields to be ignored.",
        "Updated the variation line items to no longer be collapsible or grouped by category or subcategory in the UI on the variation form.",
        "Removed internal notes from the variation PDF.",
        "Updated the description on the PO items on the IE grid to use the purchase order description.",
        "Fixed bug causing purchase orders to be duplicated in the IE grid.",
    ]),
    createVersion("0.0.49", "2022-11-16", [
        'Renamed "Sales" order type to "Purchase".',
        "Fixed validation not triggering for requisition order lines.",
        "Added required validation to the future spend note field for requisition groups.",
        "Fixed bug causing deleted items and groups to be saved against a requisition.",
        "Fixed bug causing the IE grid future spend value to not include purchase orders created after a future spend amendment.",
        "Added name and created date to the future spend amendment list items.",
        "Added new field for design percentage.",
        "Moved delivery instructions text field from construction form to address form.",
        "Updated calculations and structure for variations summary on the variation form.",
        "Updated variation variance calculation and added missing variance calculation to the non-approved list.",
        "Re-ordered the variation line columns to be Quantity, then Unit, then Rate.",
        'Added "Client approval reference" field to the variation form.',
        'Added "Cost to be agreed" and "Programme impact" fields to the variation form.',
        "Updated the variation PDF with new fields. Fixed missing data.",
    ]),
    createVersion("0.0.48", "2022-10-24", [
        "Added reference field to the edit supplier form.",
        "Wired up the supplier invoices list.",
        'Renamed the "Create variation" variation button to "Approved by client" when saving the client approval for variations.',
        'Renamed the "Amend" button to "Resubmit amendment" for variations.',
        "Fixed bug with invoice document uploading that prevented the invoice posting form from saving.",
        "Fixed bug causing purchase order documents to load for every purchase order, rather than just for the one that they were added to.",
        "Added status filter to the invoice lists.",
        "Fixed bug causing the Already allocated value on the Invoice match form to be calculated incorrectly.",
        "Added PO Value and Remaining balance columns to the Invoice details purchase order table.",
        "Added approver role name to the invoice list.",
    ]),
    createVersion("0.0.47", "2022-09-30", [
        "Updated disputing invoices.",
        "Fixed bug preventing the supplier form from loading data.",
        "Updated the variation PDF and fixed bug preventing item names from loading.",
        "Fixed notes form on the project general page.",
        "Added labels to disabled fields on the invoice form.",
        "Updated the invoice purchase order dropdown to filter by the selected supplier.",
        "Added invoice approvals.",
    ]),
    createVersion("0.0.46", "2022-09-20", [
        "Changed variation unit type to be free text field.",
        "Added username to the top left of the page.",
        "Added line total to the variation new line modal dialog.",
        "Styling improvements.",
        "Variation PDF improvements.",
        "Wired up the top level add invoice button.",
        "Added client approval documents section to variations.",
        "Fixed bug preventing the default variation items from generating.",
    ]),
    createVersion("0.0.45", "2022-08-09", [
        "Added variation approvals.",
        "Extended the approvals lists with description, cost and approver columns.",
        "Extended purchase order and requisitions lists with project column.",
        "Added future spend amendment value to the IE grids future spend column.",
        "Added back button to the future spend amendment form.",
        "Fixed approved variation list calculations.",
        "Added Risk/Opportunity form and wired up the result to the I & E grid.",
        "Added variation PDF.",
        "Removed the main address tickbox on the project construction tab.",
        "Fixed bug causing duplicate IE items to be generated when rapidly clicking the add button.",
        "Removed ability to delete I&Es that belong to non draft projects.",
        "Added Notes/exclusions to Employers Agent field to the variation form.",
    ]),
    createVersion("0.0.44", "2022-08-22", [
        "Added invoice details and match form.",
        "Fixed bug causing the terms and conditions document tile to show when no document was added.",
        "Renamed variation expected cost to future spend and fix related calculations.",
    ]),
    createVersion("0.0.43", "2022-08-01", ["Fixed bug causing the post committed cost being calculated incorrectly on the requisition request form."]),
    createVersion("0.0.42", "2022-07-29", [
        "Fixed the committed cost value not being calculated correctly on the I&E purchase order list.",
        "Updated the position of the loading animation to within the table for the global purchase order and requisition lists. To prevent the filters from losing focus whenever they change.",
        "Added fix for bug that caused the approval panel to occasionally fail to show.",
        "Fixed bugs causing requisition documents to not load.",
        "Updated requisition documents to persist the name of the uploaded file, rather than generate a new name.",
        "Fixed bugs causing requisition notes to not load.",
        "Fixed validation bug preventing the project contractual form from saving.",
        "Updated variation requested by dropdown to become a free text field.",
        "Agent name and email now auto-populate on the variation form.",
    ]),
    createVersion("0.0.41", "2022-07-21", [
        "Wired up the missing columns for the requisition request and purchase order lists.",
        "Updated the formatting of the delivery address list items on the requisition request form.",
        "Fixed bug where the suggested new future spend value didn't get updated when adding a new line item to an existing group, or when deleting a line.",
        "Fixed bug when deleting all order line items from a group would still show the group.",
        "Fixed bug where the order line items and groups would not get cleared when clicking the 'Send for approval & create new PR' button.",
        "Removed close button from the approval panel and updated styling for the minimise button.",
    ]),
    createVersion("0.0.40", "2022-07-20", [
        "Updated 'Requisition note' title to 'Requisition note (internal use)'.",
        "Fixed breadcrumb highlight issue when viewing the requisitions list.",
        "Fixed formatting for committed cost value on the requisitions list and purchase order list.",
        "Updated the list of default descriptions for variations.",
        "Added additional validation to I&E line modals to prevent adding line item with a missing description.",
        "Added dashes for empty fields for the requisition and purchase order tables.",
    ]),
    createVersion("0.0.39", "2022-07-19", ["Updated the line item modal to hide the default description options."]),
    createVersion("0.0.38", "2022-07-19", [
        "Added head of projects as new pre-approver role.",
        "Fixed bug causing the payment type to fail to be automatically selected when selecting a supplier.",
        "Added 'Labour' as new order type.",
        "Fixed bug where the 'Requested on' column for the approvals tables would not sort the data as expected.",
        "Fixed formatting and rounding errors for the order line items form total.",
        "Fixed the future spend calculation on the project summary grid.",
        "Fixed bug preventing the edit supplier form from being submitted.",
        "Added supplier reference to the add supplier form modal.",
        "Fixed validation issues on the edit supplier form.",
        "Updated requisition request form supplier list to only display verified suppliers.",
        "Added Target Cost column to the project summary grid.",
        "Fixed the suggested new future spend calculation on the requisition order line form.",
    ]),
    createVersion("0.0.37", "2022-07-13", [
        "Project new address form will now clear after adding a new address.",
        "Fixed issues related to setting main address and deleting addresses for a project.",
        "The edit I&E line item button and the future spend amendment link will only show for Comm Dir and higher.",
    ]),
    createVersion("0.0.36", "2022-07-12", [
        "Enabled the order line items on the requisition request form to be editable.",
        "Fixed the order line item validation.",
        "Add new project button will only show for Comm Dir and higher.",
    ]),
    createVersion("0.0.35", "2022-07-11", ["Fixed bug causing the requisition revision form to fail to submit."]),
    createVersion("0.0.34", "2022-07-08", [
        "Fixed bug causing the requisition forecast calculations to fail to load when creating a new requisition. Specifically for the future spend value.",
        "Fixed bug where the variation selection on the requisition form wouldn't reset when changing the project or I&E.",
        "Updated category and subcategory dropdowns in the order line items section on the requisition form to exclude the variation default category and subcategory.",
    ]),
    createVersion("0.0.33", "2022-07-07", ["Updated project I&E list to handle sub I&Es of sub I&Es.", "Updated the Sub I & E list on the I&E page to exclude the current I&E."]),
    createVersion("0.0.32", "2022-07-06", [
        "Enabled the supplier section to be viewed by users in any role.",
        "Fixed bug that would prevent users from saving the requisition request form.",
        "Fixed bug that would cause duplicate projects to be created.",
    ]),
    createVersion("0.0.31", "2022-06-30", [
        "Set Central navigation link to hidden.",
        "Added supplier contact type.",
        "Updated styling for contact details section.",
        "General variation section fixes and improvements.",
        "Added invoice tables and details form. (WORK IN PROGRESS)",
    ]),
    createVersion("0.0.30", "2022-06-23", [
        "Updated styling and general improvements for variation tab and form.",
        "Fixed bug where variations with duplicate variation numbers could be added.",
        "Fixed styling issue with the add project button.",
        "Updated styling for navigation menu.",
        "Updated styling for project pages.",
    ]),
    createVersion("0.0.29", "2022-06-22", [
        "Fixed bug where non-approved variations would show for all IEs, instead of only for their parent IE.",
        "Added fix to prevent changing the status of a variation from pending approval to draft for a variation.",
        "Updated variation dropdown on requisition form to only show approved variations.",
        "Added client notes and documents section to the variation form. (WORK IN PROGRESS)",
        "Fixed bug with how variation documents are deleted.",
        "Fixed bug where the save as draft button would be prematurely disabled on the variation form.",
        "Various styling improvements for variations",
    ]),
    createVersion("0.0.28", "2022-06-16", [
        "Added variations tab and grids.",
        "Added variation form.",
        "Updated requisitions form so you can link a variation to a requisition.",
        "Fixed validation bug for the 'Is stock requisition' field on the requisition form.",
        "Fixed various bugs with the requisition form budget costs not being calculated correctly.",
        "Wired up the variation data to the budget cost calculations on the requisition form.",
    ]),
    createVersion("0.0.27", "2022-05-09", ["Added filters to requisitions list.", "Updated menu styling.", "Added click through from Stock page to view a Purchase Order."]),
    createVersion("0.0.26", "2022-04-29", [
        "Added PDF generation for the I&E purchase orders list.",
        "Updated stock page value calculations and filters.",
        "Approving a purchase order will now update the stock page.",
        "Added stock requisitions list to the stock page.",
        "Added sub menu item for requisitions.",
        "Added delete a line functionality.",
        "PDF generation tweaks.",
    ]),
    createVersion("0.0.25", "2022-04-25", [
        "Added purchase order revision functionality.",
        "Wired up the PO data on the supplier purchase order list.",
        "Allowed the project status to be changed from draft.",
        "Updated styling on the view information page.",
        "Updated the filters on the Purchase Orders list.",
        "Fixed approvals matrix calculations.",
        "Requisitions within spend allowance no longer need approval.",
        "Fixed grid styling misalignment issues.",
        "Added control to change supplier status.",
    ]),
    createVersion("0.0.24", "2022-04-05", [
        "Added End Date filter to the project commercial grid.",
        "Added document upload on terms & conditions.",
        "Updated client field to be free text rather than dropdown.",
        "Fixed bug where clicking 'Send for approval and create new PR' would not clear all of the data from the form.",
        "Fixed Add/Edit line description filtering and styling.",
    ]),
    createVersion("0.0.23", "2022-03-31", [
        "Added 'Payment terms (days)' field to the requisition request form.",
        "Added view-only mode to the requisition/po form.",
        "Added collapsible element to side panel.",
        "Updated the calculations on the project commercial grid to have the master IE totals include the sum of their sub IEs.",
        "Fixed bug where the supplier address would not show on the supplier form.",
        "Removed Completed Projects navigation tab.",
    ]),
    createVersion("0.0.22", "2022-03-28", ["Updated category and sub-category lists."]),
    createVersion("0.0.21", "2022-03-28", ["Fixed loading bug on the view project page."]),
    createVersion("0.0.20", "2022-03-25", [
        "Wired up the order value field on the future spend amendment page.",
        "Fixed bug where the project impact was being calculated incorrectly for the I & E item.",
        "Fixed bug where the project contractual page would not load any data.",
    ]),
    createVersion("0.0.19", "2022-03-24", [
        "Updated the future spend amendment list to only show data approved audit data for a purchase order.",
        "Fixed bug on the future spend amendment page where the audit data being returned was reliant on the current request being a PO and being approved.",
        "Updated the future spend amendment UI to remove N/A data from certain list items.",
    ]),
    createVersion("0.0.18", "2022-03-24", [
        "Added future spends amendment page (WORK IN PROGRESS).",
        "Added new supplier fields.",
        "Updated supplier UI.",
        "Fixed error with the project contractual form date pickers.",
    ]),
    createVersion("0.0.17", "2022-03-16", ["Updated approvals 'Pending amends' tab name to 'Pending approval'."]),
    createVersion("0.0.16", "2022-03-15", [
        "Added pre-approval process for administration roles.",
        "Fixed bug with the order line item dropdowns not loading the correct data when viewing an existing requisition.",
        "Added budget costings and other requisitions items to a requisition/purchase orders requisition groups.",
    ]),
    createVersion("0.0.15", "2022-03-11", [
        "Added administration roles section to the add/edit I&E form.",
        "Added notes section for requisition form.",
        "Moved the I&E form to its own page.",
        "Creating a requisition request from within an I & E, dependant data will be pre-populated.",
        "Added contact details to the delivery address.",
        "Sidebar fixes for Admin and Approvals.",
        "Bug fix for Margin calculation.",
        "Added budget costings and other requisitions items to a requisition/purchase orders requisition groups. (WORK IN PROGRESS).",
    ]),
    createVersion("0.0.14", "2022-03-07", [
        "Fixed category and subcategory margin calculation for the I&E grid.",
        "Fixed bug where the future spend value would not be loaded under some circumstances for the I&E grid.",
    ]),
    createVersion("0.0.12", "2022-03-07", [
        "Added auto-incrementing purchase order number.",
        "Updated lists to display the purchase order number where necessary.",
        "Fixed values and calculations for the I&E grid.",
        "Updated future spend to be editable when adding a line item.",
        "Styling tweaks for the I&E grid.",
        "Wired up the starting future spend value from the I&E line item.",
    ]),
    createVersion("0.0.11", "2022-03-02", ["Updated requisition request form to handle the free text line description."]),
    createVersion("0.0.10", "2022-03-01", [
        "Purchase order list now loads data.",
        "Fixed the purchase order list filters.",
        "Added notification bar for requisition requests that are pending approval.",
        "Added free text option for line description.",
        "Wired up the audit data to the I&E tab.",
        "Improved styling for the I&E tab.",
    ]),
    createVersion("0.0.9", "2022-02-23", [
        "Wired up the approval panel buttons. Can now approve, require amend or reject a requisition.",
        'Fixed data loading bugs on the "Pending amends" approvals list.',
        "Added row click to the approvals list.",
        "Updated the approval panel to only show when it's supposed to.",
        "Started wiring up some more data to the approval panel.",
        "Approval panel styling updates.",
        "Existing requisitions are now viewable and amendable.",
        "Fixed the validation when amending a requisition request.",
        "Fixed bug where a requisition request wouldn't be sent for approval if the total value was too large.",
        "Add list of groups that exceed the spend allowance to the approval panel.",
    ]),
    createVersion("0.0.8", "2022-02-14", [
        "Fixed the permissions so that any user can view the approvals page.",
        "Fixed bug where the an approval item would show in multiple tables at the same time.",
        "Fixed bug where the my amends table wouldn't re-render when loading the data.",
        "Fixed login button from vanishing on hover.",
        "Fixed issues with approvals matrix.",
    ]),
    //createVersion("0.0.7", "2022-02-09", []),
    createVersion("0.0.5", "2022-02-03", ["Future spend and committed costs added to I&E view."]),
    createVersion("0.0.4", "2022-01-28", ["Requisition form and Requisition line added to I&E view."]),
    createVersion("0.0.3", "2022-01-11", ["Variation and Subs added to I&E view."]),
    createVersion("0.0.2", "2022-01-06", ["Updates to I&E section."]),
    createVersion("0.0.1", "2021-12-07", ["Initial UAT Release."]),
];

const currentVersion = versions
    .filter((version) => version.items && version.items.length > 0)
    .reduce((previous, current) => {
        return Utils.versionNumbers(previous.version, current.version) > 0 ? previous : current;
    }).version;

export const ChangeLog: { currentVersion: string; versions: Models.ChangeLogVersion[] } = {
    currentVersion,
    versions,
};
