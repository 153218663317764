import { Box, Typography } from "@material-ui/core";
import { Cell, Grid, isEmptyOrWhitespace, useRouter } from "@shoothill/core";
import { observer, useObserver } from "mobx-react-lite";
import { runInAction } from "mobx";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { DefaultButton, PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinInput } from "Globals/Styles/Control/DarwinInput";
import { PurchaseOrderViewModel } from "./PurchaseOrderViewModel";
import { DashedDivider, SolidDivider } from "./Views/Dividers";
import { FormHeader } from "./Views/FormHeaders";
import { Form, FormSection } from "./Views/Forms";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { PurchaseOrderApprovalPanel } from "./PurchaseOrderApprovalPanel";
import { SendForApprovalModal } from "./SendForApprovalModal";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import IconButton from "@material-ui/core/IconButton";
import SystemUpdateTwoToneIcon from "@material-ui/icons/SystemUpdateTwoTone";
import { ProjectModel } from "./Supporting/ProjectModel";
import { IncomeAndExpenditureModel } from "./Supporting/IncomeAndExpenditureModel";
import { AppUrls } from "AppUrls";
import { useLocation } from "react-router-dom";
import { Uploader } from "Components/Uploader/Uploader";
import { RequisitionRequestItemGroupViewModel } from "./Supporting/OrderLine/Tables/RequisitionRequestItemGroupViewModel";
import { IDisplayFile, PurchaseOrderModel } from "./PurchaseOrderModel";
import { IEGridItemViewModel } from "Views/Project/Commercial/IEmodels/IEGridItemViewModel";
import { ApprovalHistoryModal } from "./Supporting/ApprovalHistoryModal/ApprovalHistoryModal";
import { LineValidationModal } from "./Supporting/LineValidationModal/LineValidationModal";
import { UnapprovedPurchaseOrderLineValidationModal } from "./Supporting/UnapprovedPurchaseOrderLineValidationModal/UnapprovedPurchaseOrderLineValidationModal";
import { DeleteModal } from "Components/Modal/DeleteModal";
import { NonStockSectionView } from "./Views/FormSections/NonStockSectionView";
import { StockSectionView } from "./Views/FormSections/StockSectionView";
import { SupplierSectionView } from "./Views/FormSections/SupplierSectionView";
import { DeliverySectionView } from "./Views/FormSections/DeliverySectionView";
import { InstructionsToSupplierView } from "./Views/FormSections/InstructionsToSupplierView";
import { OrderLineItemsView } from "./Views/FormSections/OrderLineItemsView";
import { CategoryModel } from "./Supporting/CategoryModel";
import { SubCategoryModel } from "./Supporting/SubCategoryModel";
import { LineDescriptionModel } from "./Supporting/LineDescriptionModel";
import { InvalidDocumentsModal } from "./Supporting/InvalidDocumentsModal/InvalidDocumentsModal";
import { EmailSizeModal } from "./Supporting/EmailSizeModal/EmailSizeModal";
import { GenericWarningModal } from "Components/Modal/GenericWarningModal";

interface IProps {
    className?: string;
    poId?: string | null;
    viewedFromModal: boolean;
}

const PurchaseOrderViewBase: React.FC<IProps> = observer((props) => {
    // #region Code Behind

    const { match } = useRouter();
    const location = useLocation();
    const { poid } = match.params as any;
    const [viewModel] = useState(() => new PurchaseOrderViewModel(props.poId ? props.poId : poid));
    const [isFromApprovalSection, setIsFromApprovalSection] = useState<boolean>(false);
    const [showApprovalHistoryModal, setShowApprovalHistoryModal] = useState<boolean>(false);
    const [showSendSupplierEmailModal, setShowSendSupplierEmailModal] = useState<boolean>(false);
    const [showUnrejectModal, setShowUnrejectModal] = useState<boolean>(false);

    const setShowLineValidationModal = (val: boolean) => {
        viewModel.handleShowInvalidItemsModalChange(val);
    };

    const setShowMatchingUnapprovedRequisitionsModal = (val: boolean) => {
        viewModel.handleShowMatchingUnapprovedRequisitionsModalChange(val);
    };

    const setShowInvalidDocumentsModal = (val: boolean) => {
        viewModel.handleShowInvalidDocumentsModalChange(val);
        viewModel.resetEmailValidationParams();
    };

    const setShowEmailSizeModal = (val: boolean) => {
        viewModel.handleShowEmailSizeModalChange(val);
        viewModel.resetEmailValidationParams();
    };

    const setShowEmailMaxAttachmentsModal = (val: boolean) => {
        viewModel.handleShowEmailMaxAttachmentsModalChange(val);
        viewModel.resetEmailValidationParams();
    };

    const upsertApprovalStatusWithoutAttachments = async () => {
        await viewModel.upsertApprovalStatusWithoutAttachments();
    };

    const setShowInvalidSubmissionDocumentsModal = (val: boolean) => {
        viewModel.handleShowInvalidSubmissionDocumentsModalChange(val);
    };

    const setShowSubmissionMaxAttachmentsModal = (val: boolean) => {
        viewModel.handleShowSubmissionMaxAttachmentsModalChange(val);
    };

    const setShowSubmissionSizeModal = (val: boolean) => {
        viewModel.handleShowSubmissionSizeModalChange(val);
    };

    const setShowDuplicateSupplierModal = (val: boolean) => {
        viewModel.handleShowDuplicateSupplierModalChange(val);
    };

    const upsertWithoutAttachmentValidation = async () => {
        await viewModel.saveWithoutDocumentValidation();
    };

    const COLUMNS8 = "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr";

    useEffect(() => {
        runInAction(() => {
            viewModel.model.id = props.poId ? props.poId : poid;
        });
        let handleDataLoading = new Promise<void>(async (resolve, reject) => {
            try {
                if (isEmptyOrWhitespace(viewModel.model.id)) {
                    await viewModel.loadRelated();
                } else {
                    await viewModel.loadWithRelated();
                }
                resolve();
            } catch (error) {
                reject(error);
            }
        })
            .then(async () => {
                if (props.poId) {
                    viewModel.setIsViewOnly(true);
                }

                const { projectid } = match.params as any;
                const { ie } = match.params as any;

                // Check if from an IE Item or a Variation Item Line click.
                //
                // The "from" properties are an intention based on who requested the requisition form.
                // For example if coming from projects, you might have clicked on a variation in which
                // case the fromVariationItemLine will be set to true.
                //
                // Once processed, the "from" properties are reset.
                viewModel.populateFromItem();

                await viewModel.setIsCentralNavigation(IEGridItemViewModel.Instance.isCentral);

                switch (true) {
                    case viewModel.fromIEItemLine:
                        {
                            viewModel.resetFromVariationItem();

                            // Set Project
                            await viewModel.setProjectAsync({ id: viewModel.getProjectIdfromIEItem } as ProjectModel);

                            // Set Income & expenditure
                            await viewModel.setIncomeAndExpenditureAsync({ id: viewModel.getIEIdfromIEItem } as IncomeAndExpenditureModel);

                            // Set category
                            await viewModel.orderLine.setCategoryAsync({
                                id: viewModel.orderLine.categoryIdFromIEItem,
                                displayName: viewModel.orderLine.categoryNameFromIEItem,
                                variationId: null,
                            } as CategoryModel);

                            // Set sub-category
                            await viewModel.orderLine.setSubCategoryAsync({
                                id: viewModel.orderLine.subcategoryIdFromIEItem,
                                displayName: viewModel.orderLine.subcategoryNameFromIEItem,
                            } as SubCategoryModel);

                            // Set line description
                            viewModel.orderLine.setLineDescription({
                                id: viewModel.orderLine.lineDescriptionIdFromIEItem,
                                displayName: viewModel.orderLine.lineDescriptionNameFromIEItem,
                            } as LineDescriptionModel);
                        }
                        break;

                    case viewModel.fromVariationItemLine:
                        {
                            viewModel.resetFromIEItem();

                            // Set Project
                            await viewModel.setProjectAsync({ id: viewModel.getProjectIdfromVariationItem } as ProjectModel);

                            // Set Income & expenditure
                            await viewModel.setIncomeAndExpenditureAsync({ id: viewModel.getIEIdfromVariationItem } as IncomeAndExpenditureModel);

                            // Set category
                            await viewModel.orderLine.setCategoryAsync({
                                id: viewModel.orderLine.categoryIdFromVariationItem,
                                displayName: viewModel.orderLine.categoryNameFromVariationItem,
                                variationId: viewModel.orderLine.variationIdFromVariationItem,
                            } as CategoryModel);

                            // Set sub-category
                            await viewModel.orderLine.setSubCategoryAsync({
                                id: viewModel.orderLine.subcategoryIdFromVariationItem,
                                displayName: viewModel.orderLine.subcategoryNameFromVariationItem,
                            } as SubCategoryModel);

                            // Set line description
                            viewModel.orderLine.setLineDescription({
                                id: viewModel.orderLine.lineDescriptionIdFromVariationItem,
                                displayName: viewModel.orderLine.lineDescriptionNameFromVariationItem,
                            } as LineDescriptionModel);
                        }
                        break;

                    default:
                        {
                            viewModel.resetFromIEItem();
                            viewModel.resetFromVariationItem();

                            // Set Project
                            if (projectid) {
                                await viewModel.setProjectAsync({ id: projectid } as ProjectModel);
                            }

                            // Set Income & expenditure
                            if (ie) {
                                await viewModel.setIncomeAndExpenditureAsync({ id: ie } as IncomeAndExpenditureModel);
                            }
                        }
                        break;
                }
            })
            .then(() => {
                runInAction(() => {
                    viewModel.fromIEItemLine = false;
                    viewModel.fromVariationItemLine = false;
                    viewModel.orderLine.fromIEItemLine = false;
                    viewModel.orderLine.fromVariationItemLine = false;
                    IEGridItemViewModel.Instance.resetRelatedGuids();
                });
            })
            .catch((error) => {
                // handle error
            });

        // Check if the user has navigated from the approvals list.
        const { approvalMode } = match.params as any;
        if (approvalMode && approvalMode === "true") {
            setIsFromApprovalSection(true);
        } else {
            setIsFromApprovalSection(false);
        }

        // Check if the form should be view only.
        if ((viewModel.model.id && AppUrls.Client.PurchaseOrder.View.replace(":poid", viewModel.model.id) === location.pathname) || props.viewedFromModal === true) {
            viewModel.setIsViewOnly(true);
        }
    }, []);

    const handleSendSupplierEmail = () => {
        setShowSendSupplierEmailModal(false);
        viewModel.sendSupplierEmail();
    };

    const handleUnrejectPO = () => {
        setShowUnrejectModal(false);
        viewModel.handleUnreject();
    };

    const onSubmit = (event: any) => {
        event.preventDefault();
    };

    /**
     * Refs for the requisition groups. Used to scroll to each of the group components when a link is clicked.
     */
    const refs: React.RefObject<HTMLDivElement>[] = viewModel.requisitionGroups
        .filter((g) => g.model.id !== null && g.model.id !== undefined)
        .reduce<React.RefObject<HTMLDivElement>[]>((acc: React.RefObject<HTMLDivElement>[], value: RequisitionRequestItemGroupViewModel) => {
            acc[value.model.id!] = React.createRef<HTMLDivElement>();
            return acc;
        }, [] as React.RefObject<HTMLDivElement>[]);

    const internalNotesComponent = () => {
        return (
            <ApprovalPanelNotesContainer>
                <div>
                    <Typography variant="h2">Requisition note (internal use)</Typography>
                </div>
                <div>
                    {viewModel.canEditRequisitionNote && (
                        <DarwinInput<PurchaseOrderModel>
                            type="text"
                            label="Add requisition note"
                            multiLine={true}
                            validateOnBlur={true}
                            viewModel={viewModel}
                            fieldName="requisitionNote"
                            shrink={true}
                            // maxLength={256}
                            editMode={viewModel.canEditRequisitionNote}
                        />
                    )}

                    {!viewModel.canEditRequisitionNote && <div className="keep-newline-formatting">{viewModel.model.requisitionNote}</div>}

                    <Uploader handleSelectFile={(e: any) => viewModel.fileChange(e, true)} isFormDisabled={!viewModel.canEditRequisitionNote} label={""} />
                    {viewModel.model.requisitionFile && (
                        <div className="uploadedFile">
                            {viewModel.model.requisitionFile.map((item: IDisplayFile, index: number) => {
                                return (
                                    !item.isDeleted && (
                                        <div className="uploadedFile-box">
                                            <div className="uploadedFile-heading">
                                                <IconButton onClick={() => viewModel.DownloadFile(item.fileUrl, item.fileName)}>
                                                    <SystemUpdateTwoToneIcon />
                                                </IconButton>
                                                <h3>{item.fileName}</h3>
                                            </div>
                                            <IconButton onClick={() => viewModel.deleteRequisitionFile(index)} disabled={!viewModel.canEditRequisitionNote}>
                                                <DeleteOutlinedIcon />
                                            </IconButton>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    )}

                    {viewModel.canSaveRequisitionNote && (
                        <>
                            <br />
                            <PrimaryButton displayName="Save note" execute={() => viewModel.saveNote()} fullWidth={true} canExecute={viewModel.canSaveRequisitionNote} />
                        </>
                    )}
                </div>
            </ApprovalPanelNotesContainer>
        );
    };

    const revisionPDFsComponent = () => {
        const handleGenerateAllPurchaseOrderPDF = async (revision: string | null) => {
            if (viewModel.hasId) {
                let revisionParam: string | null = null;
                if (revision !== "") {
                    revisionParam = revision;
                }
                viewModel.handleGenerateAllPurchaseOrderPDF(viewModel.model.id, revisionParam);
            }
        };

        if (!viewModel.revisionHistory || viewModel.revisionHistory.length === 0) {
            return <></>;
        }

        return (
            <ApprovalPanelRevisionPDFsContainer>
                <div>
                    <Typography variant="h2">Revision PDFs</Typography>
                </div>
                <div>
                    {viewModel.revisionHistory.map(({ formattedPONumber, revision }, index) => (
                        <p key={index} className={"pdf-link"} onClick={() => handleGenerateAllPurchaseOrderPDF(revision)}>
                            {formattedPONumber}
                            {revision}
                        </p>
                    ))}
                </div>
            </ApprovalPanelRevisionPDFsContainer>
        );
    };

    const additionalBodyComponent = () => {
        return (
            <>
                {internalNotesComponent()}
                {revisionPDFsComponent()}
            </>
        );
    };

    return (
        <Form className={props.className} onSubmit={onSubmit}>
            {viewModel.showSendForApprovalModal && (
                <SendForApprovalModal
                    onClose={() => viewModel.handleShowSendForApprovalModalChange(false)}
                    open={viewModel.getShowSendForApprovalModal}
                    title="Send for approval"
                    confirmText="Send for approval"
                    onSave={() => viewModel.amendRequisition(false)}
                    //viewModel={viewModel}
                    value={viewModel.model.requesterNotes}
                    setValue={(val: string) => viewModel.setRequesterNote(val)}
                    isLoading={viewModel.IsLoading}
                    isDisabled={!viewModel.getRequesterNoteValid}
                />
            )}
            <HeaderContainer>
                <FormHeader displayName={viewModel.getPageTitle} />
            </HeaderContainer>

            <ApprovalHeaderContainer>
                {viewModel.approvalPanelViewModel.hasApproval && !props.poId && (
                    <FormSection>
                        <PrimaryButton
                            displayName="Approval history"
                            execute={() => setShowApprovalHistoryModal(true)}
                            fullWidth={false}
                            canExecute={viewModel.approvalPanelViewModel.hasApproval}
                        />
                    </FormSection>
                )}
                {viewModel.canShowReviseButton && (
                    <FormSection>
                        <PrimaryButton displayName="Revise" execute={() => viewModel.setIsInReviseMode(true)} fullWidth={false} canExecute={!viewModel.getIsInReviseMode} />
                    </FormSection>
                )}
                {viewModel.canRevise && props.viewedFromModal && (
                    <FormSection>
                        <PrimaryButton displayName="Revise" execute={() => viewModel.navigateToPOForm()} fullWidth={false} canExecute={props.viewedFromModal} />
                    </FormSection>
                )}
                {viewModel.canShowSendSupplierEmailButton && (
                    <FormSection>
                        <PrimaryButton
                            displayName="Send email to supplier"
                            execute={() => setShowSendSupplierEmailModal(true)}
                            fullWidth={false}
                            canExecute={viewModel.canShowSendSupplierEmailButton && !viewModel.isInReviseMode}
                        />
                    </FormSection>
                )}
                {viewModel.canShowUnrejectButton && (
                    <FormSection>
                        <PrimaryButton
                            displayName="Unreject"
                            execute={() => setShowUnrejectModal(true)}
                            fullWidth={false}
                            canExecute={viewModel.canShowUnrejectButton && !viewModel.isInReviseMode}
                        />
                    </FormSection>
                )}
            </ApprovalHeaderContainer>

            {viewModel.canShowDuplicateSupplierBanner && (
                <DuplicateSupplierBanner>
                    There is already a purchase order on this I&E for this suppliers' sub contractor agreement, so this requisition will require a level 5 approval
                </DuplicateSupplierBanner>
            )}

            <PurchaseOrderApprovalPanel
                refs={refs}
                viewModel={viewModel}
                sideOpen={!viewModel.IsLoading && !viewModel.showEmailSizeModal && !viewModel.showInvalidDocumentsModal && !viewModel.showEmailMaxAttachmentsModal}
                isFromApprovalSection={isFromApprovalSection}
                primaryTitle={viewModel.approvalPanelViewModel.getTitle}
                secondaryTitle={viewModel.getApprovalPanelSecondaryTitle}
                requisitionRequestId={viewModel.model.id}
                additionalBodyComponent={additionalBodyComponent}
            />

            <DashedDivider gutterBottom={true} />

            <FormSection>
                {/* Stock/Standard form */}
                {(() => {
                    switch (true) {
                        case viewModel.canDisplayAsStockRequisitionForm:
                            return <StockSectionView viewModel={viewModel} />;

                        case viewModel.canDisplayAsStandardRequisitionForm:
                            return <NonStockSectionView viewModel={viewModel} />;
                    }
                })()}

                {/* Supplier form */}
                {viewModel.canViewSupplier && <SupplierSectionView viewModel={viewModel} />}

                {/* Deliver form */}
                <DeliverySectionView viewModel={viewModel} />

                {/* Order line items*/}
                <OrderLineItemsView viewModel={viewModel} refs={refs} />

                {/* Render supplier instruction form */}
                <InstructionsToSupplierView viewModel={viewModel} />
            </FormSection>

            <SolidDivider gutterBottom={true} />

            {viewModel.canAddRequisition && (
                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                        <Cell ts={2} ds={2}>
                            <PrimaryButton
                                displayName="Send for approval & create new PR"
                                execute={() => viewModel.sendAndCreate(false)}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <PrimaryButton
                                displayName="Send for approval & exit"
                                execute={() => viewModel.sendAndExit(false)}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                        </Cell>
                        <Cell ts={2} ds={2}>
                            <DefaultButton displayName="Cancel" execute={viewModel.handleCancel} fullWidth={true} canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading} />
                        </Cell>
                        <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                    </Grid>
                </FormSection>
            )}

            {viewModel.canAmendRequisition && (
                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                        <Cell ts={2} ds={2}>
                            <PrimaryButton
                                displayName="Amend"
                                execute={() => viewModel.handleShowSendForApprovalModalChange(true)}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && !viewModel.IsLoading}
                            />
                        </Cell>
                        <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                    </Grid>
                </FormSection>
            )}

            {viewModel.getIsInReviseMode && (
                <FormSection>
                    <Grid columnGap={30} rowGap={20} tc={COLUMNS8} dc={COLUMNS8}>
                        {/* Group of 8 columns */}
                        <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                        <Cell ts={2} ds={2}>
                            <PrimaryButton
                                displayName="Revise, send for approval & exit"
                                execute={() => viewModel.reviseAndExit(false)}
                                fullWidth={true}
                                canExecute={!viewModel.isFormDisabled && viewModel.getIsInReviseMode && !viewModel.IsLoading}
                            />
                        </Cell>
                        <Cell display={{ xs: "none", md: "block" }} ts={1} ds={1} />
                    </Grid>
                </FormSection>
            )}

            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />

            <ApprovalHistoryModal
                onClose={() => setShowApprovalHistoryModal(false)}
                open={showApprovalHistoryModal}
                title="Approval history"
                approvalHistoryItems={viewModel.approvalPanelViewModel.approvalHistoryItems}
                isLoading={viewModel.IsLoading}
                isCentral={viewModel.approvalPanelViewModel.model.isCentral}
            />

            <LineValidationModal
                onClose={() => setShowLineValidationModal(false)}
                open={viewModel.getShowInvalidItemsModal}
                title="Line validation errors"
                lineValidationItems={viewModel.getInvalidItemsList}
                isLoading={viewModel.IsLoading}
            />

            <UnapprovedPurchaseOrderLineValidationModal
                onClose={() => setShowMatchingUnapprovedRequisitionsModal(false)}
                open={viewModel.getShowMatchingUnapprovedRequisitionsModal}
                title="There is an unapproved order against one or more of the lines."
                message={"There is an unapproved order against one or more of the lines. Please wait until that order has been approved."}
                items={viewModel.matchingUnapprovedRequisitions}
                isLoading={viewModel.IsLoading}
            />

            <DeleteModal
                open={showSendSupplierEmailModal}
                onClose={() => setShowSendSupplierEmailModal(false)}
                onDelete={handleSendSupplierEmail}
                title="Send email to supplier?"
                text="Are you sure you want to send an email to the supplier?"
                isLoading={!viewModel.canShowSendSupplierEmailButton || viewModel.IsLoading}
            />

            <DeleteModal
                open={showUnrejectModal}
                onClose={() => setShowUnrejectModal(false)}
                onDelete={handleUnrejectPO}
                title="Unreject purchase order?"
                text="Are you sure you want to unreject the purchase order?"
                isLoading={!viewModel.canShowUnrejectButton || viewModel.IsLoading}
            />

            <InvalidDocumentsModal
                onClose={() => setShowInvalidDocumentsModal(false)}
                onSubmit={upsertApprovalStatusWithoutAttachments}
                open={viewModel.getShowInvalidDocumentsModal}
                title="One or more of the documents on this requisition is too large"
                message={
                    "For this requisition to be sent to the supplier, each individual attachment must be under 7.5MB. Please review the attachments and ensure each one is under 7.5MB - Zipping attachments may help with this."
                }
                items={viewModel.invalidDocuments}
                isLoading={viewModel.IsLoading}
                saveText="Send without documents attached."
            />

            <EmailSizeModal
                onClose={() => setShowEmailSizeModal(false)}
                onSubmit={upsertApprovalStatusWithoutAttachments}
                open={viewModel.getShowEmailSizeModal}
                title="The total size of the attachments on this requisition are too large"
                message={
                    "For this requisition to sent to the supplier, the total size of the attachments can't exceed 24MB. Please review the attachments and reduce the size - Zipping the attachments may help with this."
                }
                isLoading={viewModel.IsLoading}
                saveText="Send without documents attached."
            />

            <EmailSizeModal
                onClose={() => setShowEmailMaxAttachmentsModal(false)}
                onSubmit={upsertApprovalStatusWithoutAttachments}
                open={viewModel.getShowEmailMaxAttachmentsModal}
                title="The are too many attachments on this requisition"
                message={
                    "For this requisition to be sent to the supplier, the number of attachments can't exceed 20. Please review the attachments and reduce the number you have attached - Zipping attachments may help with this."
                }
                isLoading={viewModel.IsLoading}
                saveText="Send without documents attached."
            />

            <InvalidDocumentsModal
                onClose={() => setShowInvalidSubmissionDocumentsModal(false)}
                onSubmit={upsertWithoutAttachmentValidation}
                open={viewModel.getShowInvalidSubmissionDocumentsModal}
                title="One or more of the documents on this requisition is too large"
                message={
                    "For this requisition to be sent to the supplier, each individual attachment must be under 7.5MB. Please review the attachments and ensure each one is under 7.5MB - Zipping attachments may help with this."
                }
                items={viewModel.invalidSubmissionDocuments}
                isLoading={viewModel.IsLoading}
                saveText="Save anyway"
            />

            <EmailSizeModal
                onClose={() => setShowSubmissionSizeModal(false)}
                onSubmit={upsertWithoutAttachmentValidation}
                open={viewModel.getShowSubmissionSizeModal}
                title="The total size of the attachments on this requisition are too large"
                message={
                    "For this requisition to sent to the supplier, the total size of the attachments can't exceed 24MB. Please review the attachments and reduce the size - Zipping the attachments may help with this."
                }
                isLoading={viewModel.IsLoading}
                saveText="Save anyway"
            />

            <EmailSizeModal
                onClose={() => setShowSubmissionMaxAttachmentsModal(false)}
                onSubmit={upsertWithoutAttachmentValidation}
                open={viewModel.getShowSubmissionMaxAttachmentsModal}
                title="The are too many attachments on this requisition"
                message={
                    "For this requisition to be sent to the supplier, the number of attachments can't exceed 20. Please review the attachments and reduce the number you have attached - Zipping attachments may help with this."
                }
                isLoading={viewModel.IsLoading}
                saveText="Save anyway"
            />

            <GenericWarningModal
                open={viewModel.getShowDuplicateSupplierModal}
                onClose={() => setShowDuplicateSupplierModal(false)}
                onButtonClick={() => setShowDuplicateSupplierModal(false)}
                title="Duplicate supplier order"
                text="There is already an order for this supplier against this I&E, are you sure you want to continue creating a separate order? This will require a level 5 approval."
                isLoading={viewModel.IsLoading}
            />

            <GenericWarningModal
                open={viewModel.getShowInvalidPrePaymentYearModal}
                onClose={() => viewModel.setShowInvalidPrePaymentYearModal(false)}
                onButtonClick={() => viewModel.setShowInvalidPrePaymentYearModal(false)}
                title="Invalid prepayment year"
                text="You have already included this year in your prepayment."
                isLoading={viewModel.IsLoading}
            />
        </Form>
    );
});

export const PurchaseOrderView = styled(PurchaseOrderViewBase)`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

const HeaderContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 70px;
`;

const ApprovalHeaderContainer = styled(Box)`
    display: flex;
    flex-direction: column;

    button {
        margin-right: 20px;
    }
`;

const ApprovalPanelNotesContainer = styled.div`
    padding: 8px 15px 10px 15px;

    h2 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .uploadedFile {
        grid-template-columns: 1fr;
    }

    text-area {
        min-height: 75px;
    }
`;

const ApprovalPanelRevisionPDFsContainer = styled.div`
    padding: 8px 15px 10px 15px;

    h2 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    > div:nth-child(2) {
        > p {
            text-align: left;
        }
    }
`;

const DuplicateSupplierBanner = styled.div`
    background-color: #ff0000b8;
    margin: 0 1.875rem 0 1.875rem;
    text-align: center;
`;
