// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

// Custom
import { IEGridCategoryModel, IEGridSubcategoryModel, IEGridItemModel, PurchaseOrderListItemModelDTO } from "../IEmodels";
import { IEViewModel } from "../IE.ViewModel";
import { PackagesViewModel } from "../PackagesViewModel";
import { VariationItem } from "./Rows/VariationItem";
import { VariationCat } from "./Rows/VariationCatRow";
import { VariationSubcat } from "./Rows/VariationSubcatRow";
import { VariationPurchaseOrderItem } from "./Rows/VariationPurchaseOrderRow";

// Styling & Images
import { SummaryGridView, ToggleButton } from "./VariationGrid.Styles";
import { theme } from "Globals/Styles/AppTheme";
import { makeStyles } from "@material-ui/core";
import MoneySVG from "Content/Money.svg";
import { VariationGridModel } from "../IEmodels/Variation/VariationGridModel";
import { Variation } from "./Rows/Variation";
import { PopulateFromVariationItemModel } from "../IEmodels/IEGridItemViewModel";
import { CommercialViewModel } from "../Commercial.ViewModel";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { formatCreditDebitClass, formatCreditDebitReverseClass } from "Utils/Utils";
import { VariationViewViewModel } from "../IEmodels/Variation/VariationViewViewModel";
import { VariationTypeEnum } from "Views/Variation/Form/VariationModel";

interface ITableProps {
    ieId: string;
    projectId: string;
    variationViewViewModel: VariationViewViewModel;
    packagesViewModel: PackagesViewModel;
    ieType: string;
    variationType: VariationTypeEnum;
    variationTypeName: string;
}

export const VariationGridViewCatSubDesc: React.FunctionComponent<ITableProps> = (props) => {
    const { ieId, projectId, variationViewViewModel, variationTypeName, variationType } = props;

    const packagesViewModel = PackagesViewModel.Instance;

    const commercialViewModel = CommercialViewModel.Instance;

    const formatDebit = (val: number) => {
        return commercialViewModel.formatDebit(val);
    };

    const classes = useStyles();

    const handleDescriptionEnable = (variationIndex: number, categoryIndex: number, subCategoryIndex: number, index: number) => {
        commercialViewModel.setActiveVariationDescription(index);

        if (subCategoryIndex !== -1) {
            commercialViewModel.setActiveVariationSubCategory(subCategoryIndex);
        }
        if (categoryIndex !== -1) {
            commercialViewModel.setActiveVariationCategory(categoryIndex);
        }
        if (variationIndex !== -1) {
            commercialViewModel.setActiveVariation(variationIndex);
        }
        commercialViewModel.setIsPurchaseOrderActiveVariation(false);
    };

    const handleSubCategoryEnable = (variationIndex: number, categoryIndex: number, index: number) => {
        if (variationIndex !== -1) {
            commercialViewModel.setActiveVariation(variationIndex);
        }
        if (categoryIndex !== -1) {
            commercialViewModel.setActiveVariationCategory(categoryIndex);
        }
        commercialViewModel.setActiveVariationSubCategory(index);
        commercialViewModel.setActiveVariationDescription(-1);
        commercialViewModel.setIsPurchaseOrderActiveVariation(false);
    };

    const handleCategoryEnable = (variationIndex: number, index: number) => {
        if (variationIndex !== -1) {
            commercialViewModel.setActiveVariation(variationIndex);
        }
        commercialViewModel.setActiveVariationCategory(index);
        commercialViewModel.setActiveVariationSubCategory(-1);
        commercialViewModel.setActiveVariationDescription(-1);
        commercialViewModel.setIsPurchaseOrderActiveVariation(false);
    };

    const handleVariationEnable = (index: number) => {
        commercialViewModel.setActiveVariation(index);
        commercialViewModel.setActiveVariationCategory(-1);
        commercialViewModel.setActiveVariationSubCategory(-1);
        commercialViewModel.setActiveVariationDescription(-1);
        commercialViewModel.setIsPurchaseOrderActiveVariation(false);
    };

    const itemRowHistory = (item: IEGridItemModel, catGuid: string, catName: string | undefined, subcatName: string | undefined, variationId: string) => {
        let retVal: PopulateFromVariationItemModel = {
            projectId: projectId,
            ieId: ieId,
            variationId: variationId,
            variationCategoryId: catGuid,
            variationCategoryName: catName,
            variationSubcategoryId: item.parentId,
            variationSubcategoryName: subcatName,
            variationItemLineId: item.id,
            variationItemName: "",
        };

        return retVal;
    };

    const purchaseOrderView = (obj: PurchaseOrderListItemModelDTO[], ielineId: string) => {
        return (
            <IESubItem>
                <li>
                    <div style={{ display: "flex", alignItems: "center", backgroundColor: "#EDEDED" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img src={MoneySVG}></img>
                            <span style={{ display: "inline-block", marginLeft: "12px" }}>Purchase Orders</span>
                        </div>
                        {commercialViewModel.getIsPurchaseOrderActiveVariation ? (
                            <ToggleButton>
                                <span
                                    className={"open"}
                                    onClick={() => {
                                        commercialViewModel.setIsPurchaseOrderActiveVariation(false);
                                    }}
                                >
                                    &minus;
                                </span>
                            </ToggleButton>
                        ) : (
                            <ToggleButton>
                                <span
                                    className={"close"}
                                    onClick={() => {
                                        commercialViewModel.setIsPurchaseOrderActiveVariation(true);
                                    }}
                                >
                                    &#43;
                                </span>
                            </ToggleButton>
                        )}
                    </div>
                </li>
                {commercialViewModel.getIsPurchaseOrderActiveVariation &&
                    obj.map((req: PurchaseOrderListItemModelDTO, index: number) => (
                        <>
                            <VariationPurchaseOrderItem itemModel={req} rowIndex={index} ieLineId={ielineId} ieId={ieId} isLoading={commercialViewModel.IsLoading} />
                        </>
                    ))}
            </IESubItem>
        );
    };

    const itemView = (
        obj: IEGridItemModel[],
        sourceId: number,
        varIndex: number,
        catIndex: number,
        subIndex: number,
        catGuid: string,
        parentId: string,
        categoryId: number,
        subcategoryId: number,
        categoryName: string | undefined,
        subcategoryName: string | undefined,
        variationId: string,
    ) => {
        return (
            <>
                {obj.map((i: IEGridItemModel, index: number) => (
                    <>
                        <VariationItem
                            itemModel={i}
                            sourceId={sourceId}
                            variationId={variationId}
                            rowIndex={index}
                            categoryId={categoryId}
                            subcategoryId={subcategoryId}
                            rowEnable={(vi, ci, sci, i) => handleDescriptionEnable(vi, ci, sci, i)}
                            //rowEdit={(row) => handleIEItemClick(i, parentId, categoryId, subcategoryId)}
                            ieId={ieId}
                            parentId={parentId}
                            thisGuid={i.id}
                            variationIndex={varIndex}
                            categoryIndex={catIndex}
                            subCategoryIndex={subIndex}
                            currentActiveVarIndex={commercialViewModel.getActiveVariation}
                            currentActiveCatIndex={commercialViewModel.getActiveVariationCategory}
                            currentActiveSubCatIndex={commercialViewModel.getActiveVariationSubCategory}
                            currentActiveDescIndex={commercialViewModel.getActiveVariationDescription}
                            guidHistory={itemRowHistory(i, catGuid, categoryName, subcategoryName, variationId)}
                        />

                        {commercialViewModel.getActiveVariationDescription === index &&
                            commercialViewModel.getActiveVariationSubCategory === subIndex &&
                            commercialViewModel.getActiveVariationCategory === catIndex &&
                            commercialViewModel.getActiveVariation === varIndex &&
                            i.groupedPOItems.length > 0 && <>{purchaseOrderView(i.groupedPOItems, i.id)}</>}
                    </>
                ))}
            </>
        );
    };

    const subcategoryView = (
        obj: IEGridSubcategoryModel[],
        varIndex: number,
        catIndex: number,
        catGuid: string,
        categoryId: number,
        categoryName: string | undefined,
        variationId: string,
    ) => {
        return (
            <>
                {obj.map((s: IEGridSubcategoryModel, index: number) => (
                    <>
                        <div
                            className={
                                commercialViewModel.getActiveVariationSubCategory === index &&
                                commercialViewModel.getActiveVariationCategory === catIndex &&
                                commercialViewModel.getActiveVariation === varIndex
                                    ? classes.SubCatBorder
                                    : ""
                            }
                        >
                            <VariationSubcat
                                itemModel={s}
                                categoryGuid={catGuid}
                                variationIndex={varIndex}
                                categoryIndex={catIndex}
                                rowIndex={index}
                                rowEnable={(v, p, i) => handleSubCategoryEnable(v, p, i)}
                                rowType={"subcategory"}
                                iEId={ieId}
                                currentActiveVarIndex={commercialViewModel.getActiveVariation}
                                currentActiveCatIndex={commercialViewModel.getActiveVariationCategory}
                                currentActiveSubCatIndex={commercialViewModel.getActiveVariationSubCategory}
                            />
                            {commercialViewModel.getActiveVariationSubCategory === index &&
                                commercialViewModel.getActiveVariationCategory === catIndex &&
                                commercialViewModel.getActiveVariation === varIndex && (
                                    <>
                                        {itemView(
                                            s.getGridItems,
                                            s.sourceId,
                                            varIndex,
                                            catIndex,
                                            index,
                                            catGuid,
                                            s.id,
                                            categoryId,
                                            s.sourceId,
                                            categoryName,
                                            packagesViewModel.getSubcategoryName(s.sourceId),
                                            variationId,
                                        )}
                                    </>
                                )}
                        </div>
                    </>
                ))}
            </>
        );
    };

    const categoryView = (obj: IEGridCategoryModel[], varIndex: number, variationId: string) => {
        return (
            <>
                {obj.map((c: IEGridCategoryModel, index: number) => (
                    <>
                        <div className={commercialViewModel.getActiveVariationCategory === index && commercialViewModel.getActiveVariation === varIndex ? classes.CatBorder : ""}>
                            <VariationCat
                                itemModel={c}
                                variationIndex={varIndex}
                                rowIndex={index}
                                iEId={ieId}
                                rowType={"ie"}
                                rowEnable={(v, i) => handleCategoryEnable(v, i)}
                                currentActiveVarIndex={commercialViewModel.getActiveVariation}
                                currentActiveCatIndex={commercialViewModel.getActiveVariationCategory}
                            />
                            {commercialViewModel.getActiveVariationCategory === index && commercialViewModel.getActiveVariation === varIndex && (
                                <>{subcategoryView(c.subcategories, varIndex, index, c.id, c.sourceId, packagesViewModel.getCategoryName(c.sourceId), variationId)}</>
                            )}
                        </div>
                    </>
                ))}
            </>
        );
    };

    return useObserver(() => (
        <>
            {variationViewViewModel.variationsAndRelated.length > 0 && <span style={{ letterSpacing: "-0.36px", fontSize: "18px", marginLeft: "35px" }}>{variationTypeName}</span>}
            {variationViewViewModel.variationsAndRelated.length > 0 && (
                <SummaryGridView className={classes.IELineGroup}>
                    {variationViewViewModel.variationsAndRelated.map((v: VariationGridModel, index: number) => (
                        <>
                            <div className={commercialViewModel.getActiveVariation === index ? classes.VarBorder : ""}>
                                <Variation
                                    itemModel={v}
                                    rowIndex={index}
                                    iEId={ieId}
                                    rowType={"ie"}
                                    rowEnable={(i) => handleVariationEnable(i)}
                                    currentActiveVarIndex={commercialViewModel.getActiveVariation}
                                    variationType={variationType}
                                />
                                {commercialViewModel.getActiveVariation === index && <>{categoryView(v.categories, index, v.id)}</>}
                            </div>
                        </>
                    ))}
                    <li className="ie">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <span style={{ paddingLeft: "5px" }}>Subtotal</span>
                        </div>
                        <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(variationViewViewModel.model.income))}</div>
                        <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(variationViewViewModel.model.estimateCost))}</div>
                        <div className="right">{variationViewViewModel.model.margin}</div>
                        <div className={"right" + formatDebit(variationViewViewModel.model.committedCost)}>
                            {formatCurrencyForGrid(formatCurrencyNoSign(variationViewViewModel.model.committedCost))}
                        </div>
                        <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(variationViewViewModel.model.futureSpend))}</div>
                        <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(variationViewViewModel.model.totalExpectedSpend))}</div>
                        <div className={"right" + formatCreditDebitClass(variationViewViewModel.model.variance)}>
                            {formatCurrencyForGrid(formatCurrencyNoSign(variationViewViewModel.model.variance))}
                        </div>
                        <div className={"right" + formatCreditDebitReverseClass(variationViewViewModel.model.roValue)}>
                            {formatCurrencyForGrid(formatCurrencyNoSign(variationViewViewModel.model.roValue))}
                        </div>
                        <div className="right" style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}></div>
                    </li>
                </SummaryGridView>
            )}
        </>
    ));
};

const useStyles = makeStyles({
    IELineGroup: {
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    VarBorder: {
        borderTop: `4px solid ${theme.palette.detail.main}`,
        borderBottom: `4px solid ${theme.palette.detail.main}`,
    },
    CatBorder: {
        borderTop: `3px solid ${theme.palette.detail.main}`,
        borderBottom: `3px solid ${theme.palette.detail.main}`,
    },
    SubCatBorder: {
        borderTop: `2px solid ${theme.palette.detail.main}`,
        borderBottom: `2px solid ${theme.palette.detail.main}`,
    },
    ItemBorder: {
        borderTop: `1px solid ${theme.palette.detail.main}`,
        borderBottom: `1px solid ${theme.palette.detail.main}`,
    },
});

export const IESubItem = styled.div`
    > li {
        > div:nth-child(1) {
            margin-left: 50px;
        }
    }
`;
