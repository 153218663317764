import * as MobX from "mobx";
import { ModelBase } from "@shoothill/core";
import moment from "moment";
import { IEGridModel, IEGridModelDTO } from "./IEGridModel";

export class IEViewModel extends ModelBase<IEViewModel, IEViewModelDTO> {
    public id: string = "";
    public createdDate: string = "";

    @MobX.observable
    public createdMomentDate: moment.Moment | undefined;

    public sourceId: number = 0;

    @MobX.observable
    public parentId: string | null = null;

    @MobX.observable
    public nameId: string = "";

    @MobX.observable
    public name: string = "";

    @MobX.observable
    public cell: number = -1;

    @MobX.observable
    public income: number = 0;

    @MobX.observable
    public estimateCost: number = 0;

    @MobX.observable
    public estimatedMargin: number = 0;

    @MobX.observable
    public futureSpend: number = 0;

    @MobX.observable
    public committedCost: number = 0;

    @MobX.observable
    public totalExpectedSpend: number = 0;

    @MobX.observable
    public variance: number = 0;

    @MobX.observable
    public changePeriod: number = 0;

    @MobX.observable
    public margin: number = 0;

    @MobX.observable
    public roValue: number = 0;

    @MobX.observable
    public incomeSub: number = 0;

    @MobX.observable
    public estimateCostSub: number = 0;

    @MobX.observable
    public estimatedMarginSub: number = 0;

    @MobX.observable
    public futureSpendSub: number = 0;

    @MobX.observable
    public committedCostSub: number = 0;

    @MobX.observable
    public totalExpectedSpendSub: number = 0;

    @MobX.observable
    public varianceSub: number = 0;

    @MobX.observable
    public changePeriodSub: number = 0;

    @MobX.observable
    public marginSub: number = 0;

    @MobX.observable
    public roValueSub: number = 0;

    @MobX.observable
    public type: string = "";

    @MobX.observable
    public ies: IEGridModel[] = [];
    displayName: any;

    @MobX.action
    fromDto(model: IEViewModelDTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "ie") {
                    let items: IEGridModel[] = [];
                    model[key].forEach((element) => {
                        let newModel = new IEGridModel();
                        newModel.fromDto(element);
                        items.push(newModel);
                    });
                    this.ies = items;
                } else {
                    this[key] = model[key];
                }
            }
        }

        if (model.createdDate !== null && model.createdDate !== undefined) {
            this.createdMomentDate = moment.utc(model.createdDate);
        }
    }

    toDto(model: IEViewModel): void {}
}

export type IEViewModelDTO = {
    id: string;
    nameId: string;
    name: string;
    sourceId: number;
    cell: number;
    createdDate: string;
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    changePeriod: number;
    margin: number;
    roValue: number;
    incomeSub: number;
    estimateCostSub: number;
    estimatedMarginSub: number;
    futureSpendSub: number;
    committedCostSub: number;
    totalExpectedSpendSub: number;
    varianceSub: number;
    changePeriodSub: number;
    marginSub: number;
    roValueSub: number;
    ie: IEGridModelDTO[];
    type: string;
};
