import styled from "styled-components";
import { SummaryGridView } from "../IETables/IEGrid.Styles";
import { theme } from "Globals/Styles/AppTheme";

export const ValuationGridViewContainer = styled(SummaryGridView)`
    li {
        &.valuation-po {
            > * {
                display: flex;
                align-items: center;

                &.right {
                    justify-content: flex-end;
                }
            }
        }

        &.valuation-row {
            .valuation-title {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    > div.active {
        border-top: 4px solid ${theme.palette.detail.main};
        border-bottom: 4px solid ${theme.palette.detail.main};
    }
`;
