import { ApiResult, FieldType, observable, ViewModelBase } from "@shoothill/core";
import { ValuationDetailsDTO, ValuationModel } from "./ValuationModel";
import { AppUrls } from "AppUrls";
import { computed, runInAction } from "mobx";
import { ValuationItemViewModel } from "./ValuationItemViewModel";
import { ValuationItemModel } from "./ValuationItemModel";

const VAT_PERCENTAGE = 0.2;

type ValuationParams =
    | {
          new: true;
          poId: string;
      }
    | {
          new?: false;
          valuationId: string;
      };

export class ValuationViewModel extends ViewModelBase<ValuationModel> {
    private readonly params: ValuationParams;

    @observable
    public valuationItemViewModels = observable<ValuationItemViewModel>([]);

    constructor(params: ValuationParams) {
        super(new ValuationModel(), false);
        this.setDecorators(ValuationModel);

        this.params = params;
    }

    @computed
    public get isFirstValuation(): boolean {
        return this.model.valuationNumber === 1;
    }

    @computed
    public get sections(): ValuationSection[] {
        const itemGroups: ValuationGroup[] = [];
        const variationGroups: ValuationGroup[] = [];

        for (const group of this.model.groups) {
            if (group.isVariation) {
                variationGroups.push({ id: group.id, name: group.name, items: this.valuationItemViewModels.filter((x) => x.model.groupId === group.id) });
            } else {
                itemGroups.push({ id: group.id, name: group.name, items: this.valuationItemViewModels.filter((x) => x.model.groupId === group.id) });
            }
        }

        return [
            {
                title: "Contractors proposal",
                groups: itemGroups,
                totals: {
                    title: "TOTAL CONTRACTORS PROPOSAL FIGURE Excluding Variations",
                    contractValue: itemGroups.reduce((acc, curr) => acc + curr.items.reduce((acc, curr) => acc + curr.model.contractValue, 0), 0),
                    includingMcd: itemGroups.reduce((acc, curr) => acc + curr.items.reduce((acc, curr) => acc + curr.includingMcd, 0), 0),
                },
            },
            {
                title: "Variations/ Provisional Sums",
                groups: variationGroups,
                totals: {
                    title: "Variations/ Provisional Sums Total",
                    contractValue: variationGroups.reduce((acc, curr) => acc + curr.items.reduce((acc, curr) => acc + curr.model.contractValue, 0), 0),
                    includingMcd: variationGroups.reduce((acc, curr) => acc + curr.items.reduce((acc, curr) => acc + curr.includingMcd, 0), 0),
                },
            },
        ].filter((x) => x.groups.length > 0 && x.groups.some((y) => y.items.length > 0));
    }

    @computed
    public get grandTotal(): number {
        return this.sections.reduce((acc, curr) => acc + curr.totals.contractValue, 0);
    }

    @computed
    public get grandTotalAmountClaimed(): number {
        return this.valuationItemViewModels.reduce((acc, curr) => acc + curr.model.amountClaimed, 0);
    }

    @computed
    public get mainContractorsDiscountTotal(): number {
        return this.grandTotalAmountClaimed * ((this.model.mainContractorsDiscount ?? 0) / 100);
    }

    @computed
    public get retentionTotal(): number {
        return this.grandTotalAmountClaimed * ((this.model.retention ?? 0) / 100);
    }

    @computed
    public get netClaim(): number {
        return this.grandTotalAmountClaimed - this.mainContractorsDiscountTotal - this.retentionTotal;
    }

    @computed
    public get amountPayable(): number {
        return this.netClaim - this.model.previouslyPaid;
    }

    @computed
    public get vatTotal(): number {
        return this.amountPayable * VAT_PERCENTAGE;
    }

    @computed
    public get totalAmountPayable(): number {
        return this.amountPayable + this.vatTotal;
    }

    public async isValuationFormValid(): Promise<boolean> {
        let isValid = await this.isModelValid();

        for (const item of this.valuationItemViewModels) {
            isValid = (await item.isModelValid()) && isValid;
        }

        return isValid;
    }

    public save = async (): Promise<void> => {
        if (!(await this.isValuationFormValid())) {
            return;
        }

        let url = "";
        if (this.params.new) {
            url = AppUrls.Server.Valuation.CreateValuation.replace("{poId}", this.params.poId);
        } else {
            url = AppUrls.Server.Valuation.UpdateValuation.replace("{id}", this.params.valuationId);
        }

        this.setIsLoading(true);
        const apiResult = await this.Post<string>(url, {
            valuationNo: this.model.valuationNumber,
            rowVersion: this.model.rowVersion,
            mainContractorsDiscount: this.model.mainContractorsDiscount ?? 0,
            retention: this.model.retention ?? 0,
            valuationItems: this.valuationItemViewModels.map((item) => ({
                id: item.model.id,
                amountClaimed: item.model.amountClaimed,
            })),
        });

        if (apiResult.wasSuccessful) {
            this.history.push(AppUrls.Client.Project.IE.replace(":ieid", this.model.ieId) + "#val");
        }

        this.setIsLoading(false);
    };

    public sendForInternalApproval = async (): Promise<void> => {};

    public deleteValuation = async (): Promise<void> => {
        if (!this.params.new) {
            this.setIsLoading(true);
            const apiResult = await this.Post(AppUrls.Server.Valuation.DeleteValuation.replace("{id}", this.params.valuationId));
            this.setIsLoading(false);

            if (!apiResult.wasSuccessful) {
                return;
            }
        }

        this.history.push(AppUrls.Client.Project.IE.replace(":ieid", this.model.ieId) + "#val");
    };

    public async GetValuationDetailsAsync() {
        this.setIsLoading(true);
        let apiResult: ApiResult<ValuationDetailsDTO> | undefined;
        if (this.params.new) {
            apiResult = await this.Get<ValuationDetailsDTO>(AppUrls.Server.Valuation.GetNewValuationDetails.replace("{poId}", this.params.poId));
        } else {
            apiResult = await this.Get<ValuationDetailsDTO>(AppUrls.Server.Valuation.GetValuationDetailsById.replace("{id}", this.params.valuationId));
        }

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);

                runInAction(() => {
                    this.valuationItemViewModels.replace(
                        apiResult!.payload.valuationItems.map((item) => {
                            const model = new ValuationItemModel();
                            model.fromDto(item);
                            return new ValuationItemViewModel(model, this.model);
                        }),
                    );
                });
            } else {
                console.log(apiResult.errors);
            }
        }
        this.setIsLoading(false);
    }

    public async isFieldValid(fieldName: keyof FieldType<ValuationModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    beforeUpdate: undefined;
    afterUpdate: undefined;
}

type ValuationSection = {
    title: string;
    groups: ValuationGroup[];
    totals: {
        title: string;
        contractValue: number;
        includingMcd: number;
    };
};

type ValuationGroup = {
    id: string;
    name: string;
    items: ValuationItemViewModel[];
};
