import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, computed, observable } from "mobx";

import { ClientViewModel } from "Globals/ViewModels/ClientViewModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { IEAdministratorRolesModel } from "./IEAdministratorRolesModel";
import { RoleModel } from "./RoleModel";
import { UserModel } from "./UserModel";
import { IncomeAndExpenditureViewModel } from "./IncomeAndExpenditureViewModel";

export class IEAdministratorRolesViewModel extends ViewModelBase<IEAdministratorRolesModel> {
    // #region Constructors and Disposers

    constructor(addIEAdministrationRoleUserCallback: (ieAdministratorRolesModel: IEAdministratorRolesModel) => void) {
        super(new IEAdministratorRolesModel());
        this.setDecorators(IEAdministratorRolesModel);

        this.addIEAdministrationRoleUserCallback = addIEAdministrationRoleUserCallback;
    }

    // #endregion Constructors and Disposers

    // #region roles

    //private readonly CAT_DEBOUNCE_VALUE_MS = 200;

    @action
    public setRole = (value: RoleModel | null) => {
        this.model.roleId = value ? value.id : IEAdministratorRolesModel.DEFAULT_ROLEID;
        this.model.roleDisplayName = value ? value.displayName : IEAdministratorRolesModel.DEFAULT_ROLEDISPLAYNAME;

        this.setUser(null);
    };

    @computed
    private get validateRole(): ValidationResponse {
        const errorMessage = this.model.validateRoleId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion roles

    // #region users

    @action
    public setUser = (value: UserModel | null) => {
        this.model.userId = value ? value.id : IEAdministratorRolesModel.DEFAULT_USERID;
        this.model.userDisplayName = value ? value.displayName : IEAdministratorRolesModel.DEFAULT_USERDISPLAYNAME;
    };

    @computed
    private get validateUser(): ValidationResponse {
        const errorMessage = this.model.validateUserId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #endregion users

    // #region Properties

    //private activeIEId: IComputedValue<string | null>;

    public client = new ClientViewModel();

    public server = new ServerViewModel();

    // #endregion Properties

    // #region Client Actions

    @observable
    private addIEAdministrationRoleUserCallback: (ieAdministrationRoleUserModel: IEAdministratorRolesModel) => void;

    @action
    public add = () => {
        this.client.command(
            () => {
                return true;
            },
            () => {
                // Side-effect. Notify parent we have a valid order line.
                this.addIEAdministrationRoleUserCallback(this.model);

                // Reset the state ready for next time.
                this.reset();
            },
            this.isModelValid,
            "There was an error trying to add a user",
        );
    };

    @action
    public reset = () => {
        this.model.reset();
        this.client.reset();
    };

    // #endregion Client Actions

    // #region Server Actions

    // #endregion Server Actions

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<IEAdministratorRolesModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        if (this.client.IsSubmitted) {
            // Process the properties of the model that cannot be supported via
            // the use of decorators.
            switch (fieldName) {
                case "roleId": {
                    const result = this.validateRole;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }

                case "userId": {
                    const result = this.validateUser;

                    errorMessage = result.errorMessage;
                    isValid = result.isValid;
                    break;
                }
            }
        } else {
            // Do not validate if the properties of the model have not been
            // submitted.
            errorMessage = "";
            isValid = true;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
