import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import { action, computed, observable, observe, runInAction } from "mobx";

import { FilesModel } from "./FilesModel";
import { FileModel } from "../File/FileModel";
import { FileViewModel } from "../File/FileViewModel";

export class FilesViewModel extends ViewModelBase<FilesModel> {
    @observable
    public fileViewModels = observable<FileViewModel>([]);

    constructor(fileTypes = "", allowMultipleFiles = true) {
        super(new FilesModel());

        this.model.allowMultipleFiles = allowMultipleFiles;
        this.model.fileTypes = fileTypes;
    }

    /**
     * Disposes any resources.
     */
    public dispose = (): void => {
        this.filesObserverDispose?.();
    };

    // #region Properties

    public get allowMultipleFiles() {
        return this.model.allowMultipleFiles;
    }

    public get fileTypes() {
        return this.model.fileTypes;
    }

    public get errorMessage() {
        if (!this.model.validMultipleFiles) {
            return `Only one file is allowed`;
        }

        if (!this.model.validFileTypes) {
            return `The type of one or more files is not allowed`;
        }

        if (!this.model.insideUploadLimit) {
            return `The total file size should be less than ${FilesModel.MAX_FILESIZE_MB}`;
        }

        return "";
    }

    // #endregion Properties

    // #region Commands

    /**
     * Command to add one or more files to the file collection.
     */
    @action
    public add = (files: FileList | null) => {
        if (files) {
            const newFileModels: FileModel[] = [];

            for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                const fileModel = new FileModel();

                fileModel.file = files.item(fileIndex);
                fileModel.fileName = files.item(fileIndex)!.name;
                fileModel.fileSizeBytes = files.item(fileIndex)!.size;
                fileModel.mimeType = files.item(fileIndex)!.type;

                // If the local file is an image or video file, create an image from it
                // to display in the control.
                if (fileModel.isImage || fileModel.isVideo) {
                    let reader = new FileReader();

                    reader.addEventListener("load", () => runInAction(() => (fileModel.thumbnailFileUrl = URL.createObjectURL(fileModel.file!))), false);

                    reader.readAsDataURL(files.item(fileIndex)!);
                }

                // Guard against models that are folders. We do not have
                // code to support extract files from folders at the moment.
                // TODO: APM - Handle folders, then the guard can be removed.
                if (fileModel.fileSizeBytes > 0 && !isEmptyOrWhitespace(fileModel.mimeType)) {
                    newFileModels.push(fileModel);
                }
            }

            this.model.files.push(...newFileModels);
        }
    };

    @computed
    public get canAdd() {
        return true;
    }

    @action
    public remove = (file: FileModel) => {
        const fileToRemove = this.model.files.find((m) => m.KEY === file.KEY);

        if (fileToRemove) {
            this.model.files.remove(fileToRemove);

            // Store any removed files that have an id as knowledge of these
            // may be required by consumers of the component.
            if (!isEmptyOrWhitespace(fileToRemove.id)) {
                this.model.removedFiles.remove(fileToRemove);
            }
        }
    };

    @action
    public reset = () => {
        this.model.files.replace([]);
        this.model.removedFiles.replace([]);
        this.fileViewModels.replace([]);
    };

    // #endregion Commands

    // #region Supporting

    /**
     * An observer to listen to changes in the file model collection. Use this to create or remove
     * file viewmodels in response to changes in the file model collection.
     */
    private filesObserverDispose = observe(this.model.files, (fileChanges: any) => {
        for (const addedFile of fileChanges.added) {
            this.fileViewModels.push(new FileViewModel(addedFile, this.remove));
        }

        for (const removedFile of fileChanges.removed) {
            const fileViewModelToRemove = this.fileViewModels.find((vm) => vm.model.KEY === removedFile.KEY);

            if (fileViewModelToRemove) {
                this.fileViewModels.remove(fileViewModelToRemove);
            }
        }
    });

    // #endregion Supporting

    public isFilesModelValidAsync = async (): Promise<boolean> => {
        return Promise.resolve(isEmptyOrWhitespace(this.errorMessage) && this.model.files.length !== 0);
    };

    public isFieldValid(fieldName: keyof FieldType<FilesModel>, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
