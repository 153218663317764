import { action, computed, observable } from "mobx";
import { isEmptyOrWhitespace, ModelBase } from "@shoothill/core";
import { validateFourDecimalPlaces } from "Utils/Utils";

export class OrderLineModel extends ModelBase<OrderLineModel> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_MATERIALID = null;
    public static readonly DEFAULT_CATEGORYID = null;
    public static readonly DEFAULT_CATEGORYDISPLAYNAME = "";
    public static readonly DEFAULT_SUBCATEGORYID = null;
    public static readonly DEFAULT_SUBCATEGORYDISPLAYNAME = "";
    public static readonly DEFAULT_LINEDESCRIPTIONID = null;
    public static readonly DEFAULT_VARIATIONID = null;
    public static readonly DEFAULT_LINEDESCRIPTIONDISPLAYNAME = "";

    public static readonly DEFAULT_ITEMDESCRIPTION = "";
    public static readonly DEFAULT_UNITS = 0;
    public static readonly DEFAULT_UNITPRICE = 0;

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public materialId: string | null = OrderLineModel.DEFAULT_MATERIALID;

    @observable
    public categoryId: string | null = OrderLineModel.DEFAULT_CATEGORYID;

    @observable
    public categoryDisplayName: string = OrderLineModel.DEFAULT_CATEGORYDISPLAYNAME;

    @observable
    public subCategoryId: string | null = OrderLineModel.DEFAULT_SUBCATEGORYID;

    @observable
    public subCategoryDisplayName: string = OrderLineModel.DEFAULT_SUBCATEGORYDISPLAYNAME;

    @observable
    public lineDescriptionId: string | null = OrderLineModel.DEFAULT_LINEDESCRIPTIONID;

    @observable
    public variationId: string | null = OrderLineModel.DEFAULT_VARIATIONID;

    @observable
    public lineDescriptionDisplayName: string = OrderLineModel.DEFAULT_LINEDESCRIPTIONDISPLAYNAME;

    @observable
    public itemDescription: string = OrderLineModel.DEFAULT_ITEMDESCRIPTION;

    @observable
    public units: number = OrderLineModel.DEFAULT_UNITS;

    @observable
    public unitPrice: number = OrderLineModel.DEFAULT_UNITPRICE;

    // #endregion Properties

    // #region Actions

    @action
    public reset(fullReset: boolean = true): void {
        if (fullReset) {
            this.categoryId = OrderLineModel.DEFAULT_CATEGORYID;
            this.subCategoryId = OrderLineModel.DEFAULT_SUBCATEGORYID;
            this.lineDescriptionId = OrderLineModel.DEFAULT_LINEDESCRIPTIONID;
        }

        this.variationId = OrderLineModel.DEFAULT_VARIATIONID;
        this.materialId = OrderLineModel.DEFAULT_MATERIALID;
        this.itemDescription = OrderLineModel.DEFAULT_ITEMDESCRIPTION;
        this.units = OrderLineModel.DEFAULT_UNITS;
        this.unitPrice = OrderLineModel.DEFAULT_UNITPRICE;
    }

    @action
    fromDto(dto: IOrderLineDto): void {
        this.materialId = dto.materialId ?? OrderLineModel.DEFAULT_MATERIALID;
        this.categoryId = dto.categoryId ?? OrderLineModel.DEFAULT_CATEGORYID;
        this.categoryDisplayName = dto.categoryDisplayName ?? OrderLineModel.DEFAULT_CATEGORYDISPLAYNAME;
        this.subCategoryId = dto.subCategoryId ?? OrderLineModel.DEFAULT_SUBCATEGORYID;
        this.subCategoryDisplayName = dto.subCategoryDisplayName ?? OrderLineModel.DEFAULT_SUBCATEGORYDISPLAYNAME;
        this.lineDescriptionId = dto.lineDescriptionId ?? OrderLineModel.DEFAULT_LINEDESCRIPTIONID;
        this.variationId = dto.variationId ?? OrderLineModel.DEFAULT_VARIATIONID;
        this.lineDescriptionDisplayName = dto.lineDescriptionDisplayName ?? OrderLineModel.DEFAULT_LINEDESCRIPTIONDISPLAYNAME;
        this.itemDescription = dto.itemDescription ?? OrderLineModel.DEFAULT_ITEMDESCRIPTION;
        this.units = dto.units ?? OrderLineModel.DEFAULT_UNITS;
        this.unitPrice = dto.unitPrice ?? OrderLineModel.DEFAULT_UNITPRICE;
    }

    toDto(): IOrderLineDto {
        return {
            materialId: this.materialId,
            categoryId: this.categoryId,
            categoryDisplayName: this.categoryDisplayName,
            subCategoryId: this.subCategoryId,
            subCategoryDisplayName: this.subCategoryDisplayName,
            lineDescriptionId: this.lineDescriptionId,
            variationId: this.variationId,
            lineDescriptionDisplayName: this.lineDescriptionDisplayName,
            itemDescription: this.itemDescription,
            units: this.units,
            unitPrice: this.unitPrice,
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateCategoryId(): string {
        // RULES
        // A category must be defined.
        return this.categoryId === OrderLineModel.DEFAULT_CATEGORYID ? "Please select a category" : "";
    }

    @computed
    public get validateSubCategoryId(): string {
        // RULES
        // An sub-category must be defined.
        return this.subCategoryId === OrderLineModel.DEFAULT_SUBCATEGORYID ? "Please select a sub-category" : "";
    }

    @computed
    public get validateLineDescriptionId(): string {
        // RULES
        // A line description must be defined.
        return this.lineDescriptionId === OrderLineModel.DEFAULT_LINEDESCRIPTIONID ? "Please select a line description" : "";
    }

    @computed
    public get validateItemDescription(): string {
        // RULES
        // The description must not be null or whitespace.
        return isEmptyOrWhitespace(this.itemDescription) ? "Please provide a description" : "";
    }

    @computed
    public get validateUnits(): string {
        // RULES
        // The number of units must be greater than zero.
        return this.units === OrderLineModel.DEFAULT_UNITS || isNaN(this.units) ? "Please set the number of units" : "";
    }

    @computed
    public get validateUnitPrice(): string {
        // RULES
        if (this.unitPrice === OrderLineModel.DEFAULT_UNITPRICE || isNaN(this.unitPrice)) {
            return "Please set the unit price";
        } else if (!validateFourDecimalPlaces(this.unitPrice)) {
            return "No more than four decimal places";
        }

        return "";
    }

    public validateMaterialId(isStockOrderLine: boolean): string {
        // RULES
        switch (true) {
            // If this is a stock orderline, the material must be defined.
            case isStockOrderLine && isEmptyOrWhitespace(this.materialId):
                return "Please seelct an item";

            // If this is not a stock orderline, the material must be not defined.
            case !isStockOrderLine && !isEmptyOrWhitespace(this.materialId):
                return "An item cannot be selected for a non-stock orderline";

            default:
                return "";
        }
    }

    // #endregion Custom Validation
}

export interface IOrderLineDto {
    materialId: string | null;
    categoryId: string | null;
    categoryDisplayName: string;
    subCategoryId: string | null;
    subCategoryDisplayName: string;
    lineDescriptionId: string | null;
    variationId: string | null;
    lineDescriptionDisplayName: string;
    itemDescription: string;
    units: number;
    unitPrice: number;
}
