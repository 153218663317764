export class DrawingsItemViewModel {
    // #region Properties

    public name: string = "";
    public inlineUrl: string | null = null;
    public attachmentUrl: string | null = null;
    public autodeskVersionId: string | null = null;
    public drawingId: string | null = null;

    // #endregion Properties
}
