import { ModelBase, observable } from "@shoothill/core";
import { action } from "mobx";

export class ValuationItemModel extends ModelBase<ValuationItemModel, ValuationItemDTO> {
    @observable
    public id = "";

    @observable
    public itemDescription = "";

    @observable
    public groupId = "";

    @observable
    public ordinal = 0;

    @observable
    public contractValue = 0;

    @observable
    public valueAtLastClaim = 0;

    @observable
    public previousAmountClaimed = 0;

    @observable
    public amountClaimed = 0;

    @observable
    public claimPercentage: number | null = null;

    @action
    public fromDto(model: ValuationItemDTO): void {
        this.id = model.id;
        this.itemDescription = model.itemDescription;
        this.groupId = model.groupId;
        this.ordinal = model.ordinal;
        this.contractValue = model.contractValue;
        this.valueAtLastClaim = model.valueAtLastClaim;
        this.amountClaimed = model.amountClaimed;
        this.previousAmountClaimed = model.previousAmountClaimed;

        this.claimPercentage = model.amountClaimed > 0 ? Math.round((model.amountClaimed / model.contractValue) * 10000) / 100 : null;
    }

    toDto(model: ValuationItemModel): void {}
}

export type ValuationItemDTO = {
    id: string;
    itemDescription: string;
    groupId: string;
    ordinal: number;
    contractValue: number;
    valueAtLastClaim: number;
    amountClaimed: number;
    previousAmountClaimed: number;
};
