export class IssueItemViewModel {
    // #region Properties

    public id: string = "";
    public notes: string = "";
    public toAddresses: string | null = null;
    public ccAddresses: string | null = null;
    public createdBy: string = "";
    public createdDate: string = "";
    public isIssue: boolean = false;
    public drawings: IssueDrawingItemViewModel[] = [];
    public drawingsCount: number = 0;

    // #endregion Properties
}

export class IssueDrawingItemViewModel {
    // #region Properties

    public id: string = "";
    public name: string = "";
    public inlineUrl: string | null = null;
    public attachmentUrl: string | null = null;
    public revisionNumber: number = 0;
    public autodeskVersionId: string | null = null;

    // #endregion Properties
}
