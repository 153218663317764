import React from "react";
import { AdminRoutes } from "./Views/Admin/AdminRoutes";
import { AccountRoutes } from "./Views/Account/AccountRoutes";
import { CommonInjects } from "./Globals/CommonInjects";
import { AppUrls } from "./AppUrls";
import * as RouterUtil from "Utils/Routing";
import { DarwinLayout, PrivateRoute } from "Globals/Views";
import { StoresInstance } from "Globals/Stores";
import { NotFound } from "Globals/Views";
import { ApprovalRoutes } from "Views/Approval/ApprovalRoutes";
import { CentralRoutes } from "Views/Central/CentralRoutes";
import { InvoiceRoutes } from "Views/Invoice/InvoiceRoutes";
import { ProjectRoutes } from "Views/Project/ProjectRoutes";
import { PurchaseOrderRoutes } from "Views/PurchaseOrder/PurchaseOrderRoutes";
import { ReportRoutes } from "Views/Report/ReportRoutes";
import { StockRoutes } from "Views/Stock/StockRoutes";
import { SupplierRoutes } from "Views/Supplier/SupplierRoutes";
import { ApprovalListView } from "Views/Approval/ApprovalListView";
import { VariationRoutes } from "Views/Variation/VariationRoutes";
import { InductionRoutes } from "./Views/Induction/InductionRoutes";
import { Route } from "react-router";
import { InductionAdminRoutes } from "Views/Induction/Admin/InductionAdminRoutes";
import { ProjectListView } from "Views/Project/ProjectListView";
import { RoleTypeEnum } from "Globals/Stores/Domain/Admin/RoleStore";
import { observer, useObserver } from "mobx-react-lite";
import { SiteTabletProjectRoutes } from "./Views/Project/SiteTablet/SiteTabletProjectRoutes";
import { SiteAccessQRCodePDFTemplate } from "./Views/Project/SiteAccess/SiteAccessQRCodePDFTemplate";
import { ValuationRoutes } from "Views/Valuation/ValuationRoutes";

export const AppRoutes: React.FC = observer(() => {
    const accountStore = StoresInstance.Domain.AccountStore;
    function getRootComponent(): any {
        const isSiteManager: boolean = StoresInstance.Domain.AccountStore.isInRole(RoleTypeEnum.SiteManager);

        if (isSiteManager) {
            return <ProjectListView />;
        }

        return <ApprovalListView />;
    }

    function getRootLayout() {
        return DarwinLayout;
    }

    return (
        <>
            {/*
            I have added a component that will now accept a different layout per route.
            This line
                import { RouteWithLayout as Route } from "./Core/Components/RouteWithLayout";
            renames the usual route to use the new RouteWithLayout component. The default layout is called DefaultLayout in the Layout folder
            */}
            {/*             <Route exact path={AppUrls.Client.Home} component={HomeView} />
            <Route exact path={AppUrls.Client.Samples} layout={DefaultLayoutNoFooter} component={SamplesView} />

            <Route exact path={AppUrls.Client.Admin.LogViewer} component={LogViewer} />
            <Route exact path={AppUrls.Client.Error} component={Error} />
            <Route exact path={AppUrls.Client.PrivacyPolicy} component={PrivacyPolicyView} />
            <Route exact path={AppUrls.Client.Terms} component={TermsAndConditions} />
            <Route exact path={AppUrls.Client.Test} component={TestPage} />
 */}

            <PrivateRoute
                component={getRootComponent}
                exact
                isAllowed={() => {
                    return StoresInstance.Domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                path={AppUrls.Client.Home}
                layout={getRootLayout()}
            />
            <CommonInjects />
            <AccountRoutes />
            <Route exact path={AppUrls.Client.Project.QR} component={SiteAccessQRCodePDFTemplate} />
            <Route path={AppUrls.Client.Induction.Index} component={InductionRoutes} />

            {/*EN: Only show the routes below if the initial state has been loaded and they are logged in*/}
            {accountStore.InitialStateComplete && (
                <>
                    <ApprovalRoutes />
                    <AdminRoutes />
                    <CentralRoutes />
                    <InductionAdminRoutes />
                    <InvoiceRoutes />
                    <ProjectRoutes />
                    <SiteTabletProjectRoutes />
                    <PurchaseOrderRoutes />
                    <ReportRoutes />
                    <StockRoutes />
                    <SupplierRoutes />
                    <VariationRoutes />
                    <ValuationRoutes />
                </>
            )}
        </>
    );
});
