import { useObserver } from "mobx-react-lite";
import { IEValuationGridPOModel } from "../../IEmodels/IEValuationGridPOModel";
import { ToggleButton } from "../../IETables/IEGrid.Styles";

type Props = {
    po: IEValuationGridPOModel;
    currentActivePOIndex: number | null;
    rowEnable(index: number): void;
    rowIndex: number;
};

export const ValuationPORow = ({ po, currentActivePOIndex, rowEnable, rowIndex }: Props) => {
    return useObserver(() => (
        <li className="valuation-po">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ color: `${currentActivePOIndex === rowIndex ? "#0095DA" : ""}`, paddingLeft: "5px" }}>{po.poNumberFormatted}</span>
                {currentActivePOIndex === rowIndex ? (
                    <ToggleButton>
                        <span
                            className={"open"}
                            onClick={() => {
                                rowEnable(-1);
                            }}
                        >
                            &minus;
                        </span>
                    </ToggleButton>
                ) : (
                    <ToggleButton>
                        <span
                            className={"opcloseen"}
                            onClick={() => {
                                rowEnable(rowIndex);
                            }}
                        >
                            &#43;
                        </span>
                    </ToggleButton>
                )}
            </div>
            <div className="right">{po.supplier}</div>
            <div className="right"></div>
            <div className="right"></div>
            <div className="right"></div>
            <div className="right"></div>
            <div className="right"></div>
            <div className="right"></div>
        </li>
    ));
};
