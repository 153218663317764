import { observable, action, runInAction, computed } from "mobx";
import { ApiResult, FieldType, ViewModelBase } from "@shoothill/core";
import { IEModel, IEViewandRelatedRequest, IE_DTO } from "./IE.Model";
import { IEGridCategoryModel, IEGridModel } from "./IEmodels";
import { ITab } from "Components/Tabs/Tabs";
import { AppUrls } from "AppUrls";
import { IEGridItemViewModel, IEUpsertLineDTO } from "./IEmodels/IEGridItemViewModel";
import { StoresInstance } from "Globals/Stores";
import { IEViewViewModel } from "./IEmodels/IEViewViewModel";
import { VariationViewViewModel } from "./IEmodels/Variation/VariationViewViewModel";
import { ProjectModel } from "Views/PurchaseOrder/Form/Supporting/ProjectModel";
import { CentralListViewModel } from "Views/Central/CentralListViewModel";
import { CommercialViewModel } from "./Commercial.ViewModel";
import { csvAxiosRequestConfig, exportCSV } from "Utils/Utils";
import { IEValuationGridViewModel } from "./IEmodels/IEValuationGridViewModel";
import { ProjectGeneralViewModel } from "Views/Project/General/ProjectGeneralViewModel";

export class IEViewModel extends ViewModelBase<IEModel> {
    private static _instance: IEViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public constructor() {
        super(new IEModel(), false);
        this.setDecorators(IEModel);
    }

    @observable
    public workingId: string = "";

    @action
    public setWorkingId(val: string) {
        this.workingId = val;
    }

    @observable
    public projectId = "";

    @observable
    public projectTitle = "";

    @observable
    public projectStatusType = "DRAFT";

    @observable
    public ieGridViewModel: IEViewViewModel = new IEViewViewModel();

    @observable
    public variationGridViewModel: VariationViewViewModel = new VariationViewViewModel();

    @observable
    public clientProvisionalSumsGridViewModel: VariationViewViewModel = new VariationViewViewModel();

    @observable
    public valuationGridViewModel: IEValuationGridViewModel = new IEValuationGridViewModel();

    @computed
    public get getCanAddIELine() {
        if (ProjectGeneralViewModel.Instance.isDraft) {
            return StoresInstance.Domain.AccountStore.getCanAddIELineInDraft;
        }
        return StoresInstance.Domain.AccountStore.getCanAddIELine;
    }

    @computed
    public get getCanEditIELine() {
        if (ProjectGeneralViewModel.Instance.isDraft) {
            return StoresInstance.Domain.AccountStore.getCanEditIELineInDraft;
        }
        return StoresInstance.Domain.AccountStore.getCanEditIELine;
    }

    /**
     * Returns Master IE
     */
    @computed
    public get filterIEByMaster(): IEGridModel[] {
        return this.ieGridViewModel.model.ies.filter((ie) => ie.type === "MASTER");
    }

    @computed
    public get filterIEBySub(): IEGridModel[] {
        return this.ieGridViewModel.model.ies.filter((ie) => ie.type === "SUB" && ie.id !== this.workingId && ie.parentId === this.workingId);
    }

    @computed
    public get filterIEByVariant(): IEGridModel[] {
        return this.ieGridViewModel.model.ies.filter((ie) => ie.type === "VARIATION");
    }

    @computed
    public get filterIEById(): IEGridModel[] {
        return this.ieGridViewModel.model.ies.filter((ie) => ie.id === this.workingId);
    }

    /**
     * Returns Categories and Subs for IE
     */
    @computed
    public get categoriesAndRelated(): IEGridCategoryModel[] {
        const retArrs: IEGridCategoryModel[] = [];
        const ieArr = this.ieGridViewModel.model.ies.filter((ie) => ie.id === this.workingId);
        if (ieArr.length > 0) {
            for (const category of ieArr[0].categories) {
                retArrs.push(category);
            }
        }

        return retArrs;
    }

    @computed
    public get isSubIE(): boolean {
        let retVal = false;
        const ieArr = this.ieGridViewModel.model.ies.filter((ie) => ie.id === this.workingId);
        // should only be one record in ieArr
        if (ieArr.length > 0) {
            if (ieArr[0].type === "SUB") {
                retVal = true;
            }
        }
        return retVal;
    }

    @computed
    public get ieIsType(): string {
        let retVal = "";
        const ieArr = this.ieGridViewModel.model.ies.filter((ie) => ie.id === this.workingId);
        // should only be one record in ieArr
        if (ieArr.length > 0) {
            retVal = ieArr[0].type;
        }
        return retVal;
    }

    @computed
    public get returnIEName(): string {
        const ieArr = this.ieGridViewModel.model.ies.filter((ie) => ie.id === this.workingId);
        // should only be one record in ieArr
        if (ieArr.length > 0) {
            return ieArr[0].name;
        } else {
            return "";
        }
    }

    @computed
    public get returnCell(): number {
        const ieArr = this.ieGridViewModel.model.ies.filter((ie) => ie.id === this.workingId);
        if (ieArr.length > 0) {
            return ieArr[0].cell;
        } else {
            return -1;
        }
    }

    @computed
    public get returnIEDescription(): string {
        return IEGridItemViewModel.Instance.isCentral ? this.returnIEName : `${this.returnIEName}  - Cell ${this.returnCell}`;
    }

    @action
    public returnIENameByID = (val: string) => {
        const ieArr = this.ieGridViewModel.model.ies.filter((ie) => ie.id === val);
        // should only be one record in ieArr
        if (ieArr.length > 0) {
            return ieArr[0].name;
        } else {
            return "";
        }
    };

    @action
    public populateIEGridViewModels = (dto: IE_DTO) => {
        this.projectId = dto.projectId;
        this.projectTitle = dto.projectTitle;
        this.projectStatusType = dto.projectStatusType;

        this.ieGridViewModel.model.fromDto(dto.ieView);

        this.variationGridViewModel.model.fromDto(dto.variationView);
        this.clientProvisionalSumsGridViewModel.model.fromDto(dto.clientProvisionalSumsView);

        CentralListViewModel.Instance.centralProjects.replace(ProjectModel.fromDtos(dto.centralProjects));

        const matchingCentralProject: ProjectModel | undefined = CentralListViewModel.Instance.centralProjects.find((p) => p.id === this.projectId);

        if (matchingCentralProject) {
            CentralListViewModel.Instance.setCurrentCentralProject(matchingCentralProject, false);
        }
    };

    @action
    public apiGetIEView = async (val: string): Promise<void> => {
        this.reset();
        //  reset workingId
        this.workingId = val;
        const request: IEViewandRelatedRequest = {
            Id: val,
            fromDate: null,
            toDate: null,
        };
        let apiResult = await this.Post<IE_DTO>(AppUrls.Server.Projects.IncomeExpend.GetIEView, request);
        if (apiResult) {
            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload);
                this.populateIEGridViewModels(apiResult.payload);
                if (val !== CommercialViewModel.Instance.getActiveIE) {
                    CommercialViewModel.Instance.resetActiveGridData();
                    CommercialViewModel.Instance.setActiveProjectData(val, apiResult.payload.projectId);
                }
            } else {
                console.log(apiResult.errors);
            }
        }
    };

    @action
    public apiUpsertIEItemLine = async (upsertRequest: IEUpsertLineDTO): Promise<ApiResult<IE_DTO>> => {
        this.setIsLoading(true);
        let request: IEUpsertLineDTO = {
            category: upsertRequest.category,
            subcategory: upsertRequest.subcategory,
            ieItem: upsertRequest.ieItem,
        };
        let apiResult = await this.Post<IE_DTO>(AppUrls.Server.Projects.IncomeExpend.UpsertIELine, request);
        if (apiResult) {
            runInAction(() => {
                if (apiResult.wasSuccessful) {
                    this.populateIEGridViewModels(apiResult.payload);
                } else {
                    console.log(apiResult.errors);
                }
            });
        }
        this.setIsLoading(false);
        return apiResult;
    };
    // #endregion Save IE Line

    @action
    public generateCSV = async (val: string) => {
        this.setIsLoading(true);

        const request: IEViewandRelatedRequest = {
            Id: val,
            fromDate: null,
            toDate: null,
        };

        await exportCSV(AppUrls.Server.Projects.IncomeExpend.GenerateIEViewCSV, request, await this.getConfig(true, csvAxiosRequestConfig)).finally(() => this.setIsLoading(false));
    };

    @action
    public reset = () => {
        this.ieGridViewModel = new IEViewViewModel();
        this.variationGridViewModel = new VariationViewViewModel();
        this.clientProvisionalSumsGridViewModel = new VariationViewViewModel();
        this.projectId = "";
        this.projectTitle = "";
        this.projectStatusType = "DRAFT";
    };

    // #region Modal view status
    VARIATIONMODALSTATE = false;

    @observable
    public showVariationModal = this.VARIATIONMODALSTATE;

    @action
    public setVariationModal(val: boolean) {
        this.showVariationModal = val;
    }
    // #endregion Modal view status

    // #region Tabs view status
    @observable
    public TAB_IE = "ie";

    @observable
    public TAB_VAR = "var";

    @observable
    public TAB_CPS = "cps";

    @observable
    public TAB_REJECTEDVAR = "rejectedvars";

    @observable
    public TAB_PR = "pr";

    @observable
    public TAB_PO = "po";

    @observable
    public TAB_INV = "inv";

    @observable
    public TAB_REP = "rep";

    @observable
    public TAB_ADMIN = "admin";

    @observable
    public TAB_VAL = "val";

    @observable
    public tabDraftOptions: ITab[] = [{ key: this.TAB_IE, title: "I & E", hash: "ie" }];

    @observable
    public tabOptions: ITab[] = [
        { key: this.TAB_IE, title: "I & E", hash: "ie" },
        { key: this.TAB_VAR, title: "Variations", hash: "var" },
        { key: this.TAB_CPS, title: "Client provisional sums", hash: "cps" },
        { key: this.TAB_REJECTEDVAR, title: "Rejected CPS/Var", hash: "rejectedvars" },
        { key: this.TAB_PR, title: "Purchase requisitions", hash: "pr" },
        { key: this.TAB_PO, title: "Purchase orders", hash: "po" },
        { key: this.TAB_INV, title: "Invoices", hash: "inv" },
        { key: this.TAB_VAL, title: "Valuations", hash: "val" },
        // { key: this.TAB_REP, title: "Reports", hash: "rep" },
        // { key: this.TAB_ADMIN, title: "Reports", hash: "admin" },
    ];

    @computed
    public get returnTabOptions(): ITab[] {
        if (IEGridItemViewModel.Instance.isCentral) {
            if (StoresInstance.Domain.AccountStore.getCanViewCentralIEs) {
                return this.tabOptions.filter((o) => o.key !== this.TAB_VAR && o.key !== this.TAB_CPS);
            }

            return this.tabOptions.filter((o) => o.key !== this.TAB_VAR && o.key !== this.TAB_CPS && o.key !== this.TAB_IE);
        } else {
            if (this.projectStatusType === "DRAFT") {
                return this.tabDraftOptions;
            }
            return this.tabOptions;
        }
    }

    // #endregion Tabs view status

    public async isFieldValid(fieldName: keyof FieldType<IEModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
