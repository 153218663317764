import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { DrawingsItemViewModel } from ".";
import { AddDrawingViewModel } from "../AddDrawing";
import { HistoryViewModel } from "../History";
import { UpdateDrawingViewModel } from "../UpdateDrawing";
import { GenericIdWithDisplayName } from "Globals/Models/GenericIdWithDisplayName";
import { IssueItemViewModel } from "./IssueItemViewModel";

export class DrawingsViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();

    private outputId: string;
    private drawings = observable<DrawingsItemViewModel>([]);
    private issues = observable<IssueItemViewModel>([]);
    @observable
    public approverUsers = observable<GenericIdWithDisplayName>([]);

    constructor(outputId: string) {
        super({});

        this.outputId = outputId;

        // Load drawings.
        this.apiLoadDrawingsAsync();
    }

    // #region Properties

    @observable
    public outputTypeDisplayName: string = "";

    @computed
    public get displayName() {
        if (!isEmptyOrWhitespace(this.outputTypeDisplayName)) {
            return `Drawings for ${this.outputTypeDisplayName}`;
        }

        return "Drawings";
    }

    // #endregion Properties

    // #region Filtering

    @computed
    public get filteredDrawings(): DrawingsItemViewModel[] {
        return this.drawings.slice();
    }

    @computed
    public get filteredIssues(): IssueItemViewModel[] {
        return this.issues.slice();
    }

    // #endregion Filtering

    // #region Navigation

    public navigateBack = () => this.history.goBack();

    // #endregion Navigation

    // #region Add Drawing

    @observable
    public addDrawingViewModel: AddDrawingViewModel | null = null;

    @computed
    public get canDisplayAddDrawing() {
        return this.addDrawingViewModel !== null;
    }

    @action
    public displayAddDrawing = () => {
        this.addDrawingViewModel = new AddDrawingViewModel(this.outputId, this.closeAddDrawing, this.approverUsers);
    };

    @action
    public closeAddDrawing = (refreshPage: boolean) => {
        this.addDrawingViewModel?.filesViewModel.dispose();
        this.addDrawingViewModel = null;

        if (refreshPage) {
            this.apiLoadDrawingsAsync();
        }
    };

    // #endregion Add Drawing

    // #region Update Drawing

    @observable
    public updateDrawingViewModel: UpdateDrawingViewModel | null = null;

    @computed
    public get canDisplayUpdateDrawing() {
        return this.updateDrawingViewModel !== null;
    }

    @action
    public displayUpdateDrawing = (data: DrawingsItemViewModel) => {
        this.updateDrawingViewModel = new UpdateDrawingViewModel(data.id, this.closeUpdateDrawing, this.approverUsers);
    };

    @action
    public closeUpdateDrawing = (refreshPage: boolean) => {
        this.updateDrawingViewModel?.filesViewModel.dispose();
        this.updateDrawingViewModel = null;

        if (refreshPage) {
            this.apiLoadDrawingsAsync();
        }
    };

    // #endregion Update Drawing

    // #region History

    @observable
    public historyViewModel: HistoryViewModel | null = null;

    @computed
    public get canDisplayHistory() {
        return this.historyViewModel !== null;
    }

    @action
    public displayHistory = () => {
        this.historyViewModel = new HistoryViewModel(this.outputId, this.closeHistory);
    };

    @action
    public closeHistory = (refreshPage: boolean) => {
        this.historyViewModel = null;

        if (refreshPage) {
            // Nothing to do.
        }
    };

    // #endregion History

    // #region Issue details

    @observable
    public issueViewModel: IssueItemViewModel | null = null;

    @computed
    public get canDisplayIssue() {
        return this.issueViewModel !== null;
    }

    @action
    public displayIssue = (rowData: IssueItemViewModel) => {
        const issue = new IssueItemViewModel();

        issue.id = rowData.id;
        issue.notes = rowData.notes;
        issue.toAddresses = rowData.toAddresses;
        issue.ccAddresses = rowData.ccAddresses;
        issue.createdBy = rowData.createdBy;
        issue.createdDate = rowData.createdDate;
        issue.drawingsCount = rowData.drawingsCount;
        issue.isIssue = rowData.isIssue;
        issue.drawings = rowData.drawings;

        this.issueViewModel = issue;
    };

    @action
    public closeIssue = (refreshPage: boolean) => {
        this.issueViewModel = null;

        if (refreshPage) {
            // Nothing to do.
        }
    };

    // #endregion Issue details

    // #region Api Actions

    @action
    public apiLoadDrawingsAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.GetByOutputId.replace("{outputid}", this.outputId)),
            (result) => {
                runInAction(() => {
                    this.outputTypeDisplayName = result.related.outputTypeDisplayName;

                    this.drawings.replace(
                        result.secureDrawingResponses.map((dto: any) => {
                            const drawing = new DrawingsItemViewModel();

                            drawing.id = dto.id;
                            drawing.name = dto.name;
                            drawing.inlineUrl = dto.inlineUrl;
                            drawing.attachmentUrl = dto.attachmentUrl;
                            drawing.revisionNumber = dto.revisionNumber;
                            drawing.statusName = dto.statusName;
                            drawing.statusColor = dto.statusColor;
                            drawing.statusTextColor = dto.statusTextColor;
                            drawing.updateDate = dto.updateDate;
                            drawing.updateByName = dto.updateByName;
                            drawing.canReviseDrawing = dto.canReviseDrawing;
                            drawing.autodeskVersionId = dto.autodeskVersionId;

                            return drawing;
                        }),
                    );

                    this.issues.replace(
                        result.issues.map((dto: any) => {
                            const issue = new IssueItemViewModel();

                            issue.id = dto.id;
                            issue.notes = dto.notes;
                            issue.toAddresses = dto.toAddresses;
                            issue.ccAddresses = dto.ccAddresses;
                            issue.createdBy = dto.createdBy;
                            issue.createdDate = dto.createdDate;
                            issue.isIssue = dto.isIssue;
                            issue.drawingsCount = dto.drawingsCount;
                            issue.drawings = dto.drawings;

                            return issue;
                        }),
                    );

                    this.approverUsers.replace(GenericIdWithDisplayName.fromDtos(result.related.approverUsers));
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boilerplate
}
