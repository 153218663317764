import { AppUrls } from "AppUrls";
import { Stores, StoresContext, StoresInstance } from "Globals/Stores";
import { DarwinLayout, PrivateRoute } from "Globals/Views";
import { useContext } from "react";
import * as RouterUtil from "Utils/Routing";
import { ValuationView } from "./Form/ValuationView";

export function ValuationRoutes() {
    function getRootLayout() {
        return DarwinLayout;
    }
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path={[AppUrls.Client.Valuation.Add, AppUrls.Client.Valuation.View]}
                component={() => <ValuationView />}
                isAllowed={() => {
                    return store.Domain.AccountStore.IsLoggedIn && RouterUtil.requireNotSiteManagerLoggedIn(StoresInstance);
                }}
                layout={getRootLayout()}
            />
        </>
    );
}
