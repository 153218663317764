// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";

// Custom
import { IEGridViewCatSubDesc } from "../IETables/IEGridView.CatSubDesc";
import { IEGridViewSubs } from "../IETables/IEGridView.Subs";
import { IEGridViewVariants } from "../IETables/IEGridView.Variants";
import { IEGridTotals } from "../IETables/IEGridView.MasterTotals";
import { IEViewModel } from "../IE.ViewModel";
import { IEItemModalRequest } from "../IEmodels/IEGridModel";
import { IEGridItemModel } from "../IEmodels";
import { PackagesViewModel } from "../PackagesViewModel";
import { VariationGridViewCatSubDesc } from "../VariationTables/VariationGridView.CatSubDesc";
import { GridHeader, SubsGridView, SummaryGridView } from "../IETables/IEGrid.Styles";
import { CommercialViewModel } from "../Commercial.ViewModel";
import { PrimaryButton } from "Components/Buttons/Buttons";
import { DarwinTableToolbar } from "Globals/Styles/AppStyling";
import { VariationTypeEnum } from "Views/Variation/Form/VariationModel";
import { CPSGridViewCatSubDesc } from "../VariationTables/CPSGridView.CatSubDesc";
import { IEGridItemViewModel } from "../IEmodels/IEGridItemViewModel";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { formatCreditDebitClass, formatCreditDebitReverseClass, formatDebit } from "Utils/Utils";

// Styling & Images

interface ITableProps {
    IEid: string;
    viewModel: IEViewModel;
    packagesViewModel: PackagesViewModel;
    chosenId(id: string): void;
    rowToEdit(iemodel: IEGridItemModel, parent: string, packageItem: IEItemModalRequest): void;
    rowTitle(title: string | undefined): void;
}

export const IETab: React.FunctionComponent<ITableProps> = (props) => {
    const exportCSV = () => {
        props.viewModel.generateCSV(props.IEid);
    };

    return useObserver(() => (
        <div style={{ width: "100%", paddingTop: 22 }}>
            <DarwinTableToolbar>
                {props.viewModel && <PrimaryButton displayName={"Export CSV"} execute={exportCSV} fullWidth={false} canExecute={!props.viewModel.IsLoading} />}
            </DarwinTableToolbar>

            <GridHeader>
                <SummaryGridView>
                    <li className="heading">
                        <div>Category / Sub-category / Line Description</div>
                        <div>Income</div>
                        <div>Target cost</div>
                        <div>Margin %</div>
                        <div>Committed cost</div>
                        {!IEGridItemViewModel.Instance.isCentral && <div>Future spend</div>}
                        <div>Total expected spend</div>
                        <div>Variance</div>
                        <div>Risk/Opportunity</div>
                        <div></div>
                    </li>
                </SummaryGridView>
            </GridHeader>

            <IEGridViewCatSubDesc
                IEid={props.IEid}
                viewModel={props.viewModel}
                rowToEdit={(row, parent, iePackage) => {
                    props.rowToEdit(row, parent, iePackage);
                }}
                IEType={props.viewModel.ieIsType}
                commercialViewModel={CommercialViewModel.Instance}
                isReport={false}
            />

            <IEGridViewVariants
                chosenId={(i) => {
                    props.chosenId(i);
                }}
                parentId={props.IEid}
                viewModel={props.viewModel}
                packagesViewModel={props.packagesViewModel}
                IEid={props.IEid}
            />

            <IEGridViewSubs
                chosenId={(i) => {
                    props.chosenId(i);
                }}
                parentId={props.IEid}
                viewModel={props.viewModel}
                packagesViewModel={props.packagesViewModel}
                rowTitle={(i) => props.rowTitle(i)}
                IEid={props.IEid}
            />

            <IEGridTotals
                chosenId={(i) => {
                    props.chosenId(i);
                }}
                IEid={props.IEid}
                viewModel={props.viewModel}
                packagesViewModel={props.packagesViewModel}
            />

            <VariationGridViewCatSubDesc
                ieId={props.IEid}
                projectId={props.viewModel.projectId}
                variationViewViewModel={props.viewModel.variationGridViewModel}
                packagesViewModel={props.packagesViewModel}
                ieType={props.viewModel.ieIsType}
                variationType={VariationTypeEnum.Variation}
                variationTypeName="Variations"
            />

            <CPSGridViewCatSubDesc
                ieId={props.IEid}
                projectId={props.viewModel.projectId}
                variationViewViewModel={props.viewModel.clientProvisionalSumsGridViewModel}
                packagesViewModel={props.packagesViewModel}
                ieType={props.viewModel.ieIsType}
                variationType={VariationTypeEnum.ClientProvisionalSum}
                variationTypeName="Client Provisional Sums"
            />
            <SummaryGridView>
                <li className="ie" style={{ borderTop: "solid 1px #dddddd" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ paddingLeft: "5px" }}>Grand total</span>
                    </div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(props.viewModel.model.income))}</div>
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(props.viewModel.model.estimateCost))}</div>
                    <div className="right">{props.viewModel.model.margin}</div>
                    <div className={"right" + formatDebit(props.viewModel.model.committedCost)}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(props.viewModel.model.committedCost))}
                    </div>
                    {!IEGridItemViewModel.Instance.isCentral && <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(props.viewModel.model.futureSpend))}</div>}
                    <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(props.viewModel.model.totalExpectedSpend))}</div>
                    <div className={"right" + formatCreditDebitClass(props.viewModel.model.variance)}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(props.viewModel.model.variance))}
                    </div>
                    <div className={"right" + formatCreditDebitReverseClass(props.viewModel.model.roValue)}>
                        {formatCurrencyForGrid(formatCurrencyNoSign(props.viewModel.model.roValue))}
                    </div>
                    <div className="right" style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}></div>
                </li>
            </SummaryGridView>
        </div>
    ));
};
