// Libraries
import React from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "@shoothill/core";

// Custom
import { AppUrls } from "AppUrls";
import { formatCurrencyForGrid, formatCurrencyNoSign } from "Utils/Format";
import { PurchaseOrderListItemModelDTO } from "../../IEmodels";

// Views

// Styling & Images
import MoneySVG from "../../../../../Content/Money.svg";
import { IEPOItemTitle } from "../VariationGrid.Styles";
import { POModal } from "Components/POModal/POModal";

interface ITableProps {
    ieId: string;
    itemModel: PurchaseOrderListItemModelDTO;
    rowIndex: number;
    ieLineId: string;
    isLoading: boolean;
}

export const VariationPurchaseOrderItem: React.FunctionComponent<ITableProps> = (props) => {
    const [showPOModal, setShowPOModal] = React.useState<boolean>(false);

    const handleShowPOModalChange = (val: boolean) => {
        setShowPOModal(val);
    };

    return useObserver(() => (
        <>
            <li className="item" key={"purreq_" + props.rowIndex} style={{ cursor: "pointer" }} onClick={() => handleShowPOModalChange(true)}>
                <IEPOItemTitle>
                    <img src={MoneySVG}></img>
                    <div>
                        <span style={{ marginLeft: "10px" }}>{props.itemModel.formattedPONumber}</span>
                        <span className="variation-row-name variation-po-name" style={{ marginLeft: "10px" }} title={props.itemModel.itemDescription}>
                            {props.itemModel.itemDescription}
                        </span>
                    </div>
                </IEPOItemTitle>
                <div></div>
                <div></div>
                <div></div>
                <div className="right">{formatCurrencyForGrid(formatCurrencyNoSign(props.itemModel.committedCost))}</div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </li>

            <POModal onClose={() => handleShowPOModalChange(false)} open={showPOModal} title="Purchase order" isLoading={props.isLoading} poId={props.itemModel.id} />
        </>
    ));
};
