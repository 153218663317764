import { ModelBase, observable } from "@shoothill/core";
import { action, computed, runInAction } from "mobx";
export class AddEditResponseModel extends ModelBase<AddEditResponseModel> {
    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_PROJECT_ID = "";
    public static readonly DEFAULT_RFI_ID = "";
    public static readonly DEFAULT_COMMENT = "";
    public static readonly DEFAULT_CREATED_DATE = null;
    public static readonly DEFAULT_RECEIVED_DATE = null;
    public static readonly DEFAULT_CREATED_BY_USER_ID = "";
    public static readonly DEFAULT_IS_DELETED = false;
    public static readonly DEFAULT_FROM_TEXT = "";
    public static readonly DEFAULT_STATUS_ID = "";
    public static readonly DEFAULT_RFIRESPONSEDOCUMENTS = [];

    @observable
    public id: string | null = AddEditResponseModel.DEFAULT_ID;

    @observable
    public projectId: string = AddEditResponseModel.DEFAULT_PROJECT_ID;

    @observable
    public rFIId: string = AddEditResponseModel.DEFAULT_RFI_ID;

    @observable
    public comment: string = AddEditResponseModel.DEFAULT_COMMENT;

    @observable
    public receivedDate: string | null = AddEditResponseModel.DEFAULT_RECEIVED_DATE;

    @observable
    public createdDate: string | null = AddEditResponseModel.DEFAULT_CREATED_DATE;

    @observable
    public createdByUserId: string | null = AddEditResponseModel.DEFAULT_CREATED_BY_USER_ID;

    @observable
    isDeleted: boolean = AddEditResponseModel.DEFAULT_IS_DELETED;

    @observable
    fromText: string = AddEditResponseModel.DEFAULT_FROM_TEXT;

    @observable
    statusId: string = AddEditResponseModel.DEFAULT_STATUS_ID;

    @observable
    public rfiResponseDocuments: RFIResponseDocumentDTO[] = AddEditResponseModel.DEFAULT_RFIRESPONSEDOCUMENTS;

    @computed
    public get hasId(): boolean {
        return this.id !== null && this.id !== undefined && this.id !== "";
    }

    @action
    public fromDto(dto: UpsertAddEditResponseResponseDTO): void {
        let model = dto.rfiResponse;

        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }

        this.rfiResponseDocuments = dto.rfiResponseDocuments;
    }

    @action
    public toDto() {
        const rFIResponse: AddEditResponseDTO = {
            id: this.id,
            projectId: this.projectId,
            rFIId: this.rFIId,
            comment: this.comment,
            receivedDate: this.receivedDate,
            createdByUserId: this.createdByUserId,
            createdDate: this.createdDate,
            isDeleted: this.isDeleted,
            fromText: this.fromText,
            statusId: this.statusId,
            statusName: "",
        };

        let documentsToAdd: RFIResponseDocumentDTO[] = this.rfiResponseDocuments.slice();

        runInAction(() => {
            // Remove the temp ids before upserting.
            for (let i = 0; i < documentsToAdd.length; i++) {
                if (documentsToAdd[i].id !== null && documentsToAdd[i].id!.length < 10) {
                    documentsToAdd[i].id = null;
                }
            }
        });

        // Don't send deleted documents if they don't already exist on the server
        const model: UpsertAddEditResponseRequestDTO = {
            rfiResponse: rFIResponse,
            rfiResponseDocuments: documentsToAdd.filter((d) => !(d.id === null && d.isDeleted === true)),
        };

        return model;
    }

    @action
    public reset = () => {
        this.id = AddEditResponseModel.DEFAULT_ID;
        this.projectId = AddEditResponseModel.DEFAULT_PROJECT_ID;
        this.rFIId = AddEditResponseModel.DEFAULT_RFI_ID;
        this.comment = AddEditResponseModel.DEFAULT_COMMENT;
        this.receivedDate = AddEditResponseModel.DEFAULT_RECEIVED_DATE;
        this.rfiResponseDocuments = AddEditResponseModel.DEFAULT_RFIRESPONSEDOCUMENTS;
    };
}

export interface AddEditResponseDTO {
    id: string | null;
    projectId: string;
    rFIId: string;
    comment: string | null;
    receivedDate: string | null;
    createdByUserId: string | null;
    createdDate: string | null;
    isDeleted: boolean;
    fromText: string;
    statusId: string;
    statusName: string;
}

export interface UpsertAddEditResponseRequestDTO {
    rfiResponse: AddEditResponseDTO;
    rfiResponseDocuments: RFIResponseDocumentDTO[];
}

export interface UpsertAddEditResponseResponseDTO {
    rfiResponse: AddEditResponseDTO;
    rfiResponseDocuments: RFIResponseDocumentDTO[];
}

export interface RFIResponseRequestDTO {
    id: string | null;
}

export interface RFIResponseDocumentDTO {
    id: string | null;
    fileName: string;
    url: string;
    isDeleted: boolean;
}
