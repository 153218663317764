import { ViewModelBase } from "@shoothill/core";
import { IEValuationGridModel, IEValuationGridModelDTO } from "./IEValuationGridModel";
import { AppUrls } from "AppUrls";

export class IEValuationGridViewModel extends ViewModelBase<IEValuationGridModel> {
    constructor() {
        super(new IEValuationGridModel(), false);
    }

    public async GetValuationGrid(ieId: string) {
        this.setIsLoading(true);
        const apiResult = await this.Get<IEValuationGridModelDTO>(AppUrls.Server.Valuation.GetValuationGrid.replace("{ieId}", ieId));

        if (apiResult.wasSuccessful) {
            this.model.fromDto(apiResult.payload);
        }

        this.setIsLoading(false);
    }

    isFieldValid(fieldName: any, value: any): Promise<boolean> {
        return Promise.resolve(true);
    }

    beforeUpdate: undefined;
    afterUpdate: undefined;
}
