import { observer } from "mobx-react-lite";
import React from "react";

import { BaseModal } from "Components/Modal/BaseModal";
import { SnackBar } from "Components/SnackBar/SnackBar";
import { CancelButton } from "Globals/Styles/Control/Buttons";
import { AddPlanOfWorkModalStyle } from "Views/Project/Modals/Modal.Styles";
import { DrawingRow, DrawingTable, DrawingsViewModel } from ".";
import { DrawingsItemViewModel } from "./DrawingsItemViewModel";
import { DownloadDrawingView } from "../../Shared";

interface IProps {
    viewModel: DrawingsViewModel;
}

export const DrawingsView: React.FC<IProps> = observer((props) => {
    const viewModel = props.viewModel;

    return (
        <BaseModal
            open={viewModel !== null}
            onClose={viewModel.cancel}
            title="Drawings"
            PaperComponent={AddPlanOfWorkModalStyle}
            actions={
                <React.Fragment>
                    <CancelButton onClick={viewModel.cancel}>Close</CancelButton>
                </React.Fragment>
            }
        >
            <DrawingTable>
                {viewModel.filterdDrawings.map((rowData: DrawingsItemViewModel) => {
                    return (
                        <DrawingRow>
                            <DownloadDrawingView
                                fileName={rowData.name!}
                                attachmentUrl={rowData.attachmentUrl!}
                                inlineUrl={rowData.inlineUrl!}
                                autodeskVersionId={rowData.autodeskVersionId}
                                drawingId={rowData.drawingId}
                            />
                        </DrawingRow>
                    );
                })}
            </DrawingTable>
            <SnackBar
                messageText={viewModel.snackMessage}
                messageType={viewModel.snackType}
                active={viewModel.snackbarState}
                closeOption={() => viewModel.setSnackbarState(false)}
                autoHideDuration={5000}
            />
        </BaseModal>
    );
});
