import styled from "styled-components";

export const GridViewBase = styled.ul`
    list-style: none;
    margin: 0 10px 0;

    li {
        width: 100%;
        border-bottom: solid 1px #dddddd;
        border-top: none;
        border-color: #dddddd;
        border-width: 1px;
        display: grid;
        grid-template-columns: minmax(520px, 1fr) repeat(auto-fit, minmax(100px, 1fr));
        gap: 0px 0px;
        grid-auto-flow: column;

        > div {
            padding: 0 20px;
            font-size: 12px;
            font-weight: bold;
            border-right: solid 1px #dddddd;

            &.grand-total {
                border: none;

                &.filled {
                    border-left: solid 1px #dddddd;
                    border-right: solid 1px #dddddd;
                }
            }
        }

        > div:last-of-type {
            border-right: none;
        }
    }

    .heading {
        background-color: #ced4da;
    }

    .heading,
    .item {
        height: 50px;
        line-height: 1.25;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            &.left {
                text-align: left;
                justify-content: flex-start;
            }

            &.right {
                text-align: right;
                justify-content: flex-end;
            }
        }
    }
`;
