import React, { useState } from "react";
import {
    FolderNavigatorBreadcrumb,
    FolderNavigatorBreadcrumbLink,
    FolderNavigatorButton,
    FolderNavigatorContainer,
    FolderNavigatorList,
    FolderNavigatorListItem,
    FolderNavigatorLoadingText,
} from "./FolderNavigator.styles";
import { Folder, ProjectTopFolder } from "Views/Project/ProjectTrackers/OutputTracker/Drawing/AddDrawing/AddDrawingViewModel";
import FolderIcon from "@material-ui/icons/Folder";
import DescriptionIcon from "@material-ui/icons/Description";
import { observer } from "mobx-react-lite";

interface FolderNavigatorProps {
    rootFolders: ProjectTopFolder[];
    folders: Folder[];
    apiLoadCloudFolderContentsAsync: (projectId: string, folderId: string) => Promise<void>;
    apiLoadCloudItemAsync: (projectId: string, itemId: string) => Promise<void>;
    isLoading: boolean;
    projectId: string;
}

const FolderNavigator: React.FC<FolderNavigatorProps> = observer(({ rootFolders, folders, apiLoadCloudFolderContentsAsync, apiLoadCloudItemAsync, isLoading, projectId }) => {
    const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
    const [path, setPath] = useState<Array<{ id: string; name: string }>>([]);

    const handleFolderClick = async (folder: { id: string; name: string }) => {
        setPath((prev) => [...prev, { id: folder.id, name: folder.name }]);
        setCurrentFolderId(folder.id);
        if (folder.id) {
            await apiLoadCloudFolderContentsAsync(projectId, folder.id);
        }
    };

    const handleBreadcrumbClick = async (index: number) => {
        if (!isLoading) {
            const newPath = path.slice(0, index);
            setPath(newPath);
            const folderId = index === 0 ? null : newPath[index - 1].id;
            setCurrentFolderId(folderId);
            if (folderId) {
                await apiLoadCloudFolderContentsAsync(projectId, folderId);
            }
        }
    };

    const handleDocumentClick = async (itemId: string) => {
        if (!isLoading) {
            await apiLoadCloudItemAsync(projectId, itemId);
        }
    };

    const currentContents =
        currentFolderId === null
            ? rootFolders // At the root level, display root folders
            : folders.filter((folder) => folder.parentId === currentFolderId);

    return (
        <FolderNavigatorContainer>
            <FolderNavigatorBreadcrumb>
                <FolderNavigatorBreadcrumbLink onClick={() => handleBreadcrumbClick(0)}>Root</FolderNavigatorBreadcrumbLink>
                {path.map((crumb, index) => (
                    <span key={crumb.id}>
                        {" / "}
                        <FolderNavigatorBreadcrumbLink onClick={() => handleBreadcrumbClick(index + 1)}>{crumb.name}</FolderNavigatorBreadcrumbLink>
                    </span>
                ))}
            </FolderNavigatorBreadcrumb>

            {isLoading ? (
                <FolderNavigatorLoadingText>Loading...</FolderNavigatorLoadingText>
            ) : (
                <FolderNavigatorList>
                    {currentContents.map((item) => (
                        <FolderNavigatorListItem key={item.id}>
                            {item.type === "Folders" ? (
                                <FolderNavigatorButton onClick={() => handleFolderClick(item)}>
                                    <FolderIcon />
                                    <span></span>
                                    {item.displayName}
                                </FolderNavigatorButton>
                            ) : (
                                <FolderNavigatorButton onClick={() => handleDocumentClick(item.id)}>
                                    <DescriptionIcon />
                                    <span></span>
                                    {item.displayName}
                                </FolderNavigatorButton>
                            )}
                        </FolderNavigatorListItem>
                    ))}
                    {currentContents.length === 0 && <div>No items.</div>}
                </FolderNavigatorList>
            )}
        </FolderNavigatorContainer>
    );
});

export default FolderNavigator;
