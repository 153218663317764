import { isEmptyOrWhitespace } from "@shoothill/core";
import { action, observable } from "mobx";

export class OutputsItemViewModel {
    // #region Properties

    public id: string = "";
    public name: string = "";
    public requiredByDate: string = "";
    public categoryId: string = "";
    public categoryName: string = "";
    public statusName: string = "";
    public statusColor: string = "";
    public statusTextColor: string = "";
    public drawingFileCount: number = 0;
    public drawingFileName: string | null = null;
    public drawingInlineUrl: string | null = null;
    public drawingAttachmentUrl: string | null = null;
    public autodeskVersionId: string | null = null;
    public drawingId: string | null = null;
    public drawingFileNames: string[] = [];

    @observable
    public issue: boolean = false;

    // #endrgion Properties

    public get canDisplayNoFiles() {
        return this.drawingFileCount === 0;
    }

    public get canDisplaySingleFile() {
        return this.drawingFileCount === 1;
    }

    public get canDisplayMultipleFiles() {
        return this.drawingFileCount > 1;
    }

    // #region Filtering

    public matchesFilter = (filterString: string): boolean => {
        if (isEmptyOrWhitespace(filterString)) {
            return true;
        }

        const filterStringUpperCase = filterString.toUpperCase();

        // Check drawing names.
        const matches = this.drawingFileNames.some((drawingFileName) => {
            return drawingFileName.toUpperCase().includes(filterStringUpperCase);
        });

        return matches || this.name.toUpperCase().includes(filterStringUpperCase);
    };

    // #endregion Filtering

    // #region Issue

    @action
    public toggleIssue = () => {
        this.issue = !this.issue;
    };

    // #endregion Issue
}
