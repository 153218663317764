import { FieldType, ViewModelBase } from "@shoothill/core";
import { ValuationItemModel } from "./ValuationItemModel";
import { ValuationModel } from "./ValuationModel";
import { computed, action } from "mobx";

export class ValuationItemViewModel extends ViewModelBase<ValuationItemModel> {
    private readonly valuationModel: ValuationModel;

    constructor(model: ValuationItemModel, valuationModel: ValuationModel) {
        super(model, false);
        this.setDecorators(ValuationItemModel);

        this.valuationModel = valuationModel;
    }

    @computed
    public get includingMcd(): number {
        return this.model.contractValue * (1 - (this.valuationModel.mainContractorsDiscount ?? 0) / 100);
    }

    @computed
    public get amountClaimedIncludingMcd(): number {
        return this.model.amountClaimed * (1 - (this.valuationModel.mainContractorsDiscount ?? 0) / 100);
    }

    @action
    public setClaimPercentage(value: number | null): void {
        this.setValue("claimPercentage", value);
        this.setValue("amountClaimed", value !== null ? this.model.contractValue * (value / 100) : 0);
    }

    public async isFieldValid(fieldName: keyof FieldType<ValuationItemModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "amountClaimed":
                if (value > this.model.contractValue) {
                    isValid = false;
                    errorMessage = "Cannot claim more than the contract value";
                } else if (value < this.model.previousAmountClaimed) {
                    isValid = false;
                    errorMessage = "Cannot claim less than the previous amount claimed";
                }
                break;
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    beforeUpdate: undefined;
    afterUpdate: undefined;
}
