import { action, computed, observable } from "mobx";
import { ModelBase } from "@shoothill/core";
import { ApprovalPanelBaseDTO } from "Globals/Models/ApprovalPanelModelBase";
import { ApprovalHistoryDTO } from "Components/ApprovalHistory/ApprovalHistoryDTO";
import { RequisitionPOStatus } from "Views/Approval/PurchaseListViewModel";
import { DrawingDocumentSource } from "../AddDrawing/AddDrawingModel";

export class ApproveDrawingModel extends ModelBase<ApproveDrawingModel, DrawingUpdateResponseDTO> {
    // #region Constructors and Disposers
    // #endregion Constructors and Disposers

    // #region Constants and Defaults

    public static readonly DEFAULT_ID = null;
    public static readonly DEFAULT_DESCRIPTION = null;
    public static readonly DEFAULT_FILENAME = "";
    public static readonly DEFAULT_URL = null;
    public static readonly DEFAULT_APPROVERUSERID = null;
    public static readonly DEFAULT_AUTODESKVERSIONID = null;
    public static readonly DEFAULT_PROJECTID = "";
    public static readonly DEFAULT_ROWVERSION = null;
    public static readonly DEFAULT_REQUESTERNOTE = "";

    // #endregion Constants and Defaults

    // #region Properties

    @observable
    public id: string | null = ApproveDrawingModel.DEFAULT_ID;

    @observable
    public description: string | null = ApproveDrawingModel.DEFAULT_DESCRIPTION;

    @observable
    public name: string = ApproveDrawingModel.DEFAULT_FILENAME;

    @observable
    public inlineUrl: string | null = ApproveDrawingModel.DEFAULT_URL;

    @observable
    public attachmentUrl: string | null = ApproveDrawingModel.DEFAULT_URL;

    @observable
    public autodeskVersionId: string | null = ApproveDrawingModel.DEFAULT_AUTODESKVERSIONID;

    @observable
    public autodeskItemId: string | null = ApproveDrawingModel.DEFAULT_AUTODESKVERSIONID;

    @observable
    public projectId: string = ApproveDrawingModel.DEFAULT_PROJECTID;

    @observable
    public approverUserId: string | null = ApproveDrawingModel.DEFAULT_APPROVERUSERID;

    @observable
    public rowVersion: string | null = ApproveDrawingModel.DEFAULT_ROWVERSION;

    @observable
    public requesterNotes: string = ApproveDrawingModel.DEFAULT_REQUESTERNOTE;

    @observable
    public documentSource: DrawingDocumentSource | null = null;

    // #endregion Properties

    // #region Actions

    @action
    public reset = () => {
        this.id = ApproveDrawingModel.DEFAULT_ID;
        this.description = ApproveDrawingModel.DEFAULT_DESCRIPTION;
        this.name = ApproveDrawingModel.DEFAULT_FILENAME;
        this.inlineUrl = ApproveDrawingModel.DEFAULT_URL;
        this.attachmentUrl = ApproveDrawingModel.DEFAULT_URL;
        this.autodeskVersionId = ApproveDrawingModel.DEFAULT_AUTODESKVERSIONID;
        this.autodeskItemId = ApproveDrawingModel.DEFAULT_AUTODESKVERSIONID;
        this.projectId = ApproveDrawingModel.DEFAULT_PROJECTID;
        this.approverUserId = ApproveDrawingModel.DEFAULT_APPROVERUSERID;
        this.rowVersion = ApproveDrawingModel.DEFAULT_ROWVERSION;
        this.requesterNotes = ApproveDrawingModel.DEFAULT_REQUESTERNOTE;
    };

    @action
    public fromDto(dto: DrawingUpdateResponseDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in dto) {
            if (dto.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(dto[key]);
                } else {
                    this[key] = dto[key];
                }
            }
        }
    }

    public toDto(): DrawingUpdateRequestDTO {
        return {
            id: this.id,
            description: this.description,
            approverUserId: this.approverUserId,
            rowVersion: this.rowVersion,
            files: [],
        };
    }

    // #endregion Actions

    // #region Custom Validation

    @computed
    public get validateDescription(): string {
        // RULES
        // Must be selected.
        return this.description === null || this.description === undefined ? "Please add a description" : "";
    }
}

export interface DrawingUpdateRequestDTO {
    id: string | null;
    description: string | null;
    approverUserId: string | null;
    rowVersion: string | null;
    files: any[];
}

export interface DrawingUpdateResponseDTO extends DrawingUpdateRequestDTO {
    id: string | null;
    name: string;
    inlineUrl: string;
    attachmentUrl: string;
    autodeskItemId: string | null;
    requesterNotes: string | null;
    autodeskVersionId: string | null;
    projectId: string;
}

export interface DrawingAndRelatedResponseDTO {
    drawing: DrawingUpdateResponseDTO;
    approvalPanel: ApprovalPanelBaseDTO;
    requisitionPOStatuses: RequisitionPOStatus[];
    approvalHistory: ApprovalHistoryDTO[];
}

export interface DrawingStatusDTO {
    id: string | null;
    displayName: string;
    ordinal: number;
    type: DrawingStatusEnum;
    isDeleted: boolean;
    requisitionPOStatusId: string | null;
}

export enum DrawingStatusEnum {
    SubmittedForApproval = "SUBMITTEDFORAPPROVAL",
    AmendRequired = "AMENDMENTREQUIRED",
    Approved = "APPROVED",
    Rejected = "REJECTED",
}
