import { ModelBase, observable } from "@shoothill/core";
import { IEValuationGridPOModel, IEValuationGridPOModelDTO } from "./IEValuationGridPOModel";
import { action } from "mobx";

export class IEValuationGridModel extends ModelBase<IEValuationGridModel, IEValuationGridModelDTO> {
    @observable
    public purchaseOrders = observable<IEValuationGridPOModel>([]);

    @action
    fromDto(model: IEValuationGridModelDTO): void {
        this.purchaseOrders.replace(
            model.map((po) => {
                const model = new IEValuationGridPOModel();
                model.fromDto(po);
                return model;
            }),
        );
    }

    toDto(model: IEValuationGridModel): void {}
}

export type IEValuationGridModelDTO = IEValuationGridPOModelDTO[];
