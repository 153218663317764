import { ModelBase } from "@shoothill/core";
import { observable } from "mobx";

export class IEValuationModalModel extends ModelBase<IEValuationModalModel> {
    @observable
    public supplierId: string | null = null;

    @observable
    public purchaseOrderId: string | null = null;

    fromDto(model: any): void {}
    toDto(model: IEValuationModalModel): void {}
}
