import styled from "styled-components";

export const FolderNavigatorContainer = styled.div`
    font-family: Arial, sans-serif;
    max-width: 600px;
    margin: 0 auto;
`;

export const FolderNavigatorBreadcrumb = styled.div`
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
`;

export const FolderNavigatorBreadcrumbLink = styled.span`
    cursor: pointer;
    color: #007bff;
    user-select: none;
    &:hover {
        text-decoration: underline;
    }
`;

export const FolderNavigatorList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const FolderNavigatorListItem = styled.li`
    margin: 5px 0;
`;

export const FolderNavigatorButton = styled.button`
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
    padding: 0;
    user-select: none;
    text-align: left;
    &:hover {
        text-decoration: underline;
    }

    > span {
        margin-right: 10px;
        width: auto !important;
    }
`;

export const FolderNavigatorLoadingText = styled.p`
    font-size: 16px;
    color: #666;
`;
