import { ApiResult, FieldType, isEmptyOrWhitespace, KeyValuePair, ViewModelBase } from "@shoothill/core";
import { PurchaseOrderModel } from "./PurchaseOrder.Model";

export class PurchaseOrderViewModel extends ViewModelBase<PurchaseOrderModel> {
    public constructor() {
        super(new PurchaseOrderModel(), false);
        this.setDecorators(PurchaseOrderModel);

        //this.model = item;
    }

    public matchesFilter = (filterString: string): boolean => {
        if (isEmptyOrWhitespace(filterString)) {
            return true;
        }

        const filterStringUpperCase = filterString.toUpperCase();

        return (
            this.model.itemDescription.toUpperCase().includes(filterStringUpperCase) ||
            this.model.raisedBy.toUpperCase().includes(filterStringUpperCase) ||
            this.model.supplierName?.toUpperCase().includes(filterStringUpperCase) ||
            this.getFormattedPONumber.toString().toUpperCase().includes(filterStringUpperCase)
        );
    };

    public get getFormattedPONumber(): string {
        return this.model.formattedPONumber ? this.model.formattedPONumber : "";
    }

    public async isFieldValid(fieldName: keyof FieldType<PurchaseOrderModel>, value: any): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
