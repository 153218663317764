import { debounce } from "@material-ui/core";
import { ViewModelBase, isEmptyOrWhitespace } from "@shoothill/core";
import { action, computed, observable, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { ProjectGeneralViewModel } from "Views/Project/General/ProjectGeneralViewModel";
import { OutputsItemViewModel } from ".";
import { AddOutputViewModel } from "../AddEditOutput";
import { DrawingsViewModel } from "../Drawings";
import { IssueViewModel } from "../Issue";

export class OutputsViewModel extends ViewModelBase<any> {
    public server: ServerViewModel = new ServerViewModel();
    public general: ProjectGeneralViewModel = ProjectGeneralViewModel.Instance;

    private readonly DEBOUNCE_VALUE_MS = 200;

    private projectId: string;
    private planOfWorkId: string;
    private outputs = observable<OutputsItemViewModel>([]);

    constructor(projectId: string, planofworkId: string) {
        super({});

        this.projectId = projectId;
        this.planOfWorkId = planofworkId;

        // Load general data on the project.
        this.general.apiGetbyId(this.projectId);

        // Load outputs.
        this.apiLoadOutputsAsync();
    }

    // #region Properties

    @observable
    public planOfWorkStageDisplayName: string = "";

    @computed
    public get displayName() {
        if (!isEmptyOrWhitespace(this.planOfWorkStageDisplayName)) {
            return `Outputs for ${this.planOfWorkStageDisplayName}`;
        }

        return "Outputs";
    }

    // #endregion Properties

    // #region Filtering

    @observable
    public searchString: string = "";

    @observable
    public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        this.DEBOUNCE_VALUE_MS,
    );

    @computed
    public get filteredOutputs(): OutputsItemViewModel[] {
        if (isEmptyOrWhitespace(this.filterSearchString)) {
            return this.outputs.slice();
        }

        return this.outputs.filter((vm) => vm.matchesFilter(this.filterSearchString)).slice();
    }

    // #endregion Filtering

    // #region Navigation

    public navigateBack = () => this.history.goBack();

    // #endregion Navigation

    // #region Add/Edit Output

    @observable
    public addOutputViewModel: AddOutputViewModel | null = null;

    @computed
    public get canDisplayAddOutput() {
        return this.addOutputViewModel !== null;
    }

    @action
    public displayAddOutput = (data?: OutputsItemViewModel) => {
        this.addOutputViewModel = new AddOutputViewModel(this.planOfWorkId!, data?.id ?? null, this.closeAddOutput);
    };

    @action
    public closeAddOutput = (refreshPage: boolean) => {
        this.addOutputViewModel = null;

        if (refreshPage) {
            this.apiLoadOutputsAsync();
        }
    };

    // #endregion Add/Edit Output

    // #region Drawings

    @observable
    public drawingsViewModel: DrawingsViewModel | null = null;

    @computed
    public get canDisplayDrawings() {
        return this.drawingsViewModel !== null;
    }

    @action
    public displayDrawings = (data: OutputsItemViewModel) => {
        this.drawingsViewModel = new DrawingsViewModel(data.id, this.closeDrawings);
    };

    @action
    public closeDrawings = (refreshPage: boolean) => {
        this.drawingsViewModel = null;

        if (refreshPage) {
            // Nothing to do.
        }
    };

    // #endregion Drawings

    // #region Issue

    @observable
    public issueViewModel: IssueViewModel | null = null;

    @computed
    public get canIssue() {
        return this.outputs.some((op) => op.issue);
    }

    @computed
    public get canDisplayIssue() {
        return this.issueViewModel !== null;
    }

    @action
    public displayIssue = () => {
        const outputIds = this.outputs.filter((op) => op.issue).map((op) => op.id);

        this.issueViewModel = new IssueViewModel(outputIds, this.planOfWorkId, this.closeIssue, true);
    };

    @action
    public closeIssue = (refreshPage: boolean) => {
        this.issueViewModel = null;

        if (refreshPage) {
            this.apiLoadOutputsAsync();
        }
    };

    // #endregion Issue

    // #region Communication

    @observable
    public communicationViewModel: IssueViewModel | null = null;

    @computed
    public get canAddCommunication() {
        return this.outputs.some((op) => op.issue);
    }

    @computed
    public get canDisplayCommunication() {
        return this.communicationViewModel !== null;
    }

    @action
    public displayCommunication = () => {
        const outputIds = this.outputs.filter((op) => op.issue).map((op) => op.id);

        this.communicationViewModel = new IssueViewModel(outputIds, this.planOfWorkId, this.closeCommunication, false);
    };

    @action
    public closeCommunication = (refreshPage: boolean) => {
        this.communicationViewModel = null;

        if (refreshPage) {
            this.apiLoadOutputsAsync();
        }
    };

    // #endregion Communication

    // #region Actions

    public navigateToOutputs = (viewModel: OutputsItemViewModel) => {
        this.history.push(`outputs/${viewModel.id}/drawings`);
    };

    // #endregion Actions

    // #region Api Actions

    @action
    public apiLoadOutputsAsync = async (): Promise<void> => {
        await this.server.query<any>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Outputs.GetByPlanOfWorkId.replace("{planofworkid}", this.planOfWorkId)),
            (result) => {
                runInAction(() => {
                    this.planOfWorkStageDisplayName = result.related.planOfWorkStageDisplayName;

                    this.outputs.replace(
                        result.secureResponses.map((dto: any) => {
                            const output = new OutputsItemViewModel();

                            output.id = dto.id;
                            output.name = dto.name;
                            output.requiredByDate = dto.requiredByDate;
                            output.categoryId = dto.categoryId;
                            output.categoryName = dto.categoryName;
                            output.statusName = dto.statusName;
                            output.statusColor = dto.statusColor;
                            output.statusTextColor = dto.statusTextColor;
                            output.drawingFileCount = dto.drawingFileCount;
                            output.drawingFileName = dto.drawingFileName;
                            output.drawingInlineUrl = dto.drawingInlineUrl;
                            output.drawingAttachmentUrl = dto.drawingAttachmentUrl;
                            output.drawingFileNames = dto.drawingFileNames;
                            output.autodeskVersionId = dto.autodeskVersionId;
                            output.drawingId = dto.drawingId;

                            return output;
                        }),
                    );
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    // #region Boilerplate

    isFieldValid(fieldName: string | number | symbol, value: any): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    beforeUpdate?(fieldName: string | number | symbol, value: any) {
        throw new Error("Method not implemented.");
    }
    afterUpdate?(fieldName: string | number | symbol, value: any): void {
        throw new Error("Method not implemented.");
    }

    // #endregion Boilerplate
}
