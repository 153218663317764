import { ModelBase } from "@shoothill/core";
import * as MobX from "mobx";
import { IEGridModelDTO } from "./IEmodels/IEGridModel";
import { VariationListModel } from "../Variations/VariationListModel";
import { VARGridModel } from "../Variations/VARGridModel";
import { VariationGridModelDTO } from "./IEmodels/Variation/VariationGridModel";
import { ProjectDTO } from "Views/PurchaseOrder/Form/Supporting/ProjectModel";
import { VariationTypeEnum } from "Views/Variation/Form/VariationModel";

export class IEModel extends ModelBase<IEModel, IE_DTO> {
    public id: string = "";
    public createdDate: string = "";

    @MobX.observable
    public income: number = 0;

    @MobX.observable
    public estimateCost: number = 0;

    @MobX.observable
    public estimatedMargin: number = 0;

    @MobX.observable
    public futureSpend: number = 0;

    @MobX.observable
    public committedCost: number = 0;

    @MobX.observable
    public totalExpectedSpend: number = 0;

    @MobX.observable
    public variance: number = 0;

    @MobX.observable
    public changePeriod: number = 0;

    @MobX.observable
    public margin: number = 0;

    @MobX.observable
    public roValue: number = 0;

    @MobX.observable
    public projectId: string | null = null;

    @MobX.action
    fromDto(model: IE_DTO): void {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(model: IEModel): void {}
}

export type IEModelDTO = {
    id: string;
    createdDate: string;
};

export interface VariationsTabsDTO {
    variationsLists: VariationListModel[];
}

export interface RequestModel {
    Id: string;
}

export interface VariationRequestModel {
    Id: string;
    variationType: VariationTypeEnum;
}

export interface IEViewandRelatedRequest {
    Id: string;
    fromDate: string | null;
    toDate: string | null;
}

export interface VARViewandRelatedRequest {
    Id: string;
    fromDate: string;
    toDate: string;
}

export interface IEItemModalRequest {
    categoryId: number;
    subCategoryId: number;
    descriptionId: number;
    itemId: string;
}

export type IE_DTO = {
    ieView: IEs_DTO;
    variationView: Variations_DTO;
    clientProvisionalSumsView: Variations_DTO;
    projectId: string;
    projectTitle: string;
    projectStatusType: string;
    centralProjects: ProjectDTO[];
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    changePeriod: number;
    margin: number;
    roValue: number;
};

export type Variations_DTO = {
    id: string;
    description: string;
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    //targetCost: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    changePeriod: number;
    margin: number;
    roValue: number;
    variations: VariationGridModelDTO[];
};

export type IEs_DTO = {
    id: string;
    nameId: string;
    name: string;
    sourceId: number;
    cell: number;
    createdDate: string;
    income: number;
    estimateCost: number;
    estimatedMargin: number;
    futureSpend: number;
    committedCost: number;
    totalExpectedSpend: number;
    variance: number;
    changePeriod: number;
    margin: number;
    roValue: number;
    incomeSub: number;
    estimateCostSub: number;
    estimatedMarginSub: number;
    futureSpendSub: number;
    committedCostSub: number;
    totalExpectedSpendSub: number;
    varianceSub: number;
    changePeriodSub: number;
    marginSub: number;
    roValueSub: number;
    ie: IEGridModelDTO[];
    type: string;
};

export type VAR_DTO = {
    varView: VARGridModel[];
    projectId: string;
    projectTitle: string;
    projectStatusType: string;
};

export type ValuationRelatedDTO = {
    suppliers: SupplierRelatedDTO[];
    purchaseOrders: PurchaseOrderRelatedDTO[];
};

export type SupplierRelatedDTO = {
    id: string;
    name: string;
};

export type PurchaseOrderRelatedDTO = {
    id: string;
    supplierId: string;
    poNumberFormatted: string;
};
