import { FieldType, isEmptyOrWhitespace, ViewModelBase } from "@shoothill/core";
import type { ValidationResponse } from "@shoothill/core";
import { action, observable, computed, runInAction } from "mobx";

import { AppUrls } from "AppUrls";
import { FilesViewModel } from "Components/Files/Files/FilesViewModel";
import { ServerViewModel } from "Globals/ViewModels/ServerViewModel";
import { AddDrawingModel, DrawingDocumentSource } from "./AddDrawingModel";
import { GenericIdWithDisplayName } from "Globals/Models/GenericIdWithDisplayName";

export class AddDrawingViewModel extends ViewModelBase<AddDrawingModel> {
    public server: ServerViewModel = new ServerViewModel();
    public filesViewModel = new FilesViewModel("image/*, application/pdf");

    private outputId: string;
    private parentCloseAction: (refreshPage: boolean) => void;

    constructor(outputId: string, closeAction: (refreshPage: boolean) => void, approverUsers: GenericIdWithDisplayName[]) {
        super(new AddDrawingModel(), false);

        this.outputId = outputId;
        this.parentCloseAction = closeAction;
        this.approverUsers.replace(approverUsers);

        this.setDecorators(AddDrawingModel);
    }

    // #region Actions

    @action
    public cancel = () => this.parentCloseAction(false);

    // #endregion Actions

    @observable
    public documentSourceOptions = observable<{ id: DrawingDocumentSource; displayName: string }>([
        { id: DrawingDocumentSource.FromComputer, displayName: "From computer" },
        { id: DrawingDocumentSource.FromConstructionCloud, displayName: "From construction cloud" },
    ]);

    @computed
    public get documentSourceOption() {
        return this.documentSourceOptions.find((o) => o.id === this.model.documentSource);
    }

    @computed
    public get isFromComputer() {
        return this.documentSourceOption && this.documentSourceOption.id === DrawingDocumentSource.FromComputer;
    }

    @computed
    public get isFromCloud() {
        return this.documentSourceOption && this.documentSourceOption.id === DrawingDocumentSource.FromConstructionCloud;
    }

    @action
    public setDocumentSource = (documentSource: DrawingDocumentSource) => {
        this.model.documentSource = documentSource;
        this.filesViewModel.fileViewModels.replace([]);
        this.filesViewModel.reset();
        this.filesViewModel = new FilesViewModel("image/*, application/pdf");
        this.autodeskDocuments = [];
    };

    @observable
    public approverUsers = observable<GenericIdWithDisplayName>([]);

    @computed
    public get approverUser() {
        return this.approverUsers.find((p) => p.id === this.model.approverUserId) ?? null;
    }

    @action
    public setApproverUserAsync = async (value: GenericIdWithDisplayName | null) => {
        this.model.approverUserId = value?.id ?? AddDrawingModel.DEFAULT_APPROVERUSERID;
    };

    // #region Api Actions

    @action
    public apiAddDrawingAsync = async (projectId: string): Promise<void> => {
        const dto = this.model.toDto();

        const formData = new FormData();

        if (this.isFromComputer) {
            // A collection of drawings.
            for (const file of this.filesViewModel.model?.files) {
                if (file.file) {
                    formData.append("Files", file.file);
                }
            }
        } else {
            // A collection of drawings.
            // Serialize AutodeskDocuments to JSON and append
            const autodeskDocs = this.autodeskDocuments.map((doc) => ({
                itemId: doc.itemId,
                versionId: doc.versionId,
                fileName: doc.fileName,
            }));
            formData.append("AutodeskDocuments", JSON.stringify(autodeskDocs));
        }

        const description = dto.description ?? "";
        const approverUserId = dto.approverUserId ?? "";

        formData.append("Description", description);
        formData.append("ApproverUserId", approverUserId);
        formData.append("ProjectId", projectId);

        await this.server.commandForm<any>(
            AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.Insert.replace("{outputid}", this.outputId),
            formData,
            this.getConfig,
            (result) => this.parentCloseAction(true),
            this.isMyModelValid,
            "Error whilst saving the drawings",
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @observable
    public rootFolders: ProjectTopFolder[] = [];

    @observable
    public folders: Folder[] = [];

    @observable
    public itemVersions: ItemVersion[] = [];

    @action
    public apiLoadCloudTopLevelFolderAsync = async (projectId: string): Promise<void> => {
        if (this.rootFolders.length > 0) {
            return;
        }

        await this.server.query<ProjectTopFolder[]>(
            () => this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.AutodeskCloudAPI.GetProjectTopFolders.replace("{projectId}", projectId)),
            (result) => {
                runInAction(() => {
                    this.rootFolders = result;
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @action
    public apiLoadCloudFolderContentsAsync = async (projectId: string, folderId: string): Promise<void> => {
        if (this.folders.some((folder) => folder.parentId === folderId)) {
            return;
        }

        await this.server.query<Folder[]>(
            () =>
                this.Get(
                    AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.AutodeskCloudAPI.GetFolderContents.replace("{projectId}", projectId).replace(
                        "{folderId}",
                        folderId,
                    ),
                ),
            (result) => {
                runInAction(() => {
                    if (!this.folders.some((folder) => folder.parentId === folderId)) {
                        this.folders.push(...result);
                    }
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    @observable
    public autodeskDocuments: AutodeskDocument[] = [];

    @action
    public apiLoadCloudItemAsync = async (projectId: string, itemId: string): Promise<void> => {
        if (this.itemVersions.some((item) => item.id === itemId)) {
            return;
        }

        await this.server.query<ItemVersion>(
            () =>
                this.Get(AppUrls.Server.Projects.ProjectTrackers.OutputTracker.Drawings.AutodeskCloudAPI.GetItemTip.replace("{projectId}", projectId).replace("{itemId}", itemId)),
            (result) => {
                runInAction(() => {
                    if (!this.itemVersions.some((item) => item.id === result.id)) {
                        this.itemVersions.push(result);
                        let docToAdd: AutodeskDocument = {
                            itemId: result.itemId,
                            versionId: result.id,
                            fileName: result.displayName && result.displayName !== "" ? result.displayName : result.name,
                        };
                        this.autodeskDocuments.push(docToAdd);
                    }
                });
            },
        );

        if (this.server.HaveValidationMessage) {
            this.setSnackMessage(this.server.ValidationMessage);
            this.setSnackType(this.SNACKERROR);
            this.setSnackbarState(true);
        }
    };

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        if (this.isFromComputer) {
            if ((await this.filesViewModel.isFilesModelValidAsync()) === false) {
                isValid = false;
            }
        } else {
            if (this.autodeskDocuments.length === 0) {
                isValid = false;
            }
        }

        // Validate the permit model.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }

        return isValid;
    };

    // #endregion Api Actions

    // #region Snack Bar

    public SNACKERROR = "error";

    @observable
    public snackbarState = false;

    @observable
    public snackType = "";

    @action
    public setSnackbarState = (val: boolean) => {
        this.snackbarState = val;
    };

    @observable
    public snackMessage = "";

    @action
    public setSnackMessage = (val: string) => {
        this.snackMessage = val;
    };

    @action
    public setSnackType = (val: string) => {
        this.snackType = val;
    };

    // #endregion Snack Bar

    @computed
    private get validateDescription(): ValidationResponse {
        const errorMessage = this.model.validateDescription;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    @computed
    private get validateApproverUserId(): ValidationResponse {
        const errorMessage = this.model.validateApproverUserId;

        return {
            errorMessage: errorMessage,
            isValid: isEmptyOrWhitespace(errorMessage),
        };
    }

    // #region Boilerplate

    public async isFieldValid(fieldName: keyof FieldType<AddDrawingModel>): Promise<boolean> {
        let { isValid, errorMessage } = await this.validateDecorators(fieldName);

        switch (fieldName) {
            case "description": {
                const result = this.validateDescription;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }

            case "approverUserId": {
                const result = this.validateApproverUserId;

                errorMessage = result.errorMessage;
                isValid = result.isValid;
                break;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    // #endregion Boliderplate
}

export interface ProjectTopFolder {
    id: string;
    type: string;
    name: string;
    displayName: string;
    objectCount: number;
}

export interface Folder {
    id: string;
    type: string;
    name: string;
    parentId: string;
    displayName: string;
    objectCount: number;
}

export interface ItemVersion {
    id: string;
    itemId: string;
    type: string;
    name: string;
    displayName: string;
    mimeType: string;
    versionNumber: number;
    fileType: string;
    storageSize: number;
}

export interface AutodeskDocument {
    itemId: string;
    versionId: string;
    fileName: string;
}
